import { Box, Center, Heading, Spinner, Text } from '@chakra-ui/react'
import { Currency } from '@traderjoe-team/spruce-sdk'
import { LBPairABI, LBPairV21ABI } from '@traderjoe-team/spruce-sdk-v2'
import LBPairDistributionChart from 'components/LBPairDistributionChart'
import useLBPosition from 'hooks/pool/v2/useLBPosition'
import useChainId from 'hooks/useChainId'
import React, { useMemo } from 'react'
import { Vault } from 'types/vault'
import { convertLBPositionToLiquidityChartData } from 'utils/poolV2'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { getAddress } from 'viem'
import { useContractRead } from 'wagmi'

interface VaultLiquidityChartProps {
  vault: Vault
  currency0?: Currency
  currency1?: Currency
}

const VaultLiquidityChart = ({
  currency0,
  currency1,
  vault
}: VaultLiquidityChartProps) => {
  const chainId = useChainId()

  const binStep = vault.lbPair.binStep.toString()
  const { data: reservesAndId } = useContractRead({
    abi: LBPairABI,
    address: getAddress(vault.lbPair.id),
    chainId,
    enabled: vault.lbPair.version === 'v2',
    functionName: 'getReservesAndId'
  })
  const { data: activeId, isLoading: isLoadingActiveId } = useContractRead({
    abi: LBPairV21ABI,
    address: getAddress(vault.lbPair.id),
    chainId,
    enabled: vault.lbPair.version === 'v21',
    functionName: 'getActiveId'
  })
  const activeBinId = useMemo(
    () =>
      vault.lbPair.version === 'v2'
        ? reservesAndId && Number(reservesAndId[2])
        : activeId,
    [activeId, reservesAndId, vault.lbPair.version]
  )

  const wrappedCurrency0 = wrappedCurrency(currency0, chainId)
  const wrappedCurrency1 = wrappedCurrency(currency1, chainId)
  const { isLoadingLBPosition, userBalances } = useLBPosition({
    lbPairAddress: vault.lbPair.id,
    owner: vault.strategy.id,
    token0: wrappedCurrency0,
    token1: wrappedCurrency1
  })

  const data = useMemo(() => {
    if (!activeBinId) {
      return []
    }
    return convertLBPositionToLiquidityChartData({
      activeBinId: activeBinId,
      binStep,
      token0: wrappedCurrency0,
      token1: wrappedCurrency1,
      userBalances
    })
  }, [wrappedCurrency0, wrappedCurrency1, binStep, userBalances, activeBinId])

  if (!activeBinId) {
    return null
  }

  const title = 'Auto Pool Liquidity'
  return data.length === 0 || isLoadingActiveId || isLoadingLBPosition ? (
    <Box w="full">
      <Heading size="md" mb={2}>
        {title}
      </Heading>
      <Center h="216px">
        {isLoadingActiveId || isLoadingLBPosition ? (
          <Spinner />
        ) : (
          <Text fontSize="sm" color="textSecondary">
            There is no liquidity deployed to the pool
          </Text>
        )}
      </Center>
    </Box>
  ) : (
    <LBPairDistributionChart
      title={title}
      data={data}
      activeBinId={activeBinId}
      currency0={currency0}
      currency1={currency1}
    />
  )
}

export default VaultLiquidityChart
