import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { VaultABI } from '@traderjoe-team/spruce-sdk-v2'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import { useMemo } from 'react'
import { getAddress } from 'viem'
import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi'

interface UseVaultQueueWithdrawalProps {
  vaultAddress: string
  basisPointToWithdraw?: number
  enabled?: boolean
  onSuccess?: () => void
}

const useVaultQueueWithdrawal = ({
  basisPointToWithdraw,
  enabled = true,
  onSuccess,
  vaultAddress
}: UseVaultQueueWithdrawalProps) => {
  const chainId = useChainId()
  const { address: account } = useAccount()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const contract = { abi: VaultABI, address: getAddress(vaultAddress) }
  const { data: balance } = useContractRead({
    ...contract,
    args: account ? [account] : undefined,
    chainId,
    enabled: !!account,
    functionName: 'balanceOf'
  })

  const amount = useMemo(() => {
    if (!balance || !basisPointToWithdraw) return undefined
    return (balance * BigInt(basisPointToWithdraw)) / BigInt(10000)
  }, [basisPointToWithdraw, balance])

  const { data: previewAmounts } = useContractRead({
    ...contract,
    args: amount ? [amount] : undefined,
    chainId,
    enabled: enabled && !!amount,
    functionName: 'previewAmounts'
  })

  const { config } = usePrepareContractWrite({
    abi: VaultABI,
    address: getAddress(vaultAddress),
    args: amount && account ? [amount, account] : undefined,
    cacheTime: 0,
    enabled:
      enabled && !!account && !!balance && !!amount && amount > BigInt(0),
    functionName: 'queueWithdrawal',
    value: BigInt(0) as any // workaround for safe app
  })

  const { data, isLoading, write } = useContractWrite({
    ...config,
    onSuccess: (data) => {
      const transactionSummary = 'Request withdrawal from vault'
      addRecentTransaction({
        description: transactionSummary,
        hash: data.hash
      })
      addTransactionToast({ description: transactionSummary, hash: data.hash })
    }
  })

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransaction({
      hash: data?.hash,
      onSuccess
    })

  return {
    balance,
    isLoading: isWaitingForTransaction || isLoading,
    isSuccess,
    previewAmounts,
    withdraw: write
  }
}

export default useVaultQueueWithdrawal
