import {
  Box,
  BoxProps,
  Center,
  Divider,
  Flex,
  Heading,
  Spinner,
  Tab,
  TabList,
  Tabs,
  Text
} from '@chakra-ui/react'
import { Currency } from '@traderjoe-team/spruce-sdk'
import CurrencyAmountsPanel from 'components/CurrencyAmountsPanel'
import dayjs from 'dayjs'
import useUserEarnedFees from 'hooks/pool/v2/useUserEarnedFees'
import React, { useState } from 'react'
import { useAccount } from 'wagmi'

import UserEarnedFees24Chart from './UserEarnedFees24Chart'
import UserEarnedFeesPerBinChart from './UserEarnedFeesPerBinChart'

interface UserEarnedFeesProps {
  isPriceRatioInversed: boolean
  isV21: boolean
  tokenAddrs: string[]
  boxProps?: BoxProps
  currency0?: Currency
  currency1?: Currency
  lbPairAddress?: string
}

export enum FeesPeriod {
  DAY = '24H',
  MONTH = '30D',
  WEEK = '7D'
}

const UserEarnedFees = ({
  boxProps,
  currency0,
  currency1,
  isPriceRatioInversed,
  isV21,
  lbPairAddress,
  tokenAddrs
}: UserEarnedFeesProps) => {
  const { address: account } = useAccount()
  const [tabIndex, setTabIndex] = useState<number>(0)
  const feesPeriod =
    tabIndex === 0
      ? FeesPeriod.DAY
      : tabIndex === 1
      ? FeesPeriod.WEEK
      : FeesPeriod.MONTH

  const {
    isFetchinBins,
    isFetchingIntervalData,
    isFetchingRefreshedTime,
    lastRefreshedTimestamp,
    userFeesDataBins,
    userFeesIntervalData
  } = useUserEarnedFees({
    feesPeriod,
    lbPairAddress,
    owner: account
  })

  const totalFeesX24 = userFeesIntervalData.reduce(
    (prev, curr) => prev + curr.accruedFeesX,
    0
  )
  const totalFeesY24 = userFeesIntervalData.reduce(
    (prev, curr) => prev + curr.accruedFeesY,
    0
  )

  const totalFeesXBins = userFeesDataBins.reduce(
    (prev, curr) => prev + curr.accruedFeesX,
    0
  )
  const totalFeesYBins = userFeesDataBins.reduce(
    (prev, curr) => prev + curr.accruedFeesY,
    0
  )

  const fmtRefreshedTime =
    !isFetchingRefreshedTime && lastRefreshedTimestamp
      ? dayjs(lastRefreshedTimestamp.timestamp * 1000).format(
          'MMM D YYYY, h:mm A'
        )
      : ''

  return (
    <Box w="full" {...boxProps}>
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        align={{ base: 'flex-start', md: 'center' }}
        justify="space-between"
        w="full"
        gap={4}
      >
        <Flex flexDir="column" gap={1}>
          <Heading size="md">{`FEES EARNED (${feesPeriod})`}</Heading>
          {fmtRefreshedTime ? (
            <Text fontSize="xs" color="textPrimary" textAlign="right">
              {`Last refreshed on ${fmtRefreshedTime}`}
            </Text>
          ) : null}
        </Flex>
        {isV21 ? (
          <Tabs
            variant="lb-pro"
            index={tabIndex}
            onChange={setTabIndex}
            size="sm"
          >
            <TabList>
              <Tab>24H</Tab>
              <Tab>7D</Tab>
              <Tab>30D</Tab>
            </TabList>
          </Tabs>
        ) : null}
      </Flex>
      {userFeesIntervalData.length === 0 ? (
        <Center h="200px">
          {isFetchingIntervalData ? (
            <Spinner size="sm" color="textPrimary" />
          ) : (
            <Text fontSize="sm" color="textPrimary">
              You have no fees earned in the last {feesPeriod}
            </Text>
          )}
        </Center>
      ) : (
        <UserEarnedFees24Chart
          feesPeriod={feesPeriod}
          userFeesIntervalData={userFeesIntervalData}
          currency0={currency0}
          currency1={currency1}
        />
      )}
      {userFeesIntervalData.length > 0 ? (
        <CurrencyAmountsPanel
          title=""
          tokens={tokenAddrs}
          tokenAmounts={[totalFeesX24, totalFeesY24]}
          tokenSymbols={[currency0?.symbol, currency1?.symbol]}
          boxProps={{ pb: 6, px: 0, py: 0 }}
          balanceText={`Total Fees Earned (${feesPeriod})`}
        />
      ) : null}
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'inline-flex',
          justifyContent: 'space-between',
          pt: 6,
          width: '100%'
        }}
      >
        <Flex flexDir="column" gap={1}>
          <Heading size="md">FEES EARNED (PER BIN)</Heading>
          {fmtRefreshedTime ? (
            <Text fontSize="xs" color="textPrimary">
              {`Last refreshed on ${fmtRefreshedTime}`}
            </Text>
          ) : null}
        </Flex>
      </Box>
      {userFeesDataBins.length === 0 ? (
        <Center h="200px">
          {isFetchinBins ? (
            <Spinner size="sm" color="textPrimary" />
          ) : (
            <Text fontSize="sm" color="textPrimary">
              You have no fees earned in your current bin positions
            </Text>
          )}
        </Center>
      ) : (
        <UserEarnedFeesPerBinChart
          isPriceRatioInversed={isPriceRatioInversed}
          userFeesDataBins={userFeesDataBins}
          currency0={currency0}
          currency1={currency1}
        />
      )}

      {userFeesDataBins.length > 0 ? (
        <CurrencyAmountsPanel
          title=""
          tokens={tokenAddrs}
          tokenAmounts={[totalFeesXBins, totalFeesYBins]}
          tokenSymbols={[currency0?.symbol, currency1?.symbol]}
          boxProps={{ px: 0, py: 0 }}
          balanceText="Total Fees earned (since last deposit)"
        />
      ) : null}
      {isV21 && userFeesDataBins.length > 0 ? (
        <Box mt={4}>
          <Text fontSize="sm" color="textPrimary">
            Note:
          </Text>
          <Text fontSize="sm" color="textPrimary">
            - Fees earned are automatically compounded into the bins.
          </Text>
          <Text fontSize="sm" color="textPrimary">
            - Analytics are reset on every deposit or withdraw.
          </Text>
        </Box>
      ) : null}
    </Box>
  )
}

export default UserEarnedFees
