import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { Token } from '@traderjoe-team/spruce-sdk'
import {
  LIMIT_ORDER_MANAGER_ADDRESS,
  LimitOrderManagerABI
} from '@traderjoe-team/spruce-sdk-v2'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import { OrderType } from 'types/limitOrder'
import { capturePrepareContractWriteError } from 'utils/logger'
import { getAddress, zeroAddress } from 'viem'
import {
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi'

interface UsePlaceLimitOrderProps {
  isApproved: boolean
  amount?: bigint
  binId?: number
  binStep?: number
  isDisabled?: boolean
  isInputNative?: boolean
  onPlaceOrderSuccess?: () => void
  orderType?: OrderType
  tokenX?: Token
  tokenY?: Token
}

const usePlaceLimitOrder = ({
  amount,
  binId,
  binStep,
  isApproved,
  isDisabled,
  isInputNative,
  onPlaceOrderSuccess,
  orderType,
  tokenX,
  tokenY
}: UsePlaceLimitOrderProps) => {
  const chainId = useChainId()
  const walletChainId = useNetwork().chain?.id

  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  // prepare 'placeOrder'contract write
  const args =
    tokenX?.address &&
    tokenY?.address &&
    binStep &&
    binId &&
    amount &&
    orderType !== undefined &&
    isApproved
      ? ([
          isInputNative && orderType === OrderType.ASK
            ? zeroAddress
            : getAddress(tokenX.address),
          isInputNative && orderType === OrderType.BID
            ? zeroAddress
            : getAddress(tokenY.address),
          binStep,
          orderType,
          binId,
          amount
        ] as const)
      : undefined

  const { config } = usePrepareContractWrite({
    abi: LimitOrderManagerABI,
    address: getAddress(LIMIT_ORDER_MANAGER_ADDRESS[chainId]),
    args,
    cacheTime: 0,
    chainId,
    enabled: !!args && chainId === walletChainId && !isDisabled,
    functionName: 'placeOrder',
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    },
    value: isInputNative && amount ? amount : BigInt(0)
  })

  const { data, isLoading, write } = useContractWrite({
    ...config,
    onSuccess: (data) => {
      const description = 'Place limit order'
      addRecentTransaction({ description, hash: data.hash })
      addTransactionToast({ description, hash: data.hash })
    }
  })

  const { isLoading: isWaitingForTransaction } = useWaitForTransaction({
    hash: data?.hash,
    onSuccess: (data) => {
      if (data.status === 'success') onPlaceOrderSuccess?.()
    }
  })

  return { isLoading: isLoading || isWaitingForTransaction, write }
}

export default usePlaceLimitOrder
