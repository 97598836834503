import { Flex, HStack, Image, Link, Text } from '@chakra-ui/react'
import Jazzicon from 'assets/jazzicon.svg'
import useActiveChain from 'hooks/useActiveChain'
import React from 'react'
import { shortenAddress } from 'utils/addresses'

interface ConnnectedAccountProps {
  walletAddress?: string
}

const ConnnectedAccount = ({ walletAddress }: ConnnectedAccountProps) => {
  const { blockExplorers } = useActiveChain()

  return (
    <HStack px={6} align="center" pb={4} gap={4}>
      <Image
        src={Jazzicon}
        fallbackSrc={Jazzicon}
        borderRadius="50%"
        alt="pfp"
        bgColor="rgb(243, 184, 0)"
        boxSize="40px"
      />
      <Flex flexDir="column" alignItems="flex-start">
        <Text fontSize="xs" textColor="textSecondary">
          Connected Account:
        </Text>
        <Link
          aria-label="Link to user address on explorer"
          isExternal
          href={`${blockExplorers?.default.url}/address/${walletAddress}`}
        >
          <Text fontSize="md" fontWeight="semibold">
            {shortenAddress(walletAddress)}
          </Text>
        </Link>
      </Flex>
    </HStack>
  )
}

export default ConnnectedAccount
