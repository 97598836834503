import { HStack, Tag, Text } from '@chakra-ui/react'
import { ChainId } from '@traderjoe-team/spruce-sdk'
import DoubleCurrencyLogo from 'components/DoubleCurrencyLogo'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { Token } from 'types/pool'
import { getChain } from 'utils/chains'
import { isWrappedNativeCurrency } from 'utils/wrappedCurrency'

interface PoolTableNameColumnProps {
  fees: string
  token0: Token
  token1: Token
  chainId?: ChainId
  extraTagLabel?: string
  isPoolMigrated?: boolean
  isRewarded?: boolean
}

const PoolTableNameColumn = ({
  chainId,
  extraTagLabel,
  fees,
  isPoolMigrated,
  isRewarded,
  token0,
  token1
}: PoolTableNameColumnProps) => {
  const defaultChainId = useChainId()
  const _chainId = chainId ?? defaultChainId

  const chain = getChain(_chainId)
  const nativeCurrency = chain?.nativeCurrency

  const isToken0WrappedNativeCurrency = isWrappedNativeCurrency(
    token0.address,
    _chainId
  )
  const isToken1WrappedNativeCurrency = isWrappedNativeCurrency(
    token1.address,
    _chainId
  )

  return (
    <HStack spacing={2}>
      <DoubleCurrencyLogo
        address0={isToken0WrappedNativeCurrency ? undefined : token0.address}
        symbol0={
          isToken0WrappedNativeCurrency ? nativeCurrency?.symbol : token0.symbol
        }
        address1={isToken1WrappedNativeCurrency ? undefined : token1.address}
        symbol1={
          isToken1WrappedNativeCurrency ? nativeCurrency?.symbol : token1.symbol
        }
        boxSize={6}
        ml="-2"
      />
      <HStack>
        <Text fontWeight="semibold" fontSize="sm">{`${
          isToken0WrappedNativeCurrency ? nativeCurrency?.symbol : token0.symbol
        } - ${
          isToken1WrappedNativeCurrency ? nativeCurrency?.symbol : token1.symbol
        }`}</Text>
        <Tag
          size="md"
          bg="silver"
          textColor="textPrimary"
          _dark={{ bg: 'joeDark.600' }}
        >
          {fees}
        </Tag>
        {isRewarded ? (
          <Tag variant="solid" size="md" bg="green.400" textColor="white">
            REWARDS
          </Tag>
        ) : null}
        {isPoolMigrated ? (
          <Tag variant="solid" size="md" bg="yellowBar" textColor="black">
            OUTDATED
          </Tag>
        ) : null}
        {extraTagLabel ? (
          <Tag
            variant="solid"
            size="md"
            bg="bgTertiary"
            textColor="textPrimary"
          >
            {extraTagLabel}
          </Tag>
        ) : null}
      </HStack>
    </HStack>
  )
}

export default PoolTableNameColumn
