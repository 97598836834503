import {
  Box,
  Flex,
  Heading,
  Tab,
  TabList,
  Tabs,
  Text,
  useToken
} from '@chakra-ui/react'
import AnalyticsChartXTick from 'components/AnalyticsChart/AnalyticsChartXTick'
import AnalyticsChartYTick from 'components/AnalyticsChart/AnalyticsChartYTick'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from 'recharts'
import {
  NameType,
  ValueType
} from 'recharts/types/component/DefaultTooltipContent'
import { formattedNum } from 'utils/format'

interface FeesBpsChartData {
  avgFeesBp: number
  date: number
  maxFeesBp: number
  minFeesBp: number
}

enum ChartRange {
  D7 = '7D',
  D30 = '30D',
  H24 = '24H'
}

const chartRanges = [ChartRange.H24, ChartRange.D7, ChartRange.D30]

interface FeesBpsChartProps {
  baseFee: number
  feesBpsDayDatas: FeesBpsChartData[]
  feesBpsHourDatas: FeesBpsChartData[]
}

const FeesBpsChart = ({
  baseFee,
  feesBpsDayDatas,
  feesBpsHourDatas
}: FeesBpsChartProps) => {
  const [graphPurple] = useToken('colors', ['graphPurpleDark'])

  const [chartRange, setChartRange] = useState<ChartRange>(ChartRange.H24)

  const now = dayjs()
  const startTime =
    chartRange === ChartRange.H24
      ? now.subtract(1, 'day')
      : chartRange === ChartRange.D7
      ? now.subtract(7, 'day')
      : now.subtract(30, 'day')

  const dateFmt = chartRange === ChartRange.H24 ? 'h:mm A' : 'MMM D, YYYY'

  const DAY_IN_SECS = 86400
  const HOUR_IN_SECS = 3600
  const interval = chartRange === ChartRange.H24 ? HOUR_IN_SECS : DAY_IN_SECS

  const feesBpsDatas =
    chartRange === ChartRange.H24 ? feesBpsHourDatas : feesBpsDayDatas

  const processedChartData = feesBpsDatas
    .filter((d: FeesBpsChartData) => startTime.unix() <= d.date)
    .map((d: FeesBpsChartData) => ({
      avgFeesBp: d.avgFeesBp || baseFee, // default to 'baseFee' instead of 0
      date: d.date,
      fmtDate: dayjs(d.date * 1000).format(dateFmt),
      fmtRange: `${dayjs(d.date * 1000).format(dateFmt)} ~ ${dayjs(
        (d.date + interval) * 1000
      ).format(dateFmt)}`,
      maxFeesBp: d.maxFeesBp || baseFee,
      minFeesBp: d.minFeesBp || baseFee
    }))

  const yAxisMinValue = processedChartData
    .map((data) => data.avgFeesBp)
    .reduce((a, b) => Math.min(a, b), Infinity)
  const yAxisMaxValue = processedChartData
    .map((data) => data.avgFeesBp)
    .reduce((a, b) => Math.max(a, b), -Infinity)
  const yAxisDomain = yAxisMaxValue ? [yAxisMinValue, yAxisMaxValue] : undefined

  return (
    <Box pos="relative">
      <Flex
        justify="space-between"
        align="center"
        gap={2}
        mb={{ base: 4, sm: 8 }}
      >
        <Heading size="md">{`FEES BPS (${chartRange})`}</Heading>
        <Tabs
          variant="lb-pro"
          index={chartRanges.indexOf(chartRange)}
          onChange={(index) => setChartRange(chartRanges[index])}
        >
          <TabList>
            {chartRanges.map((range, i) => (
              <Tab key={i}>{range}</Tab>
            ))}
          </TabList>
        </Tabs>
      </Flex>
      <Box h="220px">
        <ResponsiveContainer>
          <AreaChart data={processedChartData}>
            <YAxis
              width={80}
              axisLine={false}
              tickLine={false}
              tickSize={0}
              tickMargin={20}
              domain={yAxisDomain}
              tick={<AnalyticsChartYTick places={5} />}
            />
            <XAxis
              tickLine={false}
              axisLine={false}
              dataKey="fmtDate"
              tick={<AnalyticsChartXTick />}
            />
            <defs>
              <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={graphPurple}
                  stopOpacity={1}
                  style={{
                    transition: 'all 3s ease-out'
                  }}
                />
                <stop
                  offset="95%"
                  stopColor={graphPurple}
                  stopOpacity={0.01}
                  style={{
                    transition: 'all 3s ease-out'
                  }}
                />
              </linearGradient>
            </defs>
            <Area
              dataKey="avgFeesBp"
              stroke={graphPurple}
              fill="url(#colorGradient)"
            />
            <Tooltip
              wrapperStyle={{ outline: 'none' }}
              content={<FeesBpsChartTooltip />}
            />
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  )
}

const FeesBpsChartTooltip = ({
  payload
}: TooltipProps<ValueType, NameType>) => {
  const point = payload && payload.length && payload[0].payload

  return (
    <Box
      p={4}
      borderRadius="xl"
      backdropFilter="blur(8px)"
      bg="rgba(245, 245, 255, 0.48)"
      boxShadow="0px 4px 40px rgba(0, 0, 0, 0.16)"
      _dark={{
        bg: 'rgba(53, 58, 108, 0.48)',
        boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.4)'
      }}
    >
      <Text color="textPrimary" fontSize="sm" mb={3}>
        {point?.fmtRange}
      </Text>

      <Text color="textSecondary" fontSize="sm">
        Max bps
      </Text>
      <Text fontWeight="semibold">{formattedNum(point?.maxFeesBp || 0)}</Text>
      <Text color="textSecondary" fontSize="sm">
        Average bps
      </Text>
      <Text fontWeight="semibold">{formattedNum(point?.avgFeesBp || 0)}</Text>
      <Text color="textSecondary" fontSize="sm">
        Min bps
      </Text>
      <Text fontWeight="semibold">{formattedNum(point?.minFeesBp || 0)}</Text>
    </Box>
  )
}

export default FeesBpsChart
