import { Box, HStack, Text, useTheme, useToken, VStack } from '@chakra-ui/react'
import { Currency } from '@traderjoe-team/spruce-sdk'
import dayjs from 'dayjs'
import { FeesPeriod } from 'pages/PoolDetailV2/UserEarnedFees'
import React, { useMemo, useState } from 'react'
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis
} from 'recharts'
import {
  NameType,
  ValueType
} from 'recharts/types/component/DefaultTooltipContent'
import { UserFeesIntervalData } from 'types/dexbarn'
import { formattedNum } from 'utils/format'

interface UserEarnedFees24ChartProps {
  feesPeriod: FeesPeriod
  userFeesIntervalData: UserFeesIntervalData[]
  currency0?: Currency
  currency1?: Currency
}

const UserEarnedFees24Chart = ({
  currency0,
  currency1,
  feesPeriod,
  userFeesIntervalData
}: UserEarnedFees24ChartProps) => {
  const [textSecondary] = useToken('colors', ['textSecondary'])
  const theme = useTheme()
  const barColor = theme.semanticTokens.colors.graphPurpleLight
  const barFocusColor = theme.semanticTokens.colors.graphPurple

  const [focusBar, setFocusBar] = useState<number | undefined>()

  const data = useMemo(() => {
    const data: UserFeesIntervalData[] = []

    const DAY_IN_SECS = 86400
    const HOUR_IN_SECS = 3600
    const interval = feesPeriod === FeesPeriod.DAY ? HOUR_IN_SECS : DAY_IN_SECS

    // insert intervals with no fees
    userFeesIntervalData.forEach(
      (userFees: UserFeesIntervalData, index: number) => {
        if (index === 0) {
          data.push(userFees)
        } else {
          while (
            data[data.length - 1].timestamp + interval <
            userFees.timestamp
          ) {
            const prevTimestamp = data[data.length - 1].timestamp
            data.push({
              accruedFeesL: 0,
              accruedFeesX: 0,
              accruedFeesY: 0,
              date: new Date(prevTimestamp * 1000),
              timestamp: prevTimestamp + interval
            })
          }
          data.push(userFees)
        }
      }
    )
    return data
  }, [userFeesIntervalData, feesPeriod])

  const xAxisTicks = data
    .filter((_feeData, index) => {
      // show all ticks when size < 5
      if (data.length < 5) {
        return true
      }
      // else show only 3
      return (
        index === 0 ||
        index === data.length - 1 ||
        index === Math.floor(data.length / 2)
      )
    })
    .map((feeData) => feeData.timestamp)

  return (
    <Box h="160px">
      <ResponsiveContainer width="99%">
        <BarChart
          data={data}
          onMouseMove={(state) => {
            setFocusBar(
              state.isTooltipActive ? state.activeTooltipIndex : undefined
            )
          }}
          onMouseLeave={() => {
            setFocusBar(undefined)
          }}
        >
          <Tooltip
            wrapperStyle={{ outline: 'none' }}
            cursor={{ fill: 'transparent' }}
            content={
              <UserFeesChartTooltip
                feesPeriod={feesPeriod}
                symbolX={currency0?.symbol ?? ''}
                symbolY={currency1?.symbol ?? ''}
              />
            }
          />
          <XAxis
            xAxisId="0"
            tickSize={0}
            axisLine={false}
            ticks={xAxisTicks}
            dataKey="timestamp"
            tickFormatter={(timestamp) =>
              dayjs(new Date(timestamp * 1000)).format(
                feesPeriod === FeesPeriod.DAY ? 'hh:mm A' : 'MMM D'
              )
            }
            fontSize="12px"
            fontWeight="semibold"
            tick={{ fill: textSecondary }}
            interval="preserveStartEnd"
            tickMargin={12}
          />
          <Bar dataKey="accruedFeesL" radius={2} fillOpacity={1}>
            {data.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={focusBar === index ? barFocusColor : barColor}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )
}

export default UserEarnedFees24Chart

type UserFeesChartTooltipProps = TooltipProps<ValueType, NameType> & {
  feesPeriod: FeesPeriod
  symbolX: string
  symbolY: string
}

const UserFeesChartTooltip = ({
  active,
  feesPeriod,
  payload,
  symbolX,
  symbolY
}: UserFeesChartTooltipProps) => {
  const fees = payload?.[0]?.payload
  const { accruedFeesX, accruedFeesY, timestamp } = fees || {}

  const time = dayjs(timestamp * 1000)
  const date = time.format('MMM D, YYYY')
  const from = time.format('h:mm A')
  const to = time.add(1, 'hour').format('h:mm A')
  return active && payload && payload.length ? (
    <Box
      p={4}
      borderRadius="xl"
      backdropFilter="blur(8px)"
      bg="rgba(245, 245, 255, 0.48)"
      boxShadow="0px 4px 40px rgba(0, 0, 0, 0.16)"
      _dark={{
        bg: 'rgba(53, 58, 108, 0.48)',
        boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.4)'
      }}
    >
      <VStack spacing={0} align="flex-start" mb={4}>
        <Text fontSize="sm" color="textSecondary">
          {date}
        </Text>
        {feesPeriod === FeesPeriod.DAY ? (
          <Text color="textSecondary" fontSize="sm">{`${from} - ${to}`}</Text>
        ) : null}
      </VStack>
      <HStack>
        <VStack spacing={0} align="flex-start" mr={4}>
          <Text fontSize="sm" color="textSecondary">
            Earned {symbolX}
          </Text>
          <Text fontWeight="semibold">{formattedNum(accruedFeesX)}</Text>
        </VStack>
        <VStack spacing={0} align="flex-start">
          <Text fontSize="sm" color="textSecondary">
            Earned {symbolY}
          </Text>
          <Text fontWeight="semibold">{formattedNum(accruedFeesY)}</Text>
        </VStack>
      </HStack>
    </Box>
  ) : null
}
