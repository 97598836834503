import { Box, Flex, Td, Tr } from '@chakra-ui/react'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import { getRewardRates, getRewardTokens, isPoolRewarded } from 'constants/pool'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { LBPool, Pool } from 'types/pool'
import { formattedNum } from 'utils/format'
import { getPoolDetailUrl } from 'utils/poolUrl'

import PoolTableNameColumn from './PoolTableNameColumn'

interface PoolTableRow {
  pool: Pool | LBPool
  rewardPriceUsdObj: { [key: string]: number }
}

const PoolTableRow = ({ pool, rewardPriceUsdObj }: PoolTableRow) => {
  const chainId = useChainId()
  const navigate = useNavigate()

  // get rewards APR
  const isRewarded = isPoolRewarded(chainId, pool.pairAddress)
  const rewardRates = getRewardRates(chainId, pool.pairAddress)
  const rewardTokens = getRewardTokens(chainId, pool.pairAddress)

  const rewardPricesUSD =
    rewardRates && rewardTokens
      ? rewardTokens.map(
          (rewardToken) => rewardPriceUsdObj[rewardToken.toLowerCase()]
        )
      : undefined

  const rewardsPerDay =
    rewardPricesUSD && rewardRates
      ? rewardRates.reduce(
          (prev, currRewardRate, index) =>
            prev + currRewardRate * rewardPricesUSD[index],
          0
        )
      : 0
  const rewardsAPR = (rewardsPerDay / pool.liquidityUsd) * 365
  const totalAPR = pool.apr + (rewardsAPR ?? 0)

  const feesTooltipLabel = (
    <div>
      Fees APR: {`${Number(pool.apr * 100).toFixed(2)}%`} <br />
      Rewards APR:{' '}
      {rewardsAPR ? `${Number(rewardsAPR * 100).toFixed(2)}%` : 'N/A'}
    </div>
  )

  return (
    <Tr
      data-cy={`pool-row-${pool.pairAddress}`}
      key={pool.pairAddress}
      transform="scale(1)"
      cursor="pointer"
      role="group"
      aria-label={`${pool.tokenX.symbol}-${pool.tokenY.symbol} liquidity pool`}
      onClick={() => navigate(getPoolDetailUrl(pool, chainId))}
    >
      <Td>
        <PoolTableNameColumn
          token0={pool.tokenX}
          token1={pool.tokenY}
          fees={`${pool.feePct}%`}
          isRewarded={isRewarded}
          isPoolMigrated={pool.isMigrated}
        />
      </Td>
      <Td textAlign="right">{formattedNum(pool.volumeUsd, { usd: true })}</Td>
      <Td textAlign="right">
        {formattedNum(pool.liquidityUsd, { usd: true })}
      </Td>
      <Td textAlign="right">
        {'liquidityUsdDepthPlus' in pool
          ? formattedNum(pool.liquidityUsdDepthPlus, { usd: true })
          : '--'}
      </Td>
      <Td textAlign="right">
        {'liquidityUsdDepthMinus' in pool
          ? formattedNum(pool.liquidityUsdDepthMinus, { usd: true })
          : '--'}
      </Td>
      <Td textAlign="right">{formattedNum(pool.feesUsd, { usd: true })}</Td>
      <Td textAlign="right">
        <Flex alignItems="center" justifyContent="end">
          {rewardsAPR ? (
            <Box textDecoration="dotted underline">
              <TouchFriendlyTooltip label={feesTooltipLabel}>
                {formattedNum(totalAPR * 100, { places: 2 })}%
              </TouchFriendlyTooltip>
            </Box>
          ) : (
            <Box>{formattedNum(totalAPR * 100, { places: 2 })}%</Box>
          )}
        </Flex>
      </Td>
    </Tr>
  )
}

export default PoolTableRow
