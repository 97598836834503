import { Currency, Token, TokenAmount } from '@traderjoe-team/spruce-sdk'
import { PairV2, RouteV2, TradeV2 } from '@traderjoe-team/spruce-sdk-v2'
import useChainId from 'hooks/useChainId'
import debounce from 'lodash.debounce'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { BASES_TO_CHECK_TRADES_AGAINST } from 'utils/swap'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { usePublicClient } from 'wagmi'

interface UseTradesProps {
  isExactIn: boolean
  inputCurrency?: Currency
  outputCurrency?: Currency
  typedTokenAmount?: TokenAmount
}

const useTrades = ({
  inputCurrency,
  isExactIn,
  outputCurrency,
  typedTokenAmount
}: UseTradesProps) => {
  const chainId = useChainId()
  const publicClient = usePublicClient({ chainId })
  const [trades, setTrades] = useState<TradeV2[]>([])
  const [isFetchingTrades, setIsFetchingTrades] = useState(false)

  const fetchTrades = useCallback(async () => {
    try {
      const [inputToken, outputToken] = [
        wrappedCurrency(inputCurrency, chainId),
        wrappedCurrency(outputCurrency, chainId)
      ]

      if (!typedTokenAmount || !publicClient || !inputToken || !outputToken) {
        setTrades([])
        return
      }

      setIsFetchingTrades(true)

      const bases: Token[] = BASES_TO_CHECK_TRADES_AGAINST[chainId]
      const allTokenPairs = PairV2.createAllTokenPairs(
        inputToken,
        outputToken,
        bases
      )
      const allPairs = PairV2.initPairs(allTokenPairs)
      const allRoutes = RouteV2.createAllRoutes(
        allPairs,
        inputToken,
        outputToken
      )

      const isNativeIn = inputCurrency?.isNative ?? false
      const isNativeOut = outputCurrency?.isNative ?? false

      const trades = isExactIn
        ? await TradeV2.getTradesExactIn(
            allRoutes,
            typedTokenAmount,
            outputToken,
            isNativeIn,
            isNativeOut,
            publicClient,
            chainId
          )
        : await TradeV2.getTradesExactOut(
            allRoutes,
            typedTokenAmount,
            inputToken,
            isNativeIn,
            isNativeOut,
            publicClient,
            chainId
          )
      setTrades(trades.filter(Boolean) as TradeV2[])
    } catch (e) {
      console.error('Error fetchAllPossibleTrades', e)
    } finally {
      setIsFetchingTrades(false)
    }
  }, [
    publicClient,
    chainId,
    inputCurrency,
    outputCurrency,
    typedTokenAmount,
    isExactIn
  ])

  const debouncedFetchTrades = useMemo(
    () => debounce(fetchTrades, 400),
    [fetchTrades]
  )

  useEffect(() => {
    debouncedFetchTrades()
    return () => debouncedFetchTrades.cancel()
  }, [debouncedFetchTrades])

  return { isFetchingTrades, trades }
}

export default useTrades
