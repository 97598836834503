import { Box, HStack, IconButton, Text } from '@chakra-ui/react'
import { Currency, Token } from '@traderjoe-team/spruce-sdk'
import React from 'react'
import { ArrowLeftRightIcon, RefreshIcon } from 'theme/icons'

import TradeCurrencyInput from '../TradeCurrencyInput'

interface PlaceOrderPriceInputProps {
  activePrice: number
  arePairTokensInversed: boolean
  isIncorrectPrice: boolean
  isPriceRatioInversed: boolean
  onResetPriceClick: () => void
  onTogglePriceRatio: () => void
  onValueChange: (value: string) => void
  typedPrice: string
  inputCurrency?: Currency
  outputCurrency?: Currency
  tokenX?: Token
  tokenY?: Token
}

const PlaceOrderPriceInput = ({
  activePrice,
  arePairTokensInversed,
  inputCurrency,
  isIncorrectPrice,
  isPriceRatioInversed,
  onResetPriceClick,
  onTogglePriceRatio,
  onValueChange,
  outputCurrency,
  tokenX,
  tokenY,
  typedPrice
}: PlaceOrderPriceInputProps) => {
  // calculate the difference between typed price and market price in percent
  const priceRatio =
    !isPriceRatioInversed && !arePairTokensInversed
      ? Number(typedPrice) / activePrice
      : isPriceRatioInversed && !arePairTokensInversed
      ? 1 / Number(typedPrice) / activePrice
      : !isPriceRatioInversed && arePairTokensInversed
      ? Number(typedPrice) / (1 / activePrice)
      : 1 / Number(typedPrice) / (1 / activePrice)
  const priceRate = isFinite(priceRatio)
    ? Number((priceRatio * 100).toFixed(2)) - 100
    : 0

  return (
    <Box mt={4}>
      <TradeCurrencyInput
        data-cy="place-order-price-input"
        heading={
          !inputCurrency || !outputCurrency
            ? 'Price'
            : (arePairTokensInversed && !isPriceRatioInversed) ||
              (!arePairTokensInversed && isPriceRatioInversed)
            ? `Price (${tokenX?.symbol} per ${tokenY?.symbol})`
            : `Price (${tokenY?.symbol} per ${tokenX?.symbol})`
        }
        headingRightElement={
          !!inputCurrency && !!outputCurrency ? (
            <HStack spacing={2} h="24px">
              <Text
                data-cy="place-order-price-rate-label"
                ml={1}
                fontWeight="semibold"
                fontSize="sm"
                textColor={priceRate >= 0 ? 'green.400' : 'red.500'}
              >
                {`${priceRate < 0 ? '' : '+'}${priceRate.toFixed(0)}%`}
              </Text>
              <HStack spacing={1}>
                <IconButton
                  data-cy="place-order-toggle-price-ratio-button"
                  aria-label="toggle price ratio"
                  variant="ghost"
                  size="sm"
                  icon={<ArrowLeftRightIcon />}
                  onClick={onTogglePriceRatio}
                />
                <IconButton
                  data-cy="place-order-reset-price-button"
                  aria-label="reset price"
                  variant="ghost"
                  size="sm"
                  icon={<RefreshIcon />}
                  onClick={onResetPriceClick}
                />
              </HStack>
            </HStack>
          ) : undefined
        }
        placeholder="0.0"
        value={typedPrice}
        onValueChange={onValueChange}
      />
      {isIncorrectPrice ? (
        <Text data-cy="place-order-price-input-error-label" color="red.500">
          {arePairTokensInversed && isPriceRatioInversed
            ? `Price must be lower than ${activePrice.toFixed(8)} ${
                inputCurrency?.symbol
              }`
            : arePairTokensInversed && !isPriceRatioInversed
            ? `Price must be greater than ${(1 / activePrice).toFixed(8)} ${
                outputCurrency?.symbol
              }`
            : !arePairTokensInversed && !isPriceRatioInversed
            ? `Price must be greater than ${activePrice.toFixed(8)} ${
                outputCurrency?.symbol
              }`
            : `Price must be lower than ${(1 / activePrice).toFixed(8)} ${
                inputCurrency?.symbol
              }`}
        </Text>
      ) : undefined}
    </Box>
  )
}

export default PlaceOrderPriceInput
