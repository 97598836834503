import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { VaultABI } from '@traderjoe-team/spruce-sdk-v2'
import useTransactionToast from 'hooks/useTransactionToast'
import { getAddress } from 'viem'
import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi'

interface UseVaultRedeemQueuedWithdrawalProps {
  isOneCurrencyNative: boolean
  round: number
  vaultAddress: string
  enabled?: boolean
  onSuccess?: () => void
}

const useVaultRedeemQueuedWithdrawal = ({
  enabled = true,
  isOneCurrencyNative,
  onSuccess,
  round,
  vaultAddress
}: UseVaultRedeemQueuedWithdrawalProps) => {
  const { address: account } = useAccount()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { config } = usePrepareContractWrite({
    abi: VaultABI,
    address: getAddress(vaultAddress),
    args: account ? [BigInt(round), account] : undefined,
    cacheTime: 0,
    enabled: enabled && !!account,
    functionName: isOneCurrencyNative
      ? 'redeemQueuedWithdrawalNative'
      : 'redeemQueuedWithdrawal',
    value: BigInt(0) as any // workaround for safe app
  })

  const { data, isLoading, write } = useContractWrite({
    ...config,
    onSuccess: (data) => {
      const transactionSummary = 'Redeem queued withdrawal'
      addRecentTransaction({
        description: transactionSummary,
        hash: data.hash
      })
      addTransactionToast({ description: transactionSummary, hash: data.hash })
    }
  })

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransaction({
      hash: data?.hash,
      onSuccess
    })

  return {
    isLoading: isWaitingForTransaction || isLoading,
    isSuccess,
    redeemQueuedWithdrawal: write
  }
}

export default useVaultRedeemQueuedWithdrawal
