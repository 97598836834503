import { ChainId } from '@traderjoe-team/spruce-sdk'

interface VaultInfo {
  description: string
  name: string
  disabled?: boolean
}

interface StrategyInfo {
  name: string
  vault: string
}

export const VAULT_DEFAULT_DESCRIPTION = `This Auto-Pool adopts a general strategy to maximize fees with wide liquidity distribution. The Auto-Pool automates the rebalancing of positions, and auto-compounds all associated fees and rewards.

Divergence Risk: Assets may experience significant divergent price movements during volatile conditions. This means your deposit is exposed to the price movements of both tokens in the pair, and your exposure to each token can also change. Volatile conditions may lead to increased impermanent/divergence loss.

Notes: 
- All fees are auto-compounded and shared by all Auto Pool participants. 
- The automation fee is an annualized percentage rate applied to total assets during each rebalance.
- You may deposit assets in any ratio, but you may withdraw only based on the ratio of the assets in the Auto Pool. The ratio of the assets may change as the Auto Pool performs its automated rebalancing strategies. 
- All withdrawals are queued until the next rebalance (at most 1 hour).
`

export const VAULTS_INFO: {
  [chainId in ChainId]: { [address: string]: VaultInfo }
} = {
  [ChainId.FUJI]: {},
  [ChainId.SPRUCE]: {}
}

export const STRATEGIES_INFO: {
  [chainId in ChainId]: { [address: string]: StrategyInfo }
} = {
  [ChainId.FUJI]: {},
  [ChainId.SPRUCE]: {}
}
