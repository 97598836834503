import { VAULT_DEFAULT_DESCRIPTION, VAULTS_INFO } from 'constants/vaults'
import { Vault as DexbarnVault } from 'types/dexbarn'
import { UserVaultBalances, Vault } from 'types/vault'
import { formatUnits } from 'viem'

import { formattedNum } from './format'

export const convertDexbarnVaultIntoVault = (vault: DexbarnVault): Vault => {
  return {
    apr1D: vault.apr1d,
    chainId: vault.chainId,
    description:
      VAULTS_INFO[vault.chainId][vault.address]?.description ??
      VAULT_DEFAULT_DESCRIPTION,
    feesUsd: vault.feesUsd,
    id: vault.address,
    lbPair: {
      baseFeePct: vault.pair.baseFeePct,
      binStep: vault.pair.binStep,
      id: vault.pair.address,
      version: vault.pair.version === 'v2.0' ? 'v2' : 'v21'
    },
    managementFeePct: vault.strategy.aumAnnualFeePct,
    name: VAULTS_INFO[vault.chainId][vault.address]?.name ?? vault.name,
    strategy: {
      id: vault.strategy.address
    },
    tokenX: vault.tokenX,
    tokenY: vault.tokenY,
    totalValueLockedUSD: vault.tvlUsd
  }
}

export const getUserVaultBalanceUSD = (
  vault: Vault,
  userVaultBalances?: UserVaultBalances
): number | undefined => {
  const userAmountX = userVaultBalances?.amountRawX
    ? formatUnits(userVaultBalances.amountRawX, vault.tokenX.decimals)
    : undefined
  const userAmountY = userVaultBalances?.amountRawY
    ? formatUnits(userVaultBalances.amountRawY, vault.tokenY.decimals)
    : undefined
  const userBalanceUSD =
    userVaultBalances?.tokenXUsdPrice &&
    userVaultBalances.tokenYUsdPrice &&
    userAmountX &&
    userAmountY
      ? Number(userAmountX) * userVaultBalances.tokenXUsdPrice +
        Number(userAmountY) * userVaultBalances.tokenYUsdPrice
      : undefined
  return userBalanceUSD
}

export const getVaultRowFormattedValues = (
  vault: Vault,
  userVaultBalances?: UserVaultBalances
) => {
  const userBalanceUSD = getUserVaultBalanceUSD(vault, userVaultBalances)
  return {
    apr1D: `${formattedNum(vault.apr1D * 100, { places: 2 })}%`,
    feesUsd1D: formattedNum(vault.feesUsd, { usd: true }),
    liquidity: formattedNum(vault.totalValueLockedUSD, { usd: true }),
    myFunds: userBalanceUSD ? formattedNum(userBalanceUSD, { usd: true }) : '--'
  }
}
