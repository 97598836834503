import { useInfiniteQuery } from '@tanstack/react-query'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { LimitOrder, OrderEventType } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'
import { useAccount } from 'wagmi'

interface UseGetOrders {
  lbPairAddr?: string
  pageSize?: number
}

export interface UseGetOrdersResult {
  fetchNextPage: () => void
  isFetchingNextPage: boolean
  isLoading: boolean
  orders: LimitOrder[]
  ordersByStatus: {
    [OrderEventType.PLACED]: LimitOrder[]
    [OrderEventType.EXECUTED]: LimitOrder[]
    [OrderEventType.CANCELLED]: LimitOrder[]
    [OrderEventType.CLAIMED]: LimitOrder[]
  }
  refetch: () => void
  hasNextPage?: boolean
}

const useGetOrders = ({
  lbPairAddr,
  pageSize = 25
}: UseGetOrders = {}): UseGetOrdersResult => {
  const { address: account } = useAccount()
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)

  const enabled = !!account
  const fetchUserOrders = useDexbarnGet<LimitOrder[]>(
    `/v1/orders/${account}?pageSize=${pageSize}`
  )
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch
  } = useInfiniteQuery<LimitOrder[]>(
    ['UserOrders', chain, account, lbPairAddr, pageSize],
    ({ pageParam }) =>
      fetchUserOrders({
        params: {
          chain,
          orderStatus: 'all',
          orderType: 'all',
          pageNum: pageParam,
          pairId: lbPairAddr?.toLowerCase()
        }
      }),
    {
      enabled,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.length % pageSize === 0 && lastPage.length !== 0
          ? allPages.length + 1
          : undefined
    }
  )

  const orders = data?.pages.flat() ?? []

  const ordersByStatus = {
    [OrderEventType.PLACED]: orders.filter(
      (el) => el.status === OrderEventType.PLACED
    ),
    [OrderEventType.EXECUTED]: orders.filter(
      (el) => el.status === OrderEventType.EXECUTED
    ),
    [OrderEventType.CANCELLED]: orders.filter(
      (el) => el.status === OrderEventType.CANCELLED
    ),
    [OrderEventType.CLAIMED]: orders.filter(
      (el) => el.status === OrderEventType.CLAIMED
    )
  }

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: isLoading && enabled,
    orders,
    ordersByStatus,
    refetch
  }
}

export default useGetOrders
