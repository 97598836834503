import { getTokenLogoURL } from 'components/CurrencyLogo'
import useChainId from 'hooks/useChainId'
import { useTokenBalance } from 'hooks/useTokenBalance'
import { useMemo } from 'react'
import { TokenInfo } from 'types/tokensList'
import { getAddress, isAddress } from 'viem'
import { useToken } from 'wagmi'

interface UseTokenSearchProps {
  query: string
}

const useTokenSearch = ({
  query
}: UseTokenSearchProps): (TokenInfo & { balance?: string }) | undefined => {
  const chainId = useChainId()
  const queryAsAddress = isAddress(query) ? getAddress(query) : undefined
  const { data: token } = useToken({
    address: queryAsAddress,
    chainId,
    enabled: !!queryAsAddress
  })
  const { data: balance } = useTokenBalance({
    enabled: !!queryAsAddress,
    token: queryAsAddress
  })
  return useMemo(() => {
    if (!token) return undefined
    return {
      address: token.address,
      balance: balance?.formatted,
      chainId,
      decimals: token.decimals,
      logoURI: getTokenLogoURL(token.address),
      name: token.name,
      symbol: token.symbol,
      tags: []
    }
  }, [chainId, balance, token])
}

export default useTokenSearch
