import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { Currency } from '@traderjoe-team/spruce-sdk'
import {
  LB_ROUTER_V21_ADDRESS,
  LBRouterV21ABI
} from '@traderjoe-team/spruce-sdk-v2'
import { SendTransactionResult } from '@wagmi/core'
import useChainId from 'hooks/useChainId'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import useTransactionToast from 'hooks/useTransactionToast'
import { useIsRemoveLiquidityInNativeCurrencyEnabled } from 'state/settings/hooks'
import { formattedNum } from 'utils/format'
import { getConfigWithGasLimitIncreased } from 'utils/gas'
import { capturePrepareContractWriteError } from 'utils/logger'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { formatUnits, getAddress } from 'viem'
import {
  useAccount,
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi'

export enum RemoveLiquidityV2Option {
  BOTH_TOKENS,
  TOKEN_A,
  TOKEN_B
}

interface UseRemoveLiquidityV2Props {
  currency0: Currency
  currency1: Currency
  isV21: boolean
  onSuccess: () => void
  amount0Min?: string
  amount1Min?: string
  binStep?: string
  enabled?: boolean
  liquidityToRemove?: string[]
  userPositionIds?: number[]
}

const useRemoveLiquidityV2 = ({
  amount0Min,
  amount1Min,
  binStep,
  currency0,
  currency1,
  enabled,
  isV21,
  liquidityToRemove,
  onSuccess,
  userPositionIds
}: UseRemoveLiquidityV2Props) => {
  const chainId = useChainId()
  const walletChainId = useNetwork().chain?.id
  const { address: account } = useAccount()
  const transactionDeadline = useTransactionDeadline()

  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { isRemoveLiquidityInNativeCurrencyEnabled } =
    useIsRemoveLiquidityInNativeCurrencyEnabled()
  const shouldUseRemoveLiquidityNative =
    (currency0.isNative || currency1.isNative) &&
    isRemoveLiquidityInNativeCurrencyEnabled

  const address0 = wrappedCurrency(currency0, chainId)?.address
  const address1 = wrappedCurrency(currency1, chainId)?.address

  const _userPositionIds = userPositionIds?.map((id) => BigInt(id))
  const _liquidityToRemove = liquidityToRemove?.map((liquidity) =>
    BigInt(liquidity)
  )

  const canBuildArgs =
    address0 &&
    address1 &&
    binStep &&
    amount0Min &&
    amount1Min &&
    _userPositionIds &&
    _liquidityToRemove &&
    account &&
    transactionDeadline &&
    (amount0Min !== '0' || amount1Min !== '0')

  const removeLiquidityNativeArgs = canBuildArgs
    ? ([
        currency1.isNative ? getAddress(address0) : getAddress(address1),
        Number(binStep),
        currency1.isNative ? BigInt(amount0Min) : BigInt(amount1Min),
        currency1.isNative ? BigInt(amount1Min) : BigInt(amount0Min),
        _userPositionIds,
        _liquidityToRemove,
        account,
        transactionDeadline
      ] as const)
    : undefined

  const removeLiquidityArgs = canBuildArgs
    ? ([
        getAddress(address0),
        getAddress(address1),
        Number(binStep),
        BigInt(amount0Min),
        BigInt(amount1Min),
        _userPositionIds,
        _liquidityToRemove,
        account,
        transactionDeadline
      ] as const)
    : undefined

  const {
    config: removeLiquidityNativeConfigV21,
    error: removeLiquidityNativeConfigErrorV21,
    isLoading: isPreparingRemoveLiquidityNativeConfigV21
  } = usePrepareContractWrite({
    abi: LBRouterV21ABI,
    address: getAddress(LB_ROUTER_V21_ADDRESS[chainId]),
    args: removeLiquidityNativeArgs,
    cacheTime: 0,
    enabled:
      isV21 &&
      shouldUseRemoveLiquidityNative &&
      walletChainId === chainId &&
      !!removeLiquidityNativeArgs &&
      enabled === true,
    functionName: 'removeLiquidityNATIVE',
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const {
    config: removeLiquidityConfigV21,
    error: removeLiquidityConfigErrorV21,
    isLoading: isPreparingRemoveLiquidityConfigV21
  } = usePrepareContractWrite({
    abi: LBRouterV21ABI,
    address: getAddress(LB_ROUTER_V21_ADDRESS[chainId]),
    args: removeLiquidityArgs,
    cacheTime: 0,
    enabled:
      isV21 &&
      !shouldUseRemoveLiquidityNative &&
      walletChainId === chainId &&
      !!removeLiquidityArgs &&
      enabled === true,
    functionName: 'removeLiquidity',
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const onContractWriteSuccess = (data: SendTransactionResult) => {
    const description = `Remove ${formattedNum(
      formatUnits(BigInt(amount0Min ?? '0'), currency0.decimals)
    )} ${currency0.symbol} and ${formattedNum(
      formatUnits(BigInt(amount1Min ?? '0'), currency1.decimals)
    )} ${currency1.symbol} from pool`
    addRecentTransaction({ description, hash: data.hash })
    addTransactionToast({ description, hash: data.hash })
  }

  const { data, isLoading, reset, write } = useContractWrite({
    ...getConfigWithGasLimitIncreased({
      config: shouldUseRemoveLiquidityNative
        ? removeLiquidityNativeConfigV21
        : removeLiquidityConfigV21,
      percentageIncrease: 10
    }),
    onSuccess: onContractWriteSuccess
  })

  const { data: receipt, isLoading: isWaitingForTransaction } =
    useWaitForTransaction({
      hash: data?.hash,
      onSuccess
    })

  return {
    isPreparingConfig: shouldUseRemoveLiquidityNative
      ? isPreparingRemoveLiquidityNativeConfigV21
      : isPreparingRemoveLiquidityConfigV21,
    isRemovingLiquidity: isLoading || isWaitingForTransaction,
    isSuccess: receipt?.status === 'success',
    prepareConfigError: shouldUseRemoveLiquidityNative
      ? removeLiquidityNativeConfigErrorV21
      : removeLiquidityConfigErrorV21,
    removeLiquidity: write,
    resetRemoveLiquidity: reset
  }
}

export default useRemoveLiquidityV2
