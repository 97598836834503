import { LBPairABI, LBPairV21ABI } from '@traderjoe-team/spruce-sdk-v2'
import useChainId from 'hooks/useChainId'
import { getAddress } from 'viem'
import { useContractRead, useContractReads } from 'wagmi'

interface UseLBPairFeeParametersProps {
  isV21?: boolean
  lbPairAddress?: string
}

const useLBPairFeeParameters = ({
  isV21,
  lbPairAddress
}: UseLBPairFeeParametersProps) => {
  const chainId = useChainId()

  const { data: feeParameters, isLoading: isLoadingV2 } = useContractRead({
    abi: LBPairABI,
    address: lbPairAddress ? getAddress(lbPairAddress) : undefined,
    chainId,
    enabled: !!lbPairAddress && !isV21,
    functionName: 'feeParameters'
  })

  const contractV21 = {
    abi: LBPairV21ABI,
    address: lbPairAddress ? getAddress(lbPairAddress) : undefined,
    chainId
  }
  const { data: readsV21, isLoading: isLoadingV21 } = useContractReads({
    contracts: [
      {
        ...contractV21,
        functionName: 'getStaticFeeParameters'
      },
      { ...contractV21, functionName: 'getBinStep' }
    ],
    enabled: !!lbPairAddress && isV21
  })
  const binStepV21 = readsV21?.[1].result as number | undefined
  const feeParametersV21 = readsV21?.[0]?.result as unknown[] | undefined

  const binStep = isV21 ? binStepV21 : feeParameters?.binStep
  const baseFactor = isV21
    ? (feeParametersV21?.[0] as number | undefined)
    : feeParameters?.baseFactor
  const baseFee =
    binStep && baseFactor ? (binStep * baseFactor) / 10000 : undefined

  return {
    data: {
      baseFactor,
      baseFee,
      binStep
    },
    isLoading: (isLoadingV2 && !isV21) || (isLoadingV21 && isV21)
  }
}

export default useLBPairFeeParameters
