import { Button } from '@chakra-ui/react'
import { Token } from '@traderjoe-team/spruce-sdk'
import useAddTokenToWallet from 'hooks/useAddTokenToWallet'
import React from 'react'
import { tradeAddToWallet } from 'utils/measure'

interface AddToWalletButtonProps {
  token: Token
}

const AddToWalletButton = ({ token }: AddToWalletButtonProps) => {
  const addTokenToWallet = useAddTokenToWallet()
  return (
    <Button
      variant="ghost"
      size="sm"
      color="textPrimary"
      _hover={{ bg: 'bgTertiary' }}
      onClick={() => {
        addTokenToWallet(token)
        tradeAddToWallet(token.symbol)
      }}
    >{`Add ${token.symbol} to Wallet`}</Button>
  )
}

export default AddToWalletButton
