import 'theme/index.css'
import '@rainbow-me/rainbowkit/styles.css'

import { ChakraProvider, Hide, Show } from '@chakra-ui/react'
import Header from 'components/Header'
import MobileBottomTabBar from 'components/MobileBottomTabBar'
import RainbowKitProvider from 'components/RainbowKitProvider'
import ScrollToTop from 'components/ScrollToTop'
import { wagmi } from 'constants/wagmi'
import useChainFromUrlParam from 'hooks/useChainFromUrlParam'
import NotFound from 'pages/404'
import Pool from 'pages/Pool'
import PoolDetailV2 from 'pages/PoolDetailV2'
import VaultDetail from 'pages/VaultDetail'
import React, { useEffect } from 'react'
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate
} from 'react-router-dom'
import { useSelectedChainId } from 'state/settings/hooks'
import { chakraTheme } from 'theme/chakra'
import { getChainSlug } from 'utils/chains'
import { WagmiConfig } from 'wagmi'

import Privacy from './pages/Legal/Privacy'
import TermsOfService from './pages/Legal/TermsOfService'
import Trade from './pages/Trade'

const ElementWithHeader = ({
  element,
  validateChainSlug = true
}: {
  element: JSX.Element
  validateChainSlug?: boolean
}) => {
  const navigate = useNavigate()
  const chain = useChainFromUrlParam()

  useEffect(() => {
    // make sure the chain in the url is supported, else we redirect to 404 page
    if (!chain && validateChainSlug) {
      navigate('/404')
    }
  }, [chain, navigate, validateChainSlug])
  return (
    <>
      <Hide below="md">
        <Header />
      </Hide>
      {element}
    </>
  )
}

export const App = () => {
  const { selectedChainId } = useSelectedChainId()

  return (
    <ChakraProvider theme={chakraTheme}>
      <WagmiConfig config={wagmi.wagmiConfig}>
        <RainbowKitProvider>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              <Route
                path="/:chainSlug"
                element={<ElementWithHeader element={<Pool />} />}
              />
              <Route
                path="/:chainSlug/trade"
                element={<ElementWithHeader element={<Trade />} />}
              />
              <Route
                path="/:chainSlug/pool"
                element={<ElementWithHeader element={<Pool />} />}
              />
              <Route
                path="/:chainSlug/pool/:poolVersion/:currencyId0/:currencyId1/:binStep"
                element={<ElementWithHeader element={<PoolDetailV2 />} />}
              />
              <Route
                path="/:chainSlug/vault/:vaultAddress"
                element={<ElementWithHeader element={<VaultDetail />} />}
              />
              <Route
                path="/privacy"
                element={
                  <ElementWithHeader
                    element={<Privacy />}
                    validateChainSlug={false}
                  />
                }
              />
              <Route
                path="/tos"
                element={
                  <ElementWithHeader
                    element={<TermsOfService />}
                    validateChainSlug={false}
                  />
                }
              />
              <Route
                path="/404"
                element={
                  <ElementWithHeader
                    element={<NotFound />}
                    validateChainSlug={false}
                  />
                }
              />
              {/* Redirect root paths to selected chain */}
              <Route
                path="/"
                element={
                  <Navigate
                    to={`/${getChainSlug(selectedChainId)}/pool`}
                    replace
                  />
                }
              />
              <Route
                path="/pool"
                element={
                  <Navigate
                    to={`/${getChainSlug(selectedChainId)}/pool`}
                    replace
                  />
                }
              />
              <Route
                path="/trade"
                element={
                  <Navigate
                    to={`/${getChainSlug(selectedChainId)}/trade`}
                    replace
                  />
                }
              />
              {/* Unknown paths */}
              <Route path="*" element={<Navigate to="/404" replace />} />
            </Routes>
            <Show below="lg">
              <MobileBottomTabBar />
            </Show>
          </BrowserRouter>
        </RainbowKitProvider>
      </WagmiConfig>
    </ChakraProvider>
  )
}
