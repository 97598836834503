import { ChainId } from '@traderjoe-team/spruce-sdk'

export const FEE_RATE = 0.0025 // 0.25%
export const POOL_SHARE_PRECISION = 100000000

export const POOL_HELMET_TITLE =
  'LB Pro | Decentralized Exchange for Institutions | Pool'
export const POOL_HELMET_DESCRIPTION =
  'Enterprise grade Decentralized Exchange. DeFi experience for compliant institutions. Powered by Liquidity Book Protocol.'

interface RewarderInfo {
  lbPair: string
  rewards: Reward[]
}

interface Reward {
  rewardedToken: string
  rewardPerDay: number
}

export const REWARD_TOKENS: { [chainId in ChainId]: string[] } = {
  [ChainId.FUJI]: ['0x477Fd10Db0D80eAFb773cF623B258313C3739413'],
  [ChainId.SPRUCE]: ['0x477Fd10Db0D80eAFb773cF623B258313C3739413']
}

const REWARDER_LISTS: { [chainId in ChainId]: RewarderInfo[] } = {
  [ChainId.FUJI]: [
    {
      lbPair: '0x88f36a6b0e37e78d0fb1d41b07a47bad3d995453',
      rewards: [
        {
          rewardPerDay: 1000,
          rewardedToken: '0x477fd10db0d80eafb773cf623b258313c3739413'
        }
      ]
    },
    {
      lbPair: '0x96ce9f178d2a96387f21f916f7b1338310be8f4c',
      rewards: [
        {
          rewardPerDay: 500,
          rewardedToken: '0x477fd10db0d80eafb773cf623b258313c3739413'
        }
      ]
    },
    {
      lbPair: '0x739e1b8a2e11413540d622fc5c91694bee4177d3',
      rewards: [
        {
          rewardPerDay: 300,
          rewardedToken: '0x477fd10db0d80eafb773cf623b258313c3739413'
        }
      ]
    }
  ],
  [ChainId.SPRUCE]: []
}

export const getRewarderInfo = (chainId: ChainId, lbPair: string) => {
  return REWARDER_LISTS[chainId].find(
    (ri) => ri.lbPair?.toLowerCase() === lbPair.toLowerCase()
  )
}

export const getRewardRates = (chainId: ChainId, lbPair: string) => {
  return getRewarderInfo(chainId, lbPair)?.rewards.map(
    (reward) => reward.rewardPerDay
  )
}

export const getRewardTokens = (chainId: ChainId, lbPair: string) => {
  return getRewarderInfo(chainId, lbPair)?.rewards.map(
    (reward) => reward.rewardedToken
  )
}

export const isPoolRewarded = (chainId: ChainId, lbPair: string) => {
  const rewarderInfo = getRewarderInfo(chainId, lbPair)
  return (
    rewarderInfo &&
    rewarderInfo.rewards.some((reward) => reward.rewardPerDay > 0)
  )
}

export const POOL_DISTRIBUTION_CHART_RADIUS = {
  initial: 60,
  max: 400,
  min: 20,
  step: 40
}

// allow to override new pool url for migrated pools
export const MIGRATED_POOL_URL_BY_ADDRESS: {
  [chainId in ChainId]: { [address: string]: string }
} = {
  [ChainId.FUJI]: {},
  [ChainId.SPRUCE]: {}
}
