import { Box, Flex, Heading, HStack, Text, useToken } from '@chakra-ui/react'
import { Currency } from '@traderjoe-team/spruce-sdk'
import useKeyPress from 'hooks/useKeyPress'
import React, { useMemo, useState } from 'react'
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis
} from 'recharts'
import { LBPairDistribution } from 'types/poolV2'
import { formattedNum } from 'utils/format'
import { inverseLBPairDistributions } from 'utils/poolV2'

import LBPairDistributionChartTooltip from './LBPairDistributionChartTooltip'

interface LBPairDistributionChartProps {
  activeBinId: number
  data: LBPairDistribution[]
  title: string
  bottomRightElement?: JSX.Element
  currency0?: Currency
  currency1?: Currency
  isPriceRatioInversed?: boolean
}

const LBPairDistributionChart = ({
  activeBinId,
  bottomRightElement,
  currency0,
  currency1,
  data: initialData,
  isPriceRatioInversed = false,
  title
}: LBPairDistributionChartProps) => {
  const [graphPurpleLight, graphPurple, textSecondary] = useToken('colors', [
    'graphPurpleLight',
    'graphPurple',
    'textSecondary'
  ])
  const [focusBar, setFocusBar] = useState<number | undefined>()

  const [priceDecimals, setPriceDecimals] = useState(5)
  useKeyPress({
    onUp: () => setPriceDecimals((prev) => (prev === 8 ? 5 : 8)),
    targetKey: 'g'
  })

  // inverse data if price ratio is inversed
  const data = useMemo(
    () =>
      isPriceRatioInversed
        ? inverseLBPairDistributions(initialData)
        : initialData,
    [initialData, isPriceRatioInversed]
  )

  const activeBinIndex = data.findIndex((el) => el.isActiveBin)
  const activeBin = activeBinIndex >= 0 ? data[activeBinIndex] : undefined

  // custom ticks to always show active bin
  const xAxisTicks = useMemo(() => {
    const interval = 5 // set interval

    // active bin included in bins to display
    if (activeBinIndex >= 0) {
      const activeBinPrice = data[activeBinIndex]?.price

      const higherBins = data
        .filter(
          (_el, i) =>
            i > activeBinIndex && (i - activeBinIndex) % interval === 0
        )
        .map((el) => el.price)
      const lowerBins = data
        .filter(
          (_el, i) =>
            i < activeBinIndex && (activeBinIndex - i) % interval === 0
        )
        .map((el) => el.price)

      return [...lowerBins, activeBinPrice, ...higherBins]
    }

    // data loaded but active bin not among bins to display
    else if (data[0] && data[0].price !== '0') {
      return data
        .filter((_el, i) => Math.abs(i - activeBinIndex) % interval === 0)
        .map((el) => el.price)
    }

    // data not loaded yet
    return undefined
  }, [data, activeBinIndex])

  const activeBinReserveXRatio = useMemo(() => {
    if (!activeBin) {
      return undefined
    }
    const { amountYPct } = activeBin
    const amountYPctNum = Number(amountYPct)
    const amountXPctNum = 100 - amountYPctNum
    return amountXPctNum
  }, [activeBin])

  return (
    <Box w="full" pos="relative" pr={{ base: 0, md: 8 }} pt={4}>
      <Box pos="absolute" bottom={14} right={{ base: 0, md: 6 }} zIndex={1}>
        {bottomRightElement}
      </Box>
      <Flex w="full" justify="space-between" mb={4}>
        <Heading size="md" mb={2}>
          {title}
        </Heading>
        <Flex flexDir="column">
          <HStack>
            <HStack spacing={1}>
              <Box boxSize="10px" borderRadius="full" bg="graphPurple" />
              <Text fontSize="sm" fontWeight="semibold">
                {currency1?.symbol}
              </Text>
            </HStack>
            <HStack spacing={1}>
              <Box boxSize="10px" borderRadius="full" bg="graphPurpleLight" />
              <Text fontSize="sm" fontWeight="semibold">
                {currency0?.symbol}
              </Text>
            </HStack>
          </HStack>
        </Flex>
      </Flex>
      <Box h="160px">
        <ResponsiveContainer width="99%">
          <BarChart
            data={data}
            onMouseMove={(state) => {
              setFocusBar(
                state.isTooltipActive ? state.activeTooltipIndex : undefined
              )
            }}
            onMouseLeave={() => {
              setFocusBar(undefined)
            }}
          >
            <Tooltip
              wrapperStyle={{ outline: 'none' }}
              cursor={{ fill: 'transparent' }}
              content={<LBPairDistributionChartTooltip />}
            />
            <XAxis
              xAxisId="0"
              tickSize={0}
              axisLine={false}
              dataKey="price"
              ticks={xAxisTicks}
              tickFormatter={(val) =>
                formattedNum(val, {
                  allowDecimalsOver1000: true,
                  allowSmallDecimals: true,
                  places: priceDecimals
                })
              }
              fontSize="12px"
              fontWeight="semibold"
              tick={{ fill: textSecondary }}
              tickMargin={12}
            />
            <defs>
              <linearGradient id="tokenXColor" x1="0" y1="0" x2="0" y2="1">
                <stop stopColor={graphPurpleLight} fillOpacity={1} />
                <stop
                  offset="100%"
                  stopColor={graphPurpleLight}
                  fillOpacity={1}
                />
              </linearGradient>
              <linearGradient id="tokenYColor" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={graphPurple} />
                <stop offset="100%" stopColor={graphPurple} />
              </linearGradient>
              {activeBinReserveXRatio !== undefined ? (
                <linearGradient id="activeBinColor" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor={graphPurpleLight} />
                  <stop
                    offset={`${activeBinReserveXRatio}%`}
                    stopColor={graphPurpleLight}
                  />
                  <stop
                    offset={`${activeBinReserveXRatio}%`}
                    stopColor={graphPurple}
                  />
                  <stop offset="100%" stopColor={graphPurple} />
                </linearGradient>
              ) : null}
              <linearGradient
                opacity={0.5}
                id="barChartYColorActive"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="0%" stopColor={graphPurple} stopOpacity={1} />
                <stop offset="100%" stopColor={graphPurple} stopOpacity={0} />
              </linearGradient>
              <linearGradient
                opacity={0.5}
                id="barChartXColorActive"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="0%" stopColor={graphPurpleLight} />
                <stop offset="100%" stopColor={graphPurpleLight} />
              </linearGradient>
            </defs>
            <Bar dataKey="liquidity" radius={2}>
              {data.map((data, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    focusBar === index && data.binId < activeBinId
                      ? 'url(#barChartYColorActive)'
                      : focusBar === index && data.binId > activeBinId
                      ? 'url(#barChartXColorActive)'
                      : data.binId < activeBinId
                      ? 'url(#tokenYColor)'
                      : data.binId > activeBinId
                      ? 'url(#tokenXColor)'
                      : 'url(#activeBinColor)'
                  }
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  )
}

export default LBPairDistributionChart
