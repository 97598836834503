import { ChevronDownIcon, SearchIcon, WarningTwoIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, Hide, IconButton, Text } from '@chakra-ui/react'
import { ConnectButton as RainbowKitConnectButton } from '@rainbow-me/rainbowkit'
import HeaderSearchPanel from 'components/HeaderSearchPanel'
import WalletMenu from 'components/WalletMenu'
import { supportedChains } from 'constants/chains'
import useAutoConnect from 'hooks/useAutoConnect'
import useChainFromUrlParam from 'hooks/useChainFromUrlParam'
import useSwitchNetwork from 'hooks/useSwitchNetwork'
import React, { useState } from 'react'
import { useSelectedChainId } from 'state/settings/hooks'
import { WalletIcon } from 'theme/icons'

interface ConnectButtonProps {
  chainSelector?: boolean
}

const ConnectButton = ({ chainSelector }: ConnectButtonProps) => {
  const { switchNetwork } = useSwitchNetwork()
  const { selectedChainId } = useSelectedChainId()
  const [isSearching, setIsSearching] = useState(false)
  const toggleSearch = () => setIsSearching(!isSearching)

  const selectedChain = supportedChains.filter(
    (chain) => chain.id === selectedChainId
  )[0]
  const chainInUrlParam = useChainFromUrlParam()
  const targetChain = chainInUrlParam ?? selectedChain

  useAutoConnect()

  return (
    <RainbowKitConnectButton.Custom>
      {({ account, chain, mounted, openAccountModal, openConnectModal }) => {
        const ready = mounted
        const connected = ready && account && chain

        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none'
              }
            })}
          >
            {(() => {
              return (
                <Flex alignItems="center" justifyContent="center" gap={4}>
                  {chainSelector ? null : !connected ? (
                    <>
                      <Hide below="lg">
                        <Button
                          variant="outline"
                          h="48px"
                          leftIcon={<WalletIcon w="20px" />}
                          onClick={openConnectModal}
                          flexShrink={0}
                        >
                          <Text>Connect Wallet</Text>
                        </Button>
                      </Hide>
                      <Hide above="lg">
                        <IconButton
                          aria-label="connect-wallet"
                          w="full"
                          onClick={openConnectModal}
                        >
                          <Flex alignItems="center">
                            <WalletIcon w="21px" h="18px" />
                            <Box
                              boxSize={2}
                              position="relative"
                              bottom={2}
                              borderRadius="full"
                              bgColor="avaxRed"
                            />
                          </Flex>
                        </IconButton>
                      </Hide>
                    </>
                  ) : chain.id !== targetChain.id ? (
                    <>
                      <Hide below="md">
                        <Button
                          flexShrink={0}
                          data-cy="wrong-network-button"
                          colorScheme="red"
                          h="48px"
                          rightIcon={<ChevronDownIcon />}
                          onClick={() => switchNetwork?.(targetChain.id)}
                        >
                          Wrong network
                        </Button>
                      </Hide>
                      <Hide above="md">
                        <Button
                          data-cy="wrong-network-button"
                          colorScheme="red"
                          w="full"
                          onClick={() => switchNetwork?.(targetChain.id)}
                        >
                          <WarningTwoIcon />
                        </Button>
                      </Hide>
                    </>
                  ) : (
                    <>
                      <Hide below="lg">
                        <Flex columnGap={4}>
                          {isSearching ? (
                            <Flex w="350px">
                              <HeaderSearchPanel toggleSearch={toggleSearch} />
                            </Flex>
                          ) : (
                            <IconButton
                              icon={<SearchIcon />}
                              aria-label="search"
                              onClick={toggleSearch}
                              variant="ghost"
                            />
                          )}
                          <WalletMenu walletAddress={account.address} />
                        </Flex>
                      </Hide>
                      <Hide above="lg">
                        <Button w="full" onClick={openAccountModal}>
                          <Flex alignItems="center">
                            <WalletIcon
                              fill={connected ? 'textPrimary' : 'textSecondary'}
                              w="21px"
                              h="18px"
                            />
                            <Box
                              boxSize={2}
                              position="relative"
                              bottom={2}
                              borderRadius="full"
                              bgColor="green.300"
                            />
                          </Flex>
                        </Button>
                      </Hide>
                    </>
                  )}
                </Flex>
              )
            })()}
          </div>
        )
      }}
    </RainbowKitConnectButton.Custom>
  )
}

export default ConnectButton
