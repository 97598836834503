import { avalancheSpruce } from '@traderjoe-team/spruce-sdk'
import {
  avalanche as wagmiAvalanche,
  avalancheFuji as wagmiAvalancheFuji
} from '@wagmi/core/chains'
import AvalancheIcon from 'assets/avalanche.svg'
import { Chain } from 'wagmi'

export interface JoeChain {
  iconUrl: string
  slug: string
}

// DEV: needed for core wallet overrides. (see src/constants/wallet)
export const avalanche: Chain & JoeChain = {
  ...wagmiAvalanche,
  iconUrl: AvalancheIcon,
  slug: 'avalanche'
}

const fuji: Chain & JoeChain = {
  ...wagmiAvalancheFuji,
  iconUrl: AvalancheIcon,
  slug: 'fuji'
}

const spruce: Chain & JoeChain = {
  ...avalancheSpruce,
  iconUrl: AvalancheIcon,
  slug: 'spruce'
}

export const supportedChains = [fuji, spruce]

export const selectableChains = [fuji, spruce]

export const defaultChain = spruce
