import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { Currency } from '@traderjoe-team/spruce-sdk'
import {
  Bin,
  LB_ROUTER_V21_ADDRESS,
  LBRouterV21ABI,
  LiquidityDistributionParams
} from '@traderjoe-team/spruce-sdk-v2'
import { SendTransactionResult } from '@wagmi/core'
import useActiveChain from 'hooks/useActiveChain'
import useChainId from 'hooks/useChainId'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import useTransactionToast from 'hooks/useTransactionToast'
import { useCallback, useMemo } from 'react'
import { useSlippageSettings } from 'state/settings/hooks'
import { formattedNum } from 'utils/format'
import { getConfigWithGasLimitIncreased } from 'utils/gas'
import { capturePrepareContractWriteError } from 'utils/logger'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { formatUnits, getAddress } from 'viem'
import {
  useAccount,
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi'

export interface UseAddLiquidityV2Props {
  activeBinId?: bigint
  amount0?: bigint
  amount1?: bigint
  binStep?: string
  currency0?: Currency
  currency1?: Currency
  distributionParams?: LiquidityDistributionParams
  enabled?: boolean
  onPrepareContractWriteError?: (error: Error) => void
  onSuccess?: () => void
}

const useAddLiquidityV2 = ({
  activeBinId,
  amount0,
  amount1,
  binStep,
  currency0,
  currency1,
  distributionParams,
  enabled,
  onPrepareContractWriteError,
  onSuccess
}: UseAddLiquidityV2Props) => {
  const chainId = useChainId()
  const walletChainId = useNetwork().chain?.id
  const { nativeCurrency } = useActiveChain()
  const { address: account } = useAccount()
  const { slippageSettings } = useSlippageSettings()
  const deadline = useTransactionDeadline()

  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const isCurrency0Native = currency0?.symbol === nativeCurrency.symbol
  const isCurrency1Native = currency1?.symbol === nativeCurrency.symbol
  const isOneCurrencyNative = isCurrency0Native || isCurrency1Native

  const token0Address = wrappedCurrency(currency0, chainId)?.address
  const token1Address = wrappedCurrency(currency1, chainId)?.address

  const allowedPriceSlippage = slippageSettings.liquidityPriceV2 * 100
  const priceSlippage = allowedPriceSlippage / 10000
  const idSlippage = Bin.getIdSlippageFromPriceSlippage(
    priceSlippage,
    Number(binStep)
  )

  const allowedAmountsSlippage =
    10000 - slippageSettings.liquidityAmountV2 * 100
  const amountMin0 =
    amount0 !== undefined
      ? (amount0 * BigInt(allowedAmountsSlippage)) / BigInt(10000)
      : undefined
  const amountMin1 =
    amount1 !== undefined
      ? (amount1 * BigInt(allowedAmountsSlippage)) / BigInt(10000)
      : undefined

  const { deltaIds, distributionX, distributionY } = distributionParams || {}

  const addLiquidityArgs =
    token0Address &&
    token1Address &&
    binStep &&
    amount0 !== undefined &&
    amount1 !== undefined &&
    amountMin0 !== undefined &&
    amountMin1 !== undefined &&
    deadline &&
    account &&
    activeBinId &&
    deltaIds &&
    distributionX &&
    distributionY
      ? ([
          {
            activeIdDesired: activeBinId,
            amountX: amount0,
            amountXMin: amountMin0,
            amountY: amount1,
            amountYMin: amountMin1,
            binStep: BigInt(binStep),
            deadline,
            deltaIds: deltaIds.map((id) => BigInt(id)),
            distributionX,
            distributionY,
            idSlippage: BigInt(idSlippage),
            refundTo: account,
            to: account,
            tokenX: getAddress(token0Address),
            tokenY: getAddress(token1Address)
          }
        ] as const)
      : undefined

  const prepareContractWriteProps = {
    cacheTime: 0,
    chainId,
    enabled: enabled === true && walletChainId === chainId,
    onError: onPrepareContractWriteError
  }

  const addLiquidityNativeResult = usePrepareContractWrite({
    ...prepareContractWriteProps,
    abi: LBRouterV21ABI,
    address: getAddress(LB_ROUTER_V21_ADDRESS[chainId]),
    args: addLiquidityArgs,
    enabled:
      prepareContractWriteProps.enabled &&
      !!addLiquidityArgs &&
      isOneCurrencyNative,
    functionName: 'addLiquidityNATIVE',
    onSettled: (_, error: Error | null) => {
      capturePrepareContractWriteError(error)
    },
    value: (isCurrency0Native ? amount0 : amount1) ?? BigInt(0)
  })

  const addLiquidityResult = usePrepareContractWrite({
    ...prepareContractWriteProps,
    abi: LBRouterV21ABI,
    address: getAddress(LB_ROUTER_V21_ADDRESS[chainId]),
    args: addLiquidityArgs,
    enabled:
      prepareContractWriteProps.enabled &&
      !!addLiquidityArgs &&
      !isOneCurrencyNative,
    functionName: 'addLiquidity',
    onSettled: (_, error: Error | null) => {
      capturePrepareContractWriteError(error)
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const transactionSummary = useMemo(
    () =>
      amount0 !== undefined && amount1 !== undefined && currency0 && currency1
        ? `Add ${formattedNum(formatUnits(amount0, currency0.decimals))} ${
            currency0?.symbol
          } and ${formattedNum(formatUnits(amount1, currency1.decimals))} ${
            currency1?.symbol
          }`
        : undefined,
    [currency0, currency1, amount0, amount1]
  )

  const onContractWriteSuccess = useCallback(
    (data: SendTransactionResult) => {
      if (!transactionSummary) return
      addRecentTransaction({
        description: transactionSummary,
        hash: data.hash
      })
      addTransactionToast({ description: transactionSummary, hash: data.hash })
    },
    [addRecentTransaction, addTransactionToast, transactionSummary]
  )

  const { data, isLoading, write } = useContractWrite({
    ...getConfigWithGasLimitIncreased({
      config: isOneCurrencyNative
        ? addLiquidityNativeResult.config
        : addLiquidityResult.config,
      percentageIncrease: 10
    }),
    onSuccess: onContractWriteSuccess
  })

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransaction({
      hash: data?.hash,
      onSuccess
    })

  return {
    addLiquidity: write,
    isLoading: isWaitingForTransaction || isLoading,
    isPreparingConfig: isOneCurrencyNative
      ? addLiquidityNativeResult.isLoading
      : addLiquidityResult.isLoading,
    isSuccess,
    prepareConfigError: isOneCurrencyNative
      ? addLiquidityNativeResult.error
      : addLiquidityResult.error
  }
}

export default useAddLiquidityV2
