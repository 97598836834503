import { useQuery } from '@tanstack/react-query'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { SwapEvent } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'

interface UseLBPairProps {
  address?: string
  enabled?: boolean
  first?: number
}

const useLBPairSwaps = ({
  address,
  enabled = true,
  first = 10
}: UseLBPairProps) => {
  const chainId = useChainId()
  const fetchSwapEvents = useDexbarnGet<SwapEvent[]>(
    `/v1/events/swap/${getDexbarnChainParam(
      chainId
    )}/${address?.toLowerCase()}?pageNum=1&pageSize=${first}`
  )
  const result = useQuery<SwapEvent[]>(
    ['LBPairSwapsQuery', address, chainId, first],
    () => fetchSwapEvents(),
    { enabled: !!address && enabled }
  )
  return result
}

export default useLBPairSwaps
