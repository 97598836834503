import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { LBPairV21ABI } from '@traderjoe-team/spruce-sdk-v2'
import { SendTransactionResult } from '@wagmi/core'
import { LBPoolVersion } from 'types/pool'
import { capturePrepareContractWriteError } from 'utils/logger'
import { getAddress } from 'viem'
import {
  erc721ABI,
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi'

import useChainId from './useChainId'
import useTransactionToast from './useTransactionToast'

interface UseApproveForAllIfNeededProps {
  lbPoolVersion: LBPoolVersion
  spender: string
  token?: string
  tokenSymbol?: string
}

const useApproveForAllIfNeeded = ({
  lbPoolVersion,
  spender,
  token,
  tokenSymbol
}: UseApproveForAllIfNeededProps) => {
  const { address: account } = useAccount()
  const chainId = useChainId()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { data: isApprovedForAll } = useContractRead({
    abi: erc721ABI,
    address: token ? getAddress(token) : undefined,
    args: account ? [account, getAddress(spender)] : undefined,
    chainId,
    enabled: !!account,
    functionName: 'isApprovedForAll'
  })

  const { config: configV2 } = usePrepareContractWrite({
    abi: erc721ABI,
    address: token ? getAddress(token) : undefined,
    args: [getAddress(spender), true],
    cacheTime: 0,
    enabled: isApprovedForAll === false && lbPoolVersion === 'v2',
    functionName: 'setApprovalForAll',
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const { config: configV21 } = usePrepareContractWrite({
    abi: LBPairV21ABI,
    address: token ? getAddress(token) : undefined,
    args: [getAddress(spender), true],
    cacheTime: 0,
    enabled: isApprovedForAll === false && lbPoolVersion === 'v21',
    functionName: 'approveForAll',
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const onWriteSuccess = (data: SendTransactionResult) => {
    const description = `Approve ${tokenSymbol}`
    addRecentTransaction({
      description,
      hash: data.hash
    })
    addTransactionToast({ description, hash: data.hash })
  }

  const {
    data: dataV2,
    isLoading: isLoadingV2,
    write: writeV2
  } = useContractWrite({
    ...configV2,
    onSuccess: onWriteSuccess
  })

  const {
    data: dataV21,
    isLoading: isLoadingV21,
    write: writeV21
  } = useContractWrite({
    ...configV21,
    onSuccess: onWriteSuccess
  })

  const {
    data: receipt,
    isLoading: isWaitingForTransaction,
    isSuccess
  } = useWaitForTransaction({
    hash: lbPoolVersion === 'v21' ? dataV21?.hash : dataV2?.hash
  })

  return {
    approve: lbPoolVersion === 'v21' ? writeV21 : writeV2,
    isApproved: isApprovedForAll === true || receipt?.status === 'success',
    isApproving:
      (lbPoolVersion === 'v21' && isLoadingV21) ||
      (lbPoolVersion === 'v2' && isLoadingV2) ||
      isWaitingForTransaction,
    isSuccess
  }
}

export default useApproveForAllIfNeeded
