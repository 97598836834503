import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { useEffect, useState } from 'react'
import { DexAnalyticsData } from 'types/analytics'
import { getDexbarnChainParam } from 'utils/chains'

dayjs.extend(utc)

interface ChartData {
  date: number
  value: number
}

interface PoolData {
  liquidity: ChartData[]
  volume: ChartData[]
  volume1D: number // volume during the most recent full UTC day (00:00 UTC - 23:59 UTC)
  volume24: number // volume in the last 24 hours
}

interface AnalyticsData {
  v2Data: PoolData
}

const usePoolHomeAnalytics = () => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)

  const DATA_LENGTH = 180
  const SECONDS_IN_DAY = 60 * 60 * 24

  const utcDataLengthDaysBack = dayjs()
    .utc()
    .startOf('day')
    .subtract(DATA_LENGTH - 1, 'day')
    .unix()

  const now = dayjs()
  const yesterday = now.subtract(1, 'day')

  const [analyticsData, setAnalyticsData] = useState<AnalyticsData>({
    v2Data: { liquidity: [], volume: [], volume1D: 0, volume24: 0 }
  })

  const fetchDexAnalyticsDayData = useDexbarnGet<DexAnalyticsData[]>(
    `/v1/dex/analytics/${chain}?startTime=${utcDataLengthDaysBack}&aggregateBy=daily`
  )

  const fetchDexAnalyticsHourlyData = useDexbarnGet<DexAnalyticsData[]>(
    `/v1/dex/analytics/${chain}?startTime=${yesterday.unix()}&endTime=${now.unix()}&aggregateBy=hourly`
  )

  const { data: dayDatasV2, isLoading: dayDatasV2Loading } = useQuery<
    DexAnalyticsData[]
  >({
    keepPreviousData: true,
    queryFn: () => fetchDexAnalyticsDayData(),
    queryKey: ['dayDataV2', chain]
  })

  const { data: todayData, isLoading: todayDataLoading } = useQuery<
    DexAnalyticsData[]
  >({
    keepPreviousData: true,
    queryFn: () => fetchDexAnalyticsHourlyData(),
    queryKey: ['hourlyDayDataV2', chain]
  })

  useEffect(() => {
    if (dayDatasV2 && todayData) {
      let currentDayTimestamp = utcDataLengthDaysBack

      const v2Data: PoolData = {
        liquidity: [],
        volume: [],
        volume1D: 0,
        volume24: 0
      }

      let v2DataIndex = 0

      // create dummy array to sync length of filled data with DATA_LENGTH
      new Array(DATA_LENGTH).fill(0).forEach((_, index) => {
        // V2 DATA
        // condition if timestamp of current iteration is in data from dexbarn
        if (
          v2DataIndex < dayDatasV2.length &&
          dayDatasV2[v2DataIndex].timestamp === currentDayTimestamp
        ) {
          v2Data.liquidity.push({
            date: currentDayTimestamp,
            value: dayDatasV2[v2DataIndex].reserveUsd
          })
          v2Data.volume.push({
            date: currentDayTimestamp,
            value: dayDatasV2[v2DataIndex].volumeUsd
          })

          v2DataIndex++
        } else {
          try {
            // if the past day timestamp have a defined TVL, use it for current timestamp if data
            // for current timestamp is not found in the data from subgraph
            v2Data.liquidity.push({
              date: currentDayTimestamp,
              value: v2Data.liquidity[index - 1].value
            })
          } catch (e) {
            // default to zero if past day TVL is undefined (edge case when index = 0)
            v2Data.liquidity.push({
              date: currentDayTimestamp,
              value: 0
            })
          }

          // volume and fees for day timestamps not found in subgraph should default to zero
          v2Data.volume.push({
            date: currentDayTimestamp,
            value: 0
          })
        }

        currentDayTimestamp += SECONDS_IN_DAY

        const v2VolumeDatas: ChartData[] = todayData.map((el) => ({
          date: el.timestamp,
          value: el.volumeUsd
        }))

        const v2Volume24H = v2VolumeDatas.reduce(
          (accu: number, currData: ChartData) => accu + currData.value,
          0
        )

        // set volume24
        v2Data.volume24 = v2Volume24H

        // set volume1D
        v2Data.volume1D =
          v2Data.volume.length > 1
            ? v2Data.volume[v2Data.volume.length - 1].value
            : 0

        setAnalyticsData({ v2Data })
      })
    }
  }, [dayDatasV2, todayData, utcDataLengthDaysBack, SECONDS_IN_DAY])

  return {
    analyticsData,
    isLoading: dayDatasV2Loading || todayDataLoading
  }
}

export default usePoolHomeAnalytics
