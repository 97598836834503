import { Divider } from '@chakra-ui/react'
import React from 'react'
import { WalletMenuEnum } from 'types/walletMenu'

import ConnectedAccount from './ConnectedAccount'
import WalletMyInfo from './WalleMyInfo'

interface ConnectedScreenProps {
  setWalletMenuDisplay: (menuDisplay: WalletMenuEnum) => void
  walletAddress: string
}

const ConnectedScreen = ({
  setWalletMenuDisplay,
  walletAddress
}: ConnectedScreenProps) => {
  return (
    <>
      <ConnectedAccount walletAddress={walletAddress} />
      <Divider />
      <WalletMyInfo setWalletMenuDisplay={setWalletMenuDisplay} />
    </>
  )
}

export default ConnectedScreen
