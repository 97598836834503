import {
  Button,
  Center,
  Image,
  SimpleGrid,
  Text,
  useColorMode
} from '@chakra-ui/react'
import { LiquidityDistribution } from '@traderjoe-team/spruce-sdk-v2'
import BidAskImage1xDark from 'assets/distribution_bid_ask_1x.webp'
import BidAskImage1xLight from 'assets/distribution_bid_ask_1x_light.webp'
import BidAskImage2xDark from 'assets/distribution_bid_ask_2x.webp'
import BidAskImage2xLight from 'assets/distribution_bid_ask_2x_light.webp'
import BidAskImage3xDark from 'assets/distribution_bid_ask_3x.webp'
import BidAskImage3xLight from 'assets/distribution_bid_ask_3x_light.webp'
import CurveImage1xDark from 'assets/distribution_curve_1x.webp'
import CurveImage1xLight from 'assets/distribution_curve_1x_light.webp'
import CurveImage2xDark from 'assets/distribution_curve_2x.webp'
import CurveImage2xLight from 'assets/distribution_curve_2x_light.webp'
import CurveImage3xDark from 'assets/distribution_curve_3x.webp'
import CurveImage3xLight from 'assets/distribution_curve_3x_light.webp'
import SpotImage1xDark from 'assets/distribution_spot_1x.webp'
import SpotImage1xLight from 'assets/distribution_spot_1x_light.webp'
import SpotImage2xDark from 'assets/distribution_spot_2x.webp'
import SpotImage2xLight from 'assets/distribution_spot_2x_light.webp'
import SpotImage3xDark from 'assets/distribution_spot_3x.webp'
import SpotImage3xLight from 'assets/distribution_spot_3x_light.webp'
import React from 'react'

const getDistributionImage = (
  distribution: LiquidityDistribution,
  isDarkMode: boolean
): JSX.Element => {
  switch (distribution) {
    case LiquidityDistribution.BID_ASK:
      return isDarkMode ? (
        <Image
          fallbackSrc={BidAskImage2xLight}
          srcSet={`${BidAskImage1xLight} 1x, ${BidAskImage2xLight} 2x, ${BidAskImage3xLight} 3x`}
        />
      ) : (
        <Image
          fallbackSrc={BidAskImage2xDark}
          srcSet={`${BidAskImage1xDark} 1x, ${BidAskImage2xDark} 2x, ${BidAskImage3xDark} 3x`}
        />
      )
    case LiquidityDistribution.CURVE:
      return isDarkMode ? (
        <Image
          fallbackSrc={CurveImage2xLight}
          srcSet={`${CurveImage1xLight} 1x, ${CurveImage2xLight} 2x, ${CurveImage3xLight} 3x`}
        />
      ) : (
        <Image
          fallbackSrc={CurveImage2xDark}
          srcSet={`${CurveImage1xDark} 1x, ${CurveImage2xDark} 2x, ${CurveImage3xDark} 3x`}
        />
      )
    case LiquidityDistribution.SPOT:
      return isDarkMode ? (
        <Image
          fallbackSrc={SpotImage2xLight}
          srcSet={`${SpotImage1xLight} 1x, ${SpotImage2xLight} 2x, ${SpotImage3xLight} 3x`}
        />
      ) : (
        <Image
          fallbackSrc={SpotImage2xDark}
          srcSet={`${SpotImage1xDark} 1x, ${SpotImage2xDark} 2x, ${SpotImage3xDark} 3x`}
        />
      )
  }
}

const getDistributtionTitle = (distribution: LiquidityDistribution): string => {
  switch (distribution) {
    case LiquidityDistribution.BID_ASK:
      return 'Bid-Ask'
    case LiquidityDistribution.CURVE:
      return 'Curve'
    case LiquidityDistribution.SPOT:
      return 'Spot'
  }
}

interface DistributionOptionsProps {
  distribution: LiquidityDistribution
  onDistributionChange: (distribution: LiquidityDistribution) => void
}

const DistributionOptions = ({
  distribution,
  onDistributionChange
}: DistributionOptionsProps) => {
  const { colorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'
  const options = [
    LiquidityDistribution.SPOT,
    LiquidityDistribution.CURVE,
    LiquidityDistribution.BID_ASK
  ]

  return (
    <SimpleGrid columns={3} gap={2} w="full">
      {options.map((option, i) => (
        <Button
          data-cy={`distribution-shape-${i}-button`}
          key={i}
          variant="unstyled"
          border="1px"
          borderColor="border"
          h="fit-content"
          py={3}
          _hover={{ _dark: { bg: 'joeDark.500' }, bg: 'silver' }}
          isActive={distribution === option}
          _active={{ bg: 'silver', borderColor: 'border' }}
          _dark={{ _active: { bg: 'rgb(50, 53, 102)' } }}
          onClick={() => onDistributionChange(option)}
        >
          <Center flexDir="column">
            {getDistributionImage(option, isDarkMode)}
            <Text fontSize="xs" fontWeight="semibold">
              {getDistributtionTitle(option)}
            </Text>
          </Center>
        </Button>
      ))}
    </SimpleGrid>
  )
}

export default DistributionOptions
