import { LBPairABI } from '@traderjoe-team/spruce-sdk-v2'
import useChainId from 'hooks/useChainId'
import { getAddress } from 'viem'
import { useContractReads } from 'wagmi'

interface UseLBTokensSupplyProps {
  binIds?: number[]
  lbPairAddress?: string
}

const useLBTokensSupply = ({
  binIds,
  lbPairAddress
}: UseLBTokensSupplyProps) => {
  const chainId = useChainId()
  const contract = {
    abi: LBPairABI,
    address: lbPairAddress ? getAddress(lbPairAddress) : undefined
  }
  const reads = useContractReads({
    contracts: binIds?.map((binId) => ({
      ...contract,
      args: [BigInt(binId)],
      chainId,
      functionName: 'totalSupply'
    })),
    enabled: !!binIds && binIds.length > 0
  })
  return {
    ...reads,
    data: reads.data
      ? (reads.data.map((read) => read.result) as unknown[] as bigint[])
      : undefined
  }
}

export default useLBTokensSupply
