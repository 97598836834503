import { Box, HStack, Text, useTheme, useToken, VStack } from '@chakra-ui/react'
import { Currency } from '@traderjoe-team/spruce-sdk'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis
} from 'recharts'
import {
  NameType,
  ValueType
} from 'recharts/types/component/DefaultTooltipContent'
import { UserFeesBinData } from 'types/dexbarn'
import { formattedNum } from 'utils/format'

interface UserEarnedFeesPerBinChartProps {
  isPriceRatioInversed: boolean
  userFeesDataBins: UserFeesBinData[]
  currency0?: Currency
  currency1?: Currency
}

const UserEarnedFeesPerBinChart = ({
  currency0,
  currency1,
  isPriceRatioInversed,
  userFeesDataBins
}: UserEarnedFeesPerBinChartProps) => {
  const [textSecondary] = useToken('colors', ['textSecondary'])

  const theme = useTheme()
  const barColor = theme.semanticTokens.colors.graphPurpleLight
  const barFocusColor = theme.semanticTokens.colors.graphPurple

  const [focusBar, setFocusBar] = useState<number | undefined>()

  const data = userFeesDataBins

  const xAxisTicks = data
    .filter((_bin, index) => {
      // show all ticks when size < 5
      if (data.length < 5) {
        return true
      }
      // else show only 3
      return (
        index === 0 ||
        index === data.length - 1 ||
        index === Math.floor(data.length / 2)
      )
    })
    .map((bin) => (isPriceRatioInversed ? bin.priceYX : bin.priceXY))

  return (
    <Box h="160px">
      <ResponsiveContainer width="99%">
        <BarChart
          data={data}
          onMouseMove={(state) => {
            setFocusBar(
              state.isTooltipActive ? state.activeTooltipIndex : undefined
            )
          }}
          onMouseLeave={() => {
            setFocusBar(undefined)
          }}
        >
          <Tooltip
            wrapperStyle={{ outline: 'none' }}
            cursor={{ fill: 'transparent' }}
            content={
              <UserFeesBinsChartTooltip
                symbolX={currency0?.symbol ?? ''}
                symbolY={currency1?.symbol ?? ''}
              />
            }
          />
          <XAxis
            xAxisId="0"
            tickSize={0}
            axisLine={false}
            ticks={xAxisTicks}
            dataKey={isPriceRatioInversed ? 'priceYX' : 'priceXY'}
            tickFormatter={(val) => formattedNum(val)}
            fontSize="12px"
            fontWeight="semibold"
            tick={{ fill: textSecondary }}
            interval="preserveStartEnd"
            tickMargin={12}
          />
          <Bar dataKey="accruedFeesL" radius={2}>
            {data.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={focusBar === index ? barFocusColor : barColor}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )
}

export default UserEarnedFeesPerBinChart

type UserFeesBinsChartTooltipProps = TooltipProps<ValueType, NameType> & {
  symbolX: string
  symbolY: string
}

const UserFeesBinsChartTooltip = ({
  active,
  payload,
  symbolX,
  symbolY
}: UserFeesBinsChartTooltipProps) => {
  const fees = payload?.[0]?.payload
  const { accruedFeesX, accruedFeesY, priceXY, priceYX, timestamp } = fees || {}

  const time = dayjs(timestamp * 1000)
  const fromTime = time.format('MMM D, YYYY h:mm A')
  return active && payload && payload.length ? (
    <Box
      p={4}
      borderRadius="xl"
      backdropFilter="blur(8px)"
      bg="rgba(245, 245, 255, 0.48)"
      boxShadow="0px 4px 40px rgba(0, 0, 0, 0.16)"
      _dark={{
        bg: 'rgba(53, 58, 108, 0.48)',
        boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.4)'
      }}
    >
      <VStack spacing={0} align="flex-start" mb={4}>
        <Text fontSize="sm" color="textSecondary">
          Since last deposit on
        </Text>
        <Text color="textSecondary" fontSize="sm">
          {fromTime}
        </Text>
      </VStack>
      <HStack mb={4}>
        <VStack spacing={0} align="flex-start" mr={4}>
          <Text fontSize="sm" color="textSecondary">
            Earned {symbolX}
          </Text>
          <Text fontWeight="semibold">{formattedNum(accruedFeesX)}</Text>
        </VStack>
        <VStack spacing={0} align="flex-start">
          <Text fontSize="sm" color="textSecondary">
            Earned {symbolY}
          </Text>
          <Text fontWeight="semibold">{formattedNum(accruedFeesY)}</Text>
        </VStack>
      </HStack>
      <VStack spacing={0} align="flex-start">
        <Text fontSize="sm" color="textSecondary">
          Bin Price
        </Text>
        <Text fontSize="sm" color="textPrimary">
          1 {symbolX} = {formattedNum(priceXY)} {symbolY}
        </Text>
        <Text fontSize="sm" color="textPrimary">
          1 {symbolY} = {formattedNum(priceYX)} {symbolX}
        </Text>
      </VStack>
    </Box>
  ) : null
}
