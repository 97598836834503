import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import {
  jsonAbis,
  LB_ROUTER_V21_ADDRESS,
  TradeV2
} from '@traderjoe-team/spruce-sdk-v2'
import useActiveChain from 'hooks/useActiveChain'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import { useEffect, useMemo } from 'react'
import { getConfigWithGasLimitIncreased } from 'utils/gas'
import { capturePrepareContractWriteError } from 'utils/logger'
import { getAddress } from 'viem'
import {
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi'

import useSwapCallArguments from './useSwapCallArguments'

interface UseSwapCallArgumentsProps {
  allowedSlippage: number
  enabled: boolean
  onSwapSuccess: () => void
  trade?: TradeV2
}

export const useSwap = ({
  allowedSlippage,
  enabled,
  onSwapSuccess,
  trade
}: UseSwapCallArgumentsProps) => {
  const { LBRouterV21ABI } = jsonAbis
  const chainId = useChainId()
  const walletChainId = useNetwork().chain?.id
  const { nativeCurrency } = useActiveChain()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()
  const { defaultParameters, feeOnTransferParameters } = useSwapCallArguments({
    allowedSlippage,
    trade
  })

  const { config: defaultConfig, isError: isDefaultConfigError } =
    usePrepareContractWrite({
      abi: LBRouterV21ABI,
      address: getAddress(LB_ROUTER_V21_ADDRESS[chainId]),
      args: defaultParameters?.args,
      cacheTime: 0,
      enabled: !!defaultParameters && enabled && walletChainId === chainId,
      functionName: defaultParameters?.methodName,
      onSettled: (_, error) => {
        capturePrepareContractWriteError(error)
      },
      value: defaultParameters ? BigInt(defaultParameters.value) : BigInt(0)
    })

  const { config: feeOnTransferConfig } = usePrepareContractWrite({
    abi: LBRouterV21ABI,
    address: getAddress(LB_ROUTER_V21_ADDRESS[chainId]),
    args: feeOnTransferParameters?.args,
    cacheTime: 0,
    enabled: !!feeOnTransferParameters && enabled && walletChainId === chainId,
    functionName: feeOnTransferParameters?.methodName,
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    },
    value: feeOnTransferParameters
      ? BigInt(feeOnTransferParameters.value)
      : BigInt(0)
  })

  const transactionSummary = useMemo(() => {
    if (!trade) return ''
    const inputSymbol = trade.isNativeIn
      ? nativeCurrency?.symbol
      : trade.inputAmount.currency.symbol
    const outputSymbol = trade.isNativeOut
      ? nativeCurrency?.symbol
      : trade.outputAmount.currency.symbol
    const inputAmount = trade.inputAmount.toSignificant(3)
    const outputAmount = trade.outputAmount.toSignificant(3)
    return `Swap ${inputAmount} ${inputSymbol} for ${outputAmount} ${outputSymbol}`
  }, [trade, nativeCurrency])

  const {
    data,
    isLoading,
    reset,
    write: swap
  } = useContractWrite({
    ...getConfigWithGasLimitIncreased({
      config: isDefaultConfigError ? feeOnTransferConfig : defaultConfig,
      percentageIncrease: 10
    }),
    onSuccess: (data) => {
      addRecentTransaction({ description: transactionSummary, hash: data.hash })
      addTransactionToast({ description: transactionSummary, hash: data.hash })
    }
  })

  const { isLoading: isWaitingForTransaction } = useWaitForTransaction({
    hash: data?.hash,
    onSuccess: onSwapSuccess
  })

  useEffect(() => {
    reset()
  }, [chainId, reset])

  return { isSwapping: isLoading || isWaitingForTransaction, swap }
}
