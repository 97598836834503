import { Currency } from '@traderjoe-team/spruce-sdk'

export interface LeaderboardData {
  accruedFeesUsd: string
  accruedFeesX: string
  accruedFeesY: string
  currentBinCount: number
  currentPositionTokenX: number
  currentPositionTokenY: number
  shareVolumeTradedTokenX: string
  shareVolumeTradedTokenY: string
  shareVolumeTradedUsd: string
  userAddress: string
  volumeTradedTokenX: string
  volumeTradedTokenY: string
  volumeTradedUsd: string
}

export interface LeaderboardRowProps extends LeaderboardData {
  currency0?: Currency
  currency1?: Currency
  position?: number
}

export enum LeaderboardFilterType {
  DAY = '1d',
  MONTH = '30d',
  TWO_WEEK = '14d',
  WEEK = '7d'
}
