import { VaultABI } from '@traderjoe-team/spruce-sdk-v2'
import { Vault } from 'types/vault'
import { getAddress } from 'viem'
import { erc20ABI, useContractRead, useContractReads } from 'wagmi'

interface UseVaultBalancesProps {
  vault: Vault
}

const useVaultBalances = ({ vault }: UseVaultBalancesProps) => {
  const vaultContract = {
    abi: VaultABI,
    address: getAddress(vault.id)
  } as const

  const { data, isLoading } = useContractReads({
    contracts: [
      {
        ...vaultContract,
        chainId: vault.chainId,
        functionName: 'getBalances'
      },
      {
        ...vaultContract,
        chainId: vault.chainId,
        functionName: 'getCurrentTotalQueuedWithdrawal'
      },
      {
        abi: erc20ABI,
        address: vault.tokenX.address
          ? getAddress(vault.tokenX.address)
          : undefined,
        args: [getAddress(vault.id)],
        chainId: vault.chainId,
        functionName: 'balanceOf'
      },
      {
        abi: erc20ABI,
        address: vault.tokenY.address
          ? getAddress(vault.tokenY.address)
          : undefined,
        args: [getAddress(vault.id)],
        chainId: vault.chainId,
        functionName: 'balanceOf'
      }
    ] as const
  })

  const balance = data?.[0].result as [bigint, bigint] | undefined
  const currentTotalQueuedWithdrawalShares = data?.[1].result as
    | bigint
    | undefined
  const balanceTokenX = data?.[2].result as bigint | undefined
  const balanceTokenY = data?.[3].result as bigint | undefined

  const {
    data: currentTotalQueuedWithdrawalAmounts,
    isLoading: isLoadingQueuedSharesAmounts
  } = useContractRead({
    abi: VaultABI,
    address: getAddress(vault.id),
    args: currentTotalQueuedWithdrawalShares
      ? [currentTotalQueuedWithdrawalShares]
      : undefined,
    chainId: vault.chainId,
    enabled: !!currentTotalQueuedWithdrawalShares,
    functionName: 'previewAmounts'
  })

  return {
    isLoading: isLoading || isLoadingQueuedSharesAmounts,
    tokenBalancesX: {
      inPool: balance?.[0] || BigInt(0),
      inVault: balanceTokenX || BigInt(0),
      queued: currentTotalQueuedWithdrawalAmounts?.[0] || BigInt(0)
    },
    tokenBalancesY: {
      inPool: balance?.[1] || BigInt(0),
      inVault: balanceTokenY || BigInt(0),
      queued: currentTotalQueuedWithdrawalAmounts?.[1] || BigInt(0)
    }
  }
}

export default useVaultBalances
