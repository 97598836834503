import { ChainId } from '@traderjoe-team/spruce-sdk'

export const getMaxBinPerAddLiquidityBatch = (chainId: ChainId) => {
  switch (chainId) {
    case ChainId.FUJI:
    case ChainId.SPRUCE:
      return 100
  }
}

export const getMaxBinPerRemoveLiquidityBatch = (chainId: ChainId) => {
  switch (chainId) {
    case ChainId.FUJI:
    case ChainId.SPRUCE:
      return 200
  }
}
