import { Token } from '@traderjoe-team/spruce-sdk'
import { useCallback } from 'react'
import { useAccount } from 'wagmi'

const useAddTokenToWallet = () => {
  const { connector } = useAccount()
  return useCallback(
    (token: Token) => {
      connector?.watchAsset?.({
        address: token.address,
        decimals: token.decimals,
        image: `https://raw.githubusercontent.com/traderjoe-xyz/lb-pro-tokenlist/main/logos/${token.address}/logo.png`,
        symbol: token.symbol ?? ''
      })
    },
    [connector]
  )
}

export default useAddTokenToWallet
