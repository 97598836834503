import { tableAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
  td: {
    fontSize: 'normal'
  },
  th: {
    color: 'red',
    fontWeight: 'normal'
  }
})

const thStyle = {
  _dark: {
    color: 'joeDark.300'
  },
  borderBottom: '0',
  color: 'textPrimary',
  fontWeight: 'semibold'
}

const variantSimple = definePartsStyle(() => ({
  td: {
    borderBottom: '0'
  },
  th: thStyle
}))

const variantCard = definePartsStyle(() => ({
  td: {
    _groupHover: {
      _dark: {
        bg: 'joeDark.500'
      },
      bg: 'silver'
    }
  },
  th: thStyle
}))

export const tableTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { card: variantCard, simple: variantSimple }
})
