import { Box, Center, Flex, Heading, Text, useToken } from '@chakra-ui/react'
import { Currency } from '@traderjoe-team/spruce-sdk'
import useKeyPress from 'hooks/useKeyPress'
import React, { useMemo, useState } from 'react'
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { BinVolumeData } from 'types/poolV2'
import { formattedNum } from 'utils/format'
import { inverseBinsData } from 'utils/poolV2'

import BinsTradedChartTooltip from './BinsTradedChartTooltip'

interface BinsTradedChartProps {
  activeBinId: number
  binsTraded24: BinVolumeData[]
  currency0: Currency
  currency1: Currency
  isPriceRatioInversed?: boolean
}

const BinsTradedChart = ({
  activeBinId,
  binsTraded24,
  currency0,
  currency1,
  isPriceRatioInversed = false
}: BinsTradedChartProps) => {
  const [graphPurpleLight, graphPurple, textSecondary] = useToken('colors', [
    'graphPurpleLight',
    'graphPurple',
    'textSecondary'
  ])

  const [priceDecimals, setPriceDecimals] = useState(5)
  useKeyPress({
    onUp: () => setPriceDecimals((prev) => (prev === 8 ? 5 : 8)),
    targetKey: 'g'
  })

  const data = useMemo(
    () => (isPriceRatioInversed ? inverseBinsData(binsTraded24) : binsTraded24),
    [binsTraded24, isPriceRatioInversed]
  )

  const [focusBar, setFocusBar] = useState<number | undefined>()
  const dataKey = 'volumeUsd'

  const yAxisDomain = useMemo(() => {
    const yAxisMaxValue = binsTraded24
      .map((data) => data[dataKey])
      .reduce((a, b) => Math.max(a, b), -Infinity)
    return [0, yAxisMaxValue]
  }, [binsTraded24])

  return (
    <Box pos="relative">
      <Flex justify="space-between" align="center" mb={{ base: 0, sm: 8 }}>
        <Heading size="md" mb={2}>
          BINS TRADED (24H)
        </Heading>
      </Flex>
      <Box h="160px">
        <ResponsiveContainer width="99%">
          <BarChart
            data={data}
            onMouseMove={(state) => {
              setFocusBar(
                state.isTooltipActive ? state.activeTooltipIndex : undefined
              )
            }}
            onMouseLeave={() => {
              setFocusBar(undefined)
            }}
            margin={{ bottom: 16 }}
          >
            <Tooltip
              wrapperStyle={{ outline: 'none' }}
              cursor={{ fill: 'transparent' }}
              content={
                <BinsTradedChartTooltip
                  dataKey={dataKey}
                  symbolX={currency0.symbol ?? ''}
                  symbolY={currency1.symbol ?? ''}
                />
              }
            />
            <YAxis
              width={80}
              axisLine={false}
              tickLine={false}
              tickSize={0}
              tickMargin={20}
              domain={yAxisDomain}
              fontSize="12px"
              tick={{ fill: textSecondary }}
              tickFormatter={(val) =>
                formattedNum(val, {
                  allowSmallDecimals: true,
                  places: priceDecimals,
                  usd: true
                })
              }
            />
            <XAxis
              xAxisId="0"
              tickSize={0}
              minTickGap={16}
              axisLine={false}
              dataKey="priceXY"
              tickFormatter={(val) =>
                formattedNum(val, {
                  allowDecimalsOver1000: true,
                  allowSmallDecimals: true,
                  places: priceDecimals
                })
              }
              fontSize="12px"
              fontWeight="semibold"
              tick={{ fill: textSecondary }}
              tickMargin={12}
            />
            <defs>
              <linearGradient id="activeId" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={graphPurple} fillOpacity={1} />
                <stop offset="100%" stopColor={graphPurple} fillOpacity={1} />
              </linearGradient>
              <linearGradient id="nonActiveId" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor={graphPurple} stopOpacity={1} />
                <stop offset="100%" stopColor={graphPurple} fillOpacity={1} />
              </linearGradient>
              <linearGradient
                opacity={0.5}
                id="activeIdFocus"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="0%"
                  stopColor={graphPurpleLight}
                  fillOpacity={1}
                />
                <stop
                  offset="100%"
                  stopColor={graphPurpleLight}
                  fillOpacity={1}
                />
              </linearGradient>
              <linearGradient
                opacity={0.5}
                id="nonActiveIdFocus"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="0%"
                  stopColor={graphPurpleLight}
                  fillOpacity={1}
                />
                <stop
                  offset="100%"
                  stopColor={graphPurpleLight}
                  fillOpacity={1}
                />
              </linearGradient>
            </defs>
            <Bar dataKey={dataKey} radius={2}>
              {binsTraded24.map((data, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    focusBar === index && data.binId === activeBinId
                      ? 'url(#activeIdFocus)'
                      : focusBar === index && data.binId !== activeBinId
                      ? 'url(#nonActiveIdFocus)'
                      : data.binId === activeBinId
                      ? 'url(#activeId)'
                      : 'url(#nonActiveId)'
                  }
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <Center mt={-6}>
          <Text fontSize="sm" fontWeight="semibold">
            Bin Price
          </Text>
        </Center>
      </Box>
    </Box>
  )
}

export default BinsTradedChart
