import {
  Divider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@chakra-ui/react'
import { Currency } from '@traderjoe-team/spruce-sdk'
import { FetchBalanceResult } from '@wagmi/core'
import useTokenPriceUSD from 'hooks/useTokenPriceUSD'
import React from 'react'
import { LBPoolVersion } from 'types/pool'
import { LBPairUserBalances } from 'types/poolV2'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { useChainId } from 'wagmi'

import AddLiquidityPanelV2 from './AddLiquidityPanelV2'
import RemoveLiquidityPanelV2 from './RemoveLiquidityPanelV2'

interface AddRemoveLiquidityPanelV2Props {
  currency0: Currency
  currency1: Currency
  isPoolMigrated: boolean
  lbPairAddress: string
  lbPoolVersion: LBPoolVersion
  onAddLiquiditySuccess: () => void
  onRemoveLiquidityConfigError: () => void
  onRemoveLiquiditySuccess: () => void
  activeBinId?: number
  balance0?: FetchBalanceResult
  balance1?: FetchBalanceResult
  binStep?: string
  inversePriceRatios?: boolean
  togglePriceRatiosClicked?: () => void
  userBalances?: LBPairUserBalances
}

const AddRemoveLiquidityPanelV2 = (props: AddRemoveLiquidityPanelV2Props) => {
  const chainId = useChainId()
  const wrappedCurrency0 = wrappedCurrency(props.currency0, chainId)
  const wrappedCurrency1 = wrappedCurrency(props.currency1, chainId)

  const { isPoolMigrated: isAddLiquidityDisabled } = props

  const { data: usdPrices } = useTokenPriceUSD({
    tokens:
      wrappedCurrency0 && wrappedCurrency1
        ? [wrappedCurrency0.address, wrappedCurrency1.address]
        : []
  })
  const [currencyPrice0, currencyPrice1] = usdPrices ?? [undefined, undefined]

  return (
    <Tabs variant="lb-pro-line" defaultIndex={isAddLiquidityDisabled ? 1 : 0}>
      <TabList pt={2} pl={{ base: 0, md: 8 }}>
        <Tab data-cy="add-liquidity-tab" isDisabled={isAddLiquidityDisabled}>
          ADD LIQUIDITY
        </Tab>
        <Tab data-cy="remove-liquidity-tab">REMOVE LIQUIDITY</Tab>
      </TabList>
      <Divider />
      <TabPanels>
        <TabPanel
          data-cy="add-liquidity-panel"
          pt={{ base: 0, md: 8 }}
          pl={{ base: 0, md: 8 }}
        >
          {!isAddLiquidityDisabled ? (
            <AddLiquidityPanelV2
              {...props}
              currencyPrice0={currencyPrice0}
              currencyPrice1={currencyPrice1}
            />
          ) : null}
        </TabPanel>
        <TabPanel
          data-cy="remove-liquidity-panel"
          pt={{ base: 0, md: 8 }}
          pl={{ base: 0, md: 4 }}
        >
          <RemoveLiquidityPanelV2
            {...props}
            currencyPrice0={currencyPrice0}
            currencyPrice1={currencyPrice1}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default AddRemoveLiquidityPanelV2
