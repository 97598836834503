import {
  Box,
  Center,
  Flex,
  Grid,
  Tab,
  TabList,
  Tabs,
  Text
} from '@chakra-ui/react'
import { CNATIVE, Currency, Token } from '@traderjoe-team/spruce-sdk'
import { LB_ROUTER_V21_ADDRESS, TradeV2 } from '@traderjoe-team/spruce-sdk-v2'
import AddToWalletButton from 'components/AddToWalletButton'
import ApproveTokenButton from 'components/ApproveTokenButton'
import MaxButton from 'components/MaxButton'
import PageHelmet from 'components/PageHelmet'
import SlippageSettingsPicker from 'components/SlippageSettingsPicker'
import TradingViewChart from 'components/TradingViewChart'
import Web3Button from 'components/Web3Button'
import { TRADE_HELMET_DESCRIPTION, TRADE_HELMET_TITLE } from 'constants/swap'
import { FEATURE_FLAGS } from 'featureFlags'
import { useSwap } from 'hooks/swap/useSwap'
import useTrades from 'hooks/swap/useTrades'
import useWrapUnwrapNativeCurrency from 'hooks/swap/useWrapUnwrapNativeCurrency'
import useApproveSpenderIfNeeded from 'hooks/useApproveSpenderIfNeeded'
import useChainId from 'hooks/useChainId'
import useSdkCurrencies from 'hooks/useSdkCurrencies'
import { useTokenBalance } from 'hooks/useTokenBalance'
import debounce from 'lodash.debounce'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useCallback } from 'react'
import { useMemo } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useIsSafeModeEnabled, useSlippageSettings } from 'state/settings/hooks'
import { convertStringToBN } from 'utils/format'
import { tradeSwap } from 'utils/measure'
import { tryParseAmount } from 'utils/swap'
import {
  getCurrencyAddress,
  wrappedCurrencyAmount
} from 'utils/wrappedCurrency'
import { useAccount } from 'wagmi'

import PlaceOrder from './LimitOrder/PlaceOrder'
import TradeCurrencyInputs from './TradeCurrencyInputs'
import TradeDetails from './TradeDetails'

const Trade = () => {
  const { isConnected } = useAccount()
  const location = useLocation()
  const chainId = useChainId()

  // swap <-> order toggle
  const [isSwap, setIsSwap] = useState<boolean>(true)

  // Decode search params
  const [searchParams] = useSearchParams()
  const inputCurrencyAddr = searchParams.get('inputCurrency')
  const outputCurrencyAddr = searchParams.get('outputCurrency')
  const paramAddresses = useMemo(
    () => [inputCurrencyAddr, outputCurrencyAddr],
    [inputCurrencyAddr, outputCurrencyAddr]
  )
  const {
    tokens: [inputCurrencyParam, outputCurrencyParam]
  } = useSdkCurrencies({
    addresses: paramAddresses
  })

  // Input/output currencies
  const [isExactIn, setIsExactIn] = useState(true)
  const [selectedInputCurrency, setInputCurrency] = useState<
    Currency | undefined
  >(CNATIVE.onChain(chainId))
  const [selectedOutputCurrency, setOutputCurrency] = useState<
    Currency | undefined
  >()

  const handleSetCurrency = (currency: Currency, isInput: boolean) => {
    if (isInput) {
      setInputCurrency(currency)
    } else {
      setOutputCurrency(currency)
    }
  }

  const inputCurrency = useMemo(
    () => selectedInputCurrency ?? inputCurrencyParam,
    [selectedInputCurrency, inputCurrencyParam]
  )
  const outputCurrency = useMemo(
    () => selectedOutputCurrency ?? outputCurrencyParam,
    [selectedOutputCurrency, outputCurrencyParam]
  )

  // Addresses
  const inputCurrencyAddress = getCurrencyAddress(inputCurrency)
  const outputCurrencyAddress = getCurrencyAddress(outputCurrency)

  // User balances
  const inputBalance = useTokenBalance({
    token: inputCurrencyAddress
  })
  const debouncedRefetchInputBalance = debounce(
    () => inputBalance.refetch(),
    2000
  )
  const outputBalance = useTokenBalance({
    token:
      outputCurrency instanceof Token
        ? (outputCurrency as Token).address
        : undefined
  })
  const debouncedRefetchOutputBalance = debounce(
    () => outputBalance.refetch(),
    2000
  )

  // User input
  const [typedValue, setTypedValue] = useState('')
  const userTypedCurrency = isExactIn ? inputCurrency : outputCurrency
  const typedTokenAmount = useMemo(
    () =>
      wrappedCurrencyAmount(
        tryParseAmount(typedValue, userTypedCurrency),
        chainId
      ),
    [typedValue, userTypedCurrency, chainId]
  )

  // Fetch trades
  const { isFetchingTrades, trades } = useTrades({
    inputCurrency,
    isExactIn,
    outputCurrency,
    typedTokenAmount
  })
  const bestTrade = TradeV2.chooseBestTrade(trades, isExactIn)
  const isLiquidityInsufficient =
    !isFetchingTrades && !bestTrade && !!typedValue

  // Slippage
  const {
    slippageSettings: { swapV2: lowSlippage, v1: normalSlippage }
  } = useSlippageSettings()
  const allowedNormalSlippage = Math.trunc(normalSlippage * 100)
  const allowedLowSlippage = Math.trunc(lowSlippage * 100)
  const allowedSlippage = useMemo(() => {
    if (bestTrade) {
      // use low slippage if only v2 pools are used in the trade
      const isAllV2Pools = bestTrade.quote.binSteps.every(
        (binStepBN) => Number(binStepBN) > 0
      )
      if (isAllV2Pools) {
        return allowedLowSlippage
      }
    }
    return allowedNormalSlippage
  }, [bestTrade, allowedNormalSlippage, allowedLowSlippage])

  // Price impact
  const { isSafeModeEnabled } = useIsSafeModeEnabled()
  const priceImpact = bestTrade
    ? Number(bestTrade.priceImpact.toFixed(2))
    : undefined
  const isPriceImpactHigh = priceImpact ? priceImpact >= 5 : false

  // Input error
  const hasEnoughInputCurrency =
    inputBalance.data && bestTrade
      ? Number(inputBalance.data.formatted) >=
        Number(bestTrade.inputAmount.toSignificant(6))
      : true

  // Approval
  const {
    approvalType,
    approve,
    isApproved,
    isApproving,
    reset: resetApproval,
    setApprovalType
  } = useApproveSpenderIfNeeded({
    amount: convertStringToBN(
      bestTrade?.inputAmount.toFixed(),
      bestTrade?.inputAmount.token.decimals
    ),
    spender: LB_ROUTER_V21_ADDRESS[chainId],
    token: inputCurrencyAddress,
    tokenSymbol: inputCurrency?.symbol
  })

  // Refresh state on swap success
  const onSwapSuccess = useCallback(() => {
    resetApproval()
    setTypedValue('')
    debouncedRefetchInputBalance()
    debouncedRefetchOutputBalance()
  }, [
    debouncedRefetchInputBalance,
    debouncedRefetchOutputBalance,
    resetApproval
  ])

  // Swap
  const isSwapEnabled =
    hasEnoughInputCurrency &&
    !isFetchingTrades &&
    (isApproved || (inputCurrency?.isNative ?? false))
  const { isSwapping, swap } = useSwap({
    allowedSlippage,
    enabled: isSwapEnabled,
    onSwapSuccess,
    trade: bestTrade
  })

  // Wrap / Unwrap
  const {
    isLoading: isLoadingWrapUnwrap,
    state: wrapUnwrapState,
    write: wrapUnwrap
  } = useWrapUnwrapNativeCurrency({
    amount: typedTokenAmount?.toExact(),
    currency0: inputCurrency,
    currency1: outputCurrency,
    onSuccess: onSwapSuccess
  })

  // Update inputs and currencies when chain changes
  useEffect(() => {
    setInputCurrency(!!inputCurrencyAddr ? undefined : CNATIVE.onChain(chainId))
    setOutputCurrency(undefined)
    setTypedValue('')
  }, [chainId, inputCurrencyAddr])

  // Action handlers
  const onChangeSwapDirectionClick = useCallback(() => {
    const newInputCurrency = outputCurrency
    const newOutputCurrency = inputCurrency
    setTypedValue(bestTrade?.outputAmount.toFixed() ?? '')
    setInputCurrency(newInputCurrency)
    setOutputCurrency(newOutputCurrency)
  }, [inputCurrency, outputCurrency, bestTrade])

  return (
    <Flex mt={{ base: 6, md: 20 }} mb="200px" justify="center" minH="650px">
      <PageHelmet
        title={TRADE_HELMET_TITLE}
        description={TRADE_HELMET_DESCRIPTION}
        url={location.pathname}
      />
      <Grid
        w="full"
        maxW={{ '2xl': '1600px', base: '1400px' }}
        templateColumns={{
          base: 'minmax(0, 1fr)',
          lg: 'minmax(0, 7fr) minmax(0, 5fr)'
        }}
      >
        <Flex
          width="full"
          flexDir="column"
          align="flex-end"
          borderRight="1px solid"
          borderColor="border"
        >
          <TradingViewChart />
          <Flex pt={2} px={2}>
            <Text fontSize="xs" textColor="textSecondary">
              The charting solution is provided by TradingView, a social
              platform for traders and investors with advanced analytical tools
              and high-performance market data to help track coins like BTC USD
              price on charts and more.
            </Text>
          </Flex>
        </Flex>
        <Flex width="full" flexDir="column" px={{ base: 4, xl: 0 }}>
          <Flex
            flexDir="row"
            justifyContent={
              FEATURE_FLAGS.placeOrders ? 'space-between' : 'flex-end'
            }
            align="center"
          >
            {FEATURE_FLAGS.placeOrders ? (
              <Tabs
                display="flex"
                justifyContent="space-between"
                variant="lb-pro-line"
                colorScheme="accent"
                index={isSwap ? 0 : 1}
                onChange={(index) => setIsSwap(index === 0)}
                borderBottom="1px solid"
                borderColor="border"
                w={{ base: 'full', md: 'calc(100% - 2rem)' }}
              >
                <TabList>
                  <Tab data-cy="swap-tab">Swap Now</Tab>
                  <Tab data-cy="place-order-tab">Place Order</Tab>
                </TabList>
                <Box>
                  <SlippageSettingsPicker
                    type="swap"
                    iconButtonProps={{
                      'aria-label': 'open settings',
                      bg: 'transparent',
                      border: 0
                    }}
                  />
                </Box>
              </Tabs>
            ) : null}
          </Flex>
          {isSwap ? (
            <TradeCurrencyInputs
              inputCurrencyProps={{
                balance: inputBalance.data?.formatted,
                currency: inputCurrency,
                currencyAddress: inputCurrencyAddress,
                heading: 'Swap',
                isDisabled: !isExactIn && isFetchingTrades,
                onCurrencyChange: (currency) => {
                  currency.symbol === outputCurrency?.symbol
                    ? onChangeSwapDirectionClick()
                    : setInputCurrency(currency)
                },
                onValueChange: (value) => {
                  setIsExactIn(true)
                  setTypedValue(value)
                },
                rightElement: inputCurrency ? (
                  <MaxButton
                    data-cy="trade-currency-input-max-button"
                    mr={2}
                    balance={inputBalance.data?.formatted}
                    onClick={() => {
                      setIsExactIn(true)
                      setTypedValue(inputBalance.data?.formatted ?? '')
                    }}
                  />
                ) : undefined,
                value: wrapUnwrapState
                  ? typedValue
                  : isExactIn
                  ? typedValue
                  : bestTrade?.inputAmount.toFixed() ?? ''
              }}
              outputCurrencyProps={{
                balance: outputBalance.data?.formatted,
                currency: outputCurrency,
                currencyAddress: outputCurrencyAddress,
                heading: 'To',
                isDisabled: isExactIn && isFetchingTrades,
                onCurrencyChange: (currency) => {
                  currency.symbol === inputCurrency?.symbol
                    ? onChangeSwapDirectionClick()
                    : setOutputCurrency(currency)
                },
                onValueChange: (value) => {
                  setIsExactIn(false)
                  setTypedValue(value)
                },
                value: wrapUnwrapState
                  ? typedValue
                  : isExactIn
                  ? bestTrade?.outputAmount.toFixed() ?? ''
                  : typedValue
              }}
              onChangeSwapDirectionClick={onChangeSwapDirectionClick}
              isChangeSwapDirectionDisabled={isFetchingTrades || isSwapping}
              bottomContent={
                <Box w="full">
                  {bestTrade ? (
                    <TradeDetails
                      trade={bestTrade}
                      allowedSlippage={allowedSlippage}
                    />
                  ) : null}
                  <Flex flexDir="column" pt={{ base: 0, md: 4 }} gap={4}>
                    {approve &&
                    !isApproved &&
                    hasEnoughInputCurrency &&
                    !isFetchingTrades ? (
                      <ApproveTokenButton
                        data-cy="approve-button"
                        amount={bestTrade?.inputAmount.toExact()}
                        currencySymbol={inputCurrency?.symbol}
                        approvalType={approvalType}
                        onApprovalTypeSelect={setApprovalType}
                        isLoading={isApproving}
                        onClick={() => approve()}
                      />
                    ) : null}
                    {wrapUnwrapState && !!wrapUnwrap ? (
                      <Web3Button
                        data-cy="wrap-unwrap-button"
                        variant="primary"
                        colorScheme="accent"
                        bg="proAccent"
                        w="full"
                        size="xl"
                        isLoading={isLoadingWrapUnwrap}
                        loadingText={wrapUnwrapState}
                        onClick={() => wrapUnwrap?.()}
                      >
                        {wrapUnwrapState}
                      </Web3Button>
                    ) : (
                      <Web3Button
                        data-cy="swap-button"
                        variant="primary"
                        bg="proAccent"
                        borderRadius="0"
                        colorScheme={
                          isPriceImpactHigh && isConnected ? 'red' : 'accent'
                        }
                        w="full"
                        size="xl"
                        isLoading={isFetchingTrades || isSwapping}
                        loadingText={
                          isFetchingTrades
                            ? 'Fetching best trade'
                            : 'Waiting for confirmation'
                        }
                        isDisabled={
                          !typedTokenAmount ||
                          !isSwapEnabled ||
                          isLiquidityInsufficient ||
                          !swap ||
                          (isPriceImpactHigh && isSafeModeEnabled)
                        }
                        onClick={() => {
                          swap?.()
                          tradeSwap(
                            inputCurrency?.symbol,
                            outputCurrency?.symbol
                          )
                        }}
                      >
                        {isLiquidityInsufficient
                          ? 'Insufficient liquidity for this trade'
                          : hasEnoughInputCurrency
                          ? isPriceImpactHigh
                            ? isSafeModeEnabled
                              ? 'Safe Mode Activated'
                              : 'Swap Anyway'
                            : isSwap
                            ? 'Swap'
                            : 'Place Order'
                          : `Not enough ${inputCurrency?.symbol}`}
                      </Web3Button>
                    )}
                    {isPriceImpactHigh && isSafeModeEnabled ? (
                      <Text
                        fontSize="sm"
                        textColor="textPrimary"
                        textAlign="center"
                        mt={-2}
                      >
                        Safe mode prevents high price impact trade. It can be
                        accessed in Settings.
                      </Text>
                    ) : null}
                  </Flex>
                </Box>
              }
            />
          ) : (
            <PlaceOrder
              inputCurrency={inputCurrency}
              outputCurrency={outputCurrency}
              handleSetCurrency={handleSetCurrency}
              onChangeSwapDirectionClick={onChangeSwapDirectionClick}
            />
          )}

          {outputCurrency && outputCurrency.isToken && isSwap ? (
            <Center w="full" mt={4}>
              <AddToWalletButton token={outputCurrency} />
            </Center>
          ) : null}
        </Flex>
      </Grid>
    </Flex>
  )
}

export default Trade
