import { CheckCircleIcon } from '@chakra-ui/icons'
import { Flex, Heading, HStack, Text, VStack } from '@chakra-ui/react'
import { Currency } from '@traderjoe-team/spruce-sdk'
import Web3Button from 'components/Web3Button'
import useAddLiquidityV2, {
  UseAddLiquidityV2Props
} from 'hooks/pool/v2/useAddLiquidityV2'
import React from 'react'
import { getPriceFromBinId } from 'utils/bin'
import { formattedNum } from 'utils/format'
import { AddUniformLiquidityBatch } from 'utils/getAddUniformLiquidityBatches'
import { formatUnits } from 'viem'

interface ValueProps {
  title: string
  value: string
}

const Value = ({ title, value }: ValueProps) => (
  <Flex justify="space-between" w="full">
    <Text textColor="textSecondary" fontSize="sm">
      {title}
    </Text>
    <Text fontWeight="semibold" fontSize="sm">
      {value}
    </Text>
  </Flex>
)

interface AddUniformLiquidityBatchPanelProps {
  addLiquidityProps: UseAddLiquidityV2Props
  batch: AddUniformLiquidityBatch
  batchIndex: number
  binPerBatch: number
  binStep: number
  currency0: Currency
  currency1: Currency
  isSuccess: boolean
  onSuccess: () => void
  inversePriceRatios?: boolean
  onAddLiquidityPrepareContractWriteError?: () => void
}

const AddUniformLiquidityBatchPanel = ({
  addLiquidityProps,
  batch,
  batchIndex,
  binPerBatch,
  binStep,
  currency0,
  currency1,
  inversePriceRatios,
  isSuccess,
  onAddLiquidityPrepareContractWriteError,
  onSuccess
}: AddUniformLiquidityBatchPanelProps) => {
  const {
    addLiquidity,
    isLoading: isAddingLiquidity,
    isPreparingConfig,
    prepareConfigError
  } = useAddLiquidityV2({
    ...addLiquidityProps,
    amount0: batch.amount0,
    amount1: batch.amount1,
    distributionParams: batch.distributionParams,
    onSuccess
  })

  const minPrice = getPriceFromBinId(
    batch.binRange[0],
    binStep,
    currency0.decimals,
    currency1.decimals,
    18
  )
  const maxPrice = getPriceFromBinId(
    batch.binRange[1],
    binStep,
    currency0.decimals,
    currency1.decimals,
    18
  )

  const fmtMinPrice = formattedNum(
    inversePriceRatios ? 1 / Number(maxPrice) : minPrice
  )
  const fmtMaxPrice = formattedNum(
    inversePriceRatios ? 1 / Number(minPrice) : maxPrice
  )

  return (
    <Flex flexDir="column" bg="bgPrimary" p={4} gap={4} borderRadius="xl">
      <HStack>
        <Heading size="sm">Batch {batchIndex + 1}</Heading>
        {isSuccess ? <CheckCircleIcon color="green.400" /> : null}
      </HStack>
      <VStack w="full">
        <Value
          title={`Range`}
          value={`${fmtMinPrice} - ${fmtMaxPrice} ${
            inversePriceRatios ? currency0.symbol : currency1.symbol
          } per ${inversePriceRatios ? currency1.symbol : currency0.symbol}`}
        />
        <Value
          title={`${currency0?.symbol} to add`}
          value={`${formattedNum(
            formatUnits(batch.amount0, currency0?.decimals)
          )}`}
        />
        <Value
          title={`${currency1?.symbol} to add`}
          value={`${formattedNum(
            formatUnits(batch.amount1, currency1?.decimals)
          )}`}
        />
      </VStack>
      <Web3Button
        variant="primary"
        colorScheme="accent"
        size="xl"
        isDisabled={isSuccess || !addLiquidity}
        isLoading={isAddingLiquidity || isPreparingConfig}
        loadingText={isPreparingConfig ? 'Preparing transaction' : 'Adding'}
        onClick={() => addLiquidity?.()}
      >
        {isSuccess ? 'Added!' : 'Add Liquidity'}
      </Web3Button>
      {prepareConfigError && !isSuccess ? (
        <Text
          fontSize="sm"
          onClick={onAddLiquidityPrepareContractWriteError}
          cursor="pointer"
          color="red.500"
          _hover={{ opacity: 0.5 }}
        >
          {`Failed to prepare transaction with ${binPerBatch} bins per batch. Click here to
          try again with a lower value.`}
        </Text>
      ) : null}
    </Flex>
  )
}

export default AddUniformLiquidityBatchPanel
