import * as Sentry from '@sentry/react'
import { captureException } from '@sentry/react'

export const initializeErrorLogger = () => {
  const SENTRY_DSN: string | undefined = process.env.REACT_APP_SENTRY_DSN
  if (typeof SENTRY_DSN === 'string' && SENTRY_DSN) {
    Sentry.init({
      // https://docs.sentry.io/platforms/javascript/configuration/options/#allow-urls
      allowUrls: ['traderjoexyz.com'],
      beforeSend: (event, hint) => {
        const error = hint.originalException
        // ignore errors when the user intentionally rejects a transaction
        if (error instanceof Object) {
          const parsedError = error as { code?: string | number }
          if (
            parsedError.code === 4001 ||
            parsedError.code === 'ACTION_REJECTED'
          ) {
            return null
          }
        }
        return event
      },
      dsn: SENTRY_DSN,
      ignoreErrors: [
        'User canceled',
        'user rejected transaction',
        'Network Error',
        'UserRejectedRequestError: User rejected request',
        'XDEFI error',
        'Request aborted',
        'timeout (requestBody="[{"method":"eth_getBalance"',
        'missing response (requestBody="[{"method":"eth_getBalance"'
      ],
      release: process.env.REACT_APP_SENTRY_RELEASE,
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/sampling/#setting-a-uniform-sample-rate
      sampleRate: 0.25,
      tracesSampleRate: 0.1
    })
  }
}

// ethersjs error are returned as strings,
// so we need to extract the transaction data from the error string
const extractTransactionData = (
  error: Error
): { data?: string; from?: string; to?: string; value?: bigint } => {
  const errorString = error.toString()
  const transactionStart = errorString.indexOf('{"from"')
  const transactionEnd = errorString.indexOf(', error={')
  const transactionString = errorString.slice(transactionStart, transactionEnd)
  try {
    return JSON.parse(transactionString)
  } catch {
    return {}
  }
}

export const capturePrepareContractWriteError = (error: Error | null) => {
  if (!error) return
  const transactionData = extractTransactionData(error)
  captureException(error, { extra: transactionData })
}
