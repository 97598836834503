import useActiveChain from 'hooks/useActiveChain'
import { TokenInfo, TokenInfoTag } from 'types/tokensList'
import { useAccount, useBalance } from 'wagmi'

const useNativeTokenInfo = (): TokenInfo => {
  const chain = useActiveChain()
  const { address } = useAccount()
  const { data } = useBalance({ address, chainId: chain.id })
  return {
    balance: data?.formatted,
    chainId: chain.id,
    decimals: chain.nativeCurrency.decimals,
    name: chain.nativeCurrency.name,
    symbol: chain.nativeCurrency.symbol,
    tags: [TokenInfoTag.TOP]
  }
}

export default useNativeTokenInfo
