import {
  Box,
  Center,
  Flex,
  Heading,
  SimpleGrid,
  Spinner,
  useToken
} from '@chakra-ui/react'
import AnalyticsChart from 'components/AnalyticsChart'
import AnalyticsStat from 'components/AnalyticsStat'
import useVaultBalances from 'hooks/vault/useVaultBalances'
import useVaultTvlHistory from 'hooks/vault/useVaultTvlHistory'
import React, { useMemo } from 'react'
import { Vault } from 'types/vault'
import { formattedNum } from 'utils/format'
import { formatUnits } from 'viem'

interface VaultAnalyticsProps {
  isTabSelected: boolean
  vault: Vault
}

const VaultAnalytics = ({ isTabSelected, vault }: VaultAnalyticsProps) => {
  const [graphPurple] = useToken('colors', ['graphPurpleDark'])

  const { data: tvlHistory } = useVaultTvlHistory({
    enabled: isTabSelected,
    vaultAddress: vault.id
  })
  const tvlChartData = useMemo(() => {
    return tvlHistory
      ? tvlHistory.map((x) => ({
          date: x.timestamp,
          value: x.tvlUsd
        }))
      : []
  }, [tvlHistory])

  const {
    isLoading: isLoadingBalances,
    tokenBalancesX,
    tokenBalancesY
  } = useVaultBalances({ vault })

  if (isLoadingBalances) {
    return (
      <Center pt={8}>
        <Spinner />
      </Center>
    )
  }

  const fmtBalancesX = formatBalances(tokenBalancesX, vault.tokenX.decimals)
  const fmtBalancesY = formatBalances(tokenBalancesY, vault.tokenY.decimals)

  return (
    <Flex flexDir="column" gap={8}>
      <SimpleGrid columns={{ base: 2, md: 3 }} spacing={4}>
        <AnalyticsStat
          title="Token X deployed in pool"
          value={`${fmtBalancesX.inPool} ${vault.tokenX.symbol}`}
        />
        <AnalyticsStat
          title="Token X queued for withdrawal"
          value={`${fmtBalancesX.queued} ${vault.tokenX.symbol}`}
        />
        <AnalyticsStat
          title="Token X in vault"
          value={`${fmtBalancesX.inVault} ${vault.tokenX.symbol}`}
        />
        <AnalyticsStat
          title="Token Y deployed in pool"
          value={`${fmtBalancesY.inPool} ${vault.tokenY.symbol}`}
        />
        <AnalyticsStat
          title="Token Y queued for withdrawal"
          value={`${fmtBalancesY.queued} ${vault.tokenY.symbol}`}
        />
        <AnalyticsStat
          title="Token Y in vault"
          value={`${fmtBalancesY.inVault} ${vault.tokenY.symbol}`}
        />
      </SimpleGrid>
      <Box
        p={{ base: 4, md: 8 }}
        bg="bgCard"
        border="1px"
        borderColor="border"
        borderRadius="2xl"
      >
        <AnalyticsChart
          id="tvl"
          data={tvlChartData}
          header={<Heading size="md">TVL (Total Locked Value)</Heading>}
          h="220px"
          tooltipTitle="TVL"
          fill={graphPurple}
          stroke={graphPurple}
          showXAxis
          showYAxis
          hideTab
        />
      </Box>
    </Flex>
  )
}

export default VaultAnalytics

const formatBalances = (
  tokenBalances: {
    inPool: bigint
    inVault: bigint
    queued: bigint
  },
  tokenDecimals: number
) => {
  const inPool = formattedNum(
    formatUnits(tokenBalances.inPool, tokenDecimals),
    { places: 2 }
  )
  const queued = formattedNum(
    formatUnits(tokenBalances.queued, tokenDecimals),
    { places: 2 }
  )
  const inVault = formattedNum(
    formatUnits(tokenBalances.inVault, tokenDecimals),
    { places: 2 }
  )
  return {
    inPool,
    inVault,
    queued
  }
}
