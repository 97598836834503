import { useQuery } from '@tanstack/react-query'
import { Pool as DexbarnPool, PoolQueryParam } from 'types/dexbarn'
import { Pool } from 'types/pool'
import { getDexbarnChainParam } from 'utils/chains'
import { convertDexbarnPoolToPool } from 'utils/poolV2'

import useChainId from './useChainId'
import useDebounce from './useDebounce'
import { useDexbarnGet } from './useDexbarn'

interface UsePairsSearchProps {
  query: string
  enabled?: boolean
  filterBy?: PoolQueryParam.FilterBy
  orderBy?: PoolQueryParam.OrderBy
  status?: PoolQueryParam.Status
}

const usePairsSearch = ({
  enabled = true,
  filterBy = '1d',
  orderBy = 'liquidity',
  query,
  status = 'all'
}: UsePairsSearchProps) => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)

  const debouncedQuery = useDebounce(query, 400)
  const terms = debouncedQuery.split(' ')
  const _query = terms[0].trim()

  const _enabled = _query.length >= 3 && enabled

  const fetchLbPairs = useDexbarnGet<DexbarnPool[]>(
    `/v1/pools/search?query=${_query}&chain=${chain}&status=${status}&filterBy=${filterBy}&orderBy=${orderBy}`
  )
  const { data: poolsV2, isLoading: isLoadingV2 } = useQuery<DexbarnPool[]>(
    ['LBPairsSearchV2Query', _query, filterBy, orderBy, status],
    () => fetchLbPairs(),
    {
      enabled: _enabled
    }
  )

  let pairs: Pool[] = poolsV2?.map(convertDexbarnPoolToPool) ?? []

  const term2 = terms.length > 1 ? terms[1].trim().toLowerCase() : undefined
  if (term2) {
    pairs = pairs.filter(
      (pair) =>
        pair.name.toLowerCase().includes(term2) ||
        pair.name.toLowerCase().includes(term2)
    )
  }

  return {
    data: pairs,
    isLoading: isLoadingV2 && _enabled
  }
}

export default usePairsSearch
