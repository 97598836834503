import {
  LB_FACTORY_V21_ADDRESS,
  LBFactoryABI,
  LBPairV21ABI
} from '@traderjoe-team/spruce-sdk-v2'
import useChainId from 'hooks/useChainId'
import { getAddress } from 'viem'
import { useContractRead, useContractReads } from 'wagmi'

import useLBPairFeeParameters from './useLBPairFeeParameters'

interface UseLBPairDataProps {
  binStep?: string
  isV21?: boolean
  token0Address?: string
  token1Address?: string
}

const useLBPairData = ({
  binStep,
  isV21,
  token0Address,
  token1Address
}: UseLBPairDataProps) => {
  const chainId = useChainId()

  const { data: lbPairInfo, isLoading: isLoadingPairInfo } = useContractRead({
    abi: LBFactoryABI, // getLBPairInformation is the same in both versions
    address: getAddress(LB_FACTORY_V21_ADDRESS[chainId]),
    args:
      token0Address && token1Address && binStep
        ? [
            getAddress(token0Address),
            getAddress(token1Address),
            BigInt(binStep)
          ]
        : undefined,
    chainId,
    enabled: !!token0Address && !!token1Address && !!binStep,
    functionName: 'getLBPairInformation'
  })

  const lbPairContractV21 = {
    abi: LBPairV21ABI,
    address: lbPairInfo?.LBPair,
    chainId
  }
  const {
    data: reservesAndIdV21,
    isLoading: isLoadingV21ReservesAndId,
    refetch: refetchReservesAndActiveBinIdV21
  } = useContractReads({
    contracts: [
      {
        ...lbPairContractV21,
        functionName: 'getReserves'
      },
      {
        ...lbPairContractV21,
        functionName: 'getActiveId'
      }
    ],
    enabled: !!lbPairInfo && isV21
  })
  const reservesV21 = reservesAndIdV21?.[0].result as
    | [bigint, bigint]
    | undefined

  const { data: feeParameters, isLoading: isLoadingFeeParameters } =
    useLBPairFeeParameters({ isV21, lbPairAddress: lbPairInfo?.LBPair })

  const activeBinId: number | undefined =
    reservesAndIdV21 && Number(reservesAndIdV21[1].result)

  return {
    activeBinId,
    feeParameters,
    isLoading:
      isLoadingPairInfo || isLoadingV21ReservesAndId || isLoadingFeeParameters,
    lbPairInfo,
    refetchReservesAndActiveBinId: refetchReservesAndActiveBinIdV21,
    reserveX: reservesV21?.[0],
    reserveY: reservesV21?.[1]
  }
}

export default useLBPairData
