import { Center, SimpleGrid, Spinner, Text } from '@chakra-ui/react'
import React from 'react'
import { Pool } from 'types/pool'

import HeaderSearchResultRow from './HeaderSearchResultRow'

interface SearchResultsProps {
  isLoading: boolean
  onRowClick: () => void
  pairs: Pool[]
}

const SearchResults = ({
  isLoading,
  onRowClick,
  pairs
}: SearchResultsProps) => {
  return isLoading ? (
    <Center p={8}>
      <Spinner size="sm" />
    </Center>
  ) : pairs && pairs.length > 0 ? (
    <SimpleGrid columns={{ '2xl': 2, base: 1 }} gap={2} p={2}>
      {pairs.map((pair, i) => (
        <HeaderSearchResultRow key={i} pair={pair} onClick={onRowClick} />
      ))}
    </SimpleGrid>
  ) : (
    <Center p={8}>
      <Text color="textPrimary">No results found</Text>
    </Center>
  )
}

export default SearchResults
