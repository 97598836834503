import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  Center,
  Collapse,
  Flex,
  Grid,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Show,
  Spinner,
  Tab,
  TabList,
  Tabs,
  Text
} from '@chakra-ui/react'
import SearchBar from 'components/SearchBar'
import useSortedPools, { PoolSortMethod } from 'hooks/pool/useSortedPools'
import React, { useCallback, useState } from 'react'
import { ArrowUpDownIcon, SearchIcon } from 'theme/icons'

import PoolMobileCard from './PoolMobileCard'
import { PoolsTableProps } from './PoolsTable'

enum PoolsMobileSort {
  // LeastFees = 'Least Fees',
  LeastAPR = 'Lowest APR',
  LeastLiquidity = 'Lowest Liquidity',
  LeastVolume = 'Lowest Volume',
  // MostFees = 'Highest Fees',
  MostAPR = 'Highest APR',
  MostLiquidity = 'Highest Liquidity',
  MostVolume = 'Highest Volume'
}

const PoolsMobileList = ({
  hasNextPage,
  isFetchingPage,
  isLoadingPools,
  onLoadMoreClick,
  onPoolsStatusChange,
  onQueryChange,
  pools,
  query,
  rewardPriceUsdObj,
  selectedPoolStatus,
  setSortMethod,
  sortMethod
}: PoolsTableProps): JSX.Element => {
  const [showSearchBar, setShowSearchBar] = useState(false)
  const toggleSetShowSearchBar = () => setShowSearchBar(!showSearchBar)

  // Sorting
  const sorts = [
    PoolsMobileSort.MostVolume,
    PoolsMobileSort.LeastVolume,
    PoolsMobileSort.MostLiquidity,
    PoolsMobileSort.LeastLiquidity,
    PoolsMobileSort.MostAPR,
    PoolsMobileSort.LeastAPR
  ]
  const [sort, setSort] = useState(PoolsMobileSort.MostVolume)
  const [isSortDescending, setIsSortDescending] = useState<boolean>(true)

  const handleChangeSort = useCallback(
    (s: PoolsMobileSort) => {
      setSort(s)
      switch (s) {
        case PoolsMobileSort.MostLiquidity:
        case PoolsMobileSort.LeastLiquidity:
          setSortMethod(PoolSortMethod.LIQUIDITY)
          break
        case PoolsMobileSort.MostVolume:
        case PoolsMobileSort.LeastVolume:
          setSortMethod(PoolSortMethod.VOLUME)
          break
        case PoolsMobileSort.MostAPR:
        case PoolsMobileSort.LeastAPR:
          setSortMethod(PoolSortMethod.APR)
          break
      }

      switch (s) {
        case PoolsMobileSort.MostLiquidity:
        case PoolsMobileSort.MostVolume:
        case PoolsMobileSort.MostAPR:
          setIsSortDescending(true)
          break
        case PoolsMobileSort.LeastLiquidity:
        case PoolsMobileSort.LeastVolume:
        case PoolsMobileSort.LeastAPR:
          setIsSortDescending(false)
          break
      }
    },
    [setSortMethod]
  )

  const sortedPools = useSortedPools({
    isSortDescending,
    pools,
    sortMethod
  })

  return (
    <Flex flexDir="column" gap={2}>
      <Tabs
        isFitted={true}
        variant="soft-rounded-outlined-white"
        flexShrink={0}
        index={selectedPoolStatus === 'main' ? 0 : 1}
        onChange={(index) => onPoolsStatusChange(index === 0 ? 'main' : 'all')}
      >
        <TabList borderRadius="md" h={10}>
          <Tab borderRadius="md">Main Pools</Tab>
          <Tab borderRadius="md">All Pools</Tab>
        </TabList>
      </Tabs>
      <Collapse in={!showSearchBar} animateOpacity>
        <Grid templateColumns="40px 1fr" gap={2}>
          <IconButton
            aria-label="open search bar"
            boxSize="40px"
            variant="outline"
            size="sm"
            icon={<SearchIcon boxSize={4} />}
            onClick={toggleSetShowSearchBar}
          />
          <Menu>
            <MenuButton
              w="full"
              variant="outline"
              as={Button}
              rightIcon={<ChevronDownIcon />}
              borderColor="border"
              borderWidth={1}
            >
              <HStack>
                <Show above="md">
                  <Text color="textSecondary" fontWeight="normal">
                    Sorting:
                  </Text>
                </Show>
                <Text color="textPrimary" fontWeight="semibold">
                  {sort}
                </Text>
              </HStack>
            </MenuButton>
            <MenuList>
              {sorts.map((s, i) => (
                <MenuItem key={i} h="48px" onClick={() => handleChangeSort(s)}>
                  {s}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Grid>
      </Collapse>
      <Collapse in={showSearchBar} animateOpacity>
        <Grid p="2px" w="full" templateColumns="40px 1fr" gap={2}>
          <IconButton
            aria-label="close search bar"
            boxSize="40px"
            variant="outline"
            size="sm"
            icon={<ArrowUpDownIcon boxSize={4} />}
            onClick={toggleSetShowSearchBar}
          />
          <SearchBar
            placeholder="Search by name, symbol or address"
            value={query}
            onValueChange={onQueryChange}
          />
        </Grid>
      </Collapse>
      {isLoadingPools ? (
        <Center mt={8}>
          <Spinner />
        </Center>
      ) : (
        <>
          <Flex flexDir="column" gap={2} mb={4}>
            {sortedPools.map((pool, idx) => (
              <PoolMobileCard
                key={idx}
                pool={pool}
                rewardPriceUsdObj={rewardPriceUsdObj}
              />
            ))}
          </Flex>
          {!isLoadingPools &&
          query === '' &&
          sortedPools.length > 0 &&
          hasNextPage ? (
            <Button
              variant="outline-bold"
              size="lg"
              alignSelf="center"
              w="160px"
              isLoading={isFetchingPage}
              loadingText="Loading"
              onClick={onLoadMoreClick}
            >
              Load More
            </Button>
          ) : null}
        </>
      )}
    </Flex>
  )
}

export default PoolsMobileList
