import { Token } from '@traderjoe-team/spruce-sdk'
import { LBPairV21ABI } from '@traderjoe-team/spruce-sdk-v2'
import useGetAllLBPairs from 'hooks/pool/v2/useGetAllLBPairs'
import useChainId from 'hooks/useChainId'
import { useMemo, useState } from 'react'
import { getBinIdFromPrice, getPriceFromBinId } from 'utils/bin'
import { getAddress } from 'viem'
import { useContractReads } from 'wagmi'

interface UsePrepareLimitOrderProps {
  tokenIn?: Token
  tokenOut?: Token
}

const usePrepareLimitOrder = ({
  tokenIn,
  tokenOut
}: UsePrepareLimitOrderProps) => {
  const chainId = useChainId()
  const [typedPrice, setTypedPrice] = useState<string>('')
  const [inputBinId, setInputBinId] = useState<number | undefined>()
  const [isPriceRatioInversed, setIsPriceRatioInversed] =
    useState<boolean>(false)

  const tokenInAddr = tokenIn?.address ? getAddress(tokenIn.address) : undefined
  const tokenOutAddr = tokenOut?.address
    ? getAddress(tokenOut.address)
    : undefined

  // get all v2.1 pools
  const { data: existingV21LbPairs, isLoading: isLoadingAllLBPairs } =
    useGetAllLBPairs({
      tokenXAddress: tokenInAddr, // doesn't matter which is X/Y
      tokenYAddress: tokenOutAddr
    })

  // get pool with lowest bin step
  const { binStep, lbPairAddr } = useMemo(() => {
    if (!existingV21LbPairs || existingV21LbPairs.length === 0) return {}

    const minBinStepPair = existingV21LbPairs.reduce((prev, curr) =>
      prev.binStep < curr.binStep ? prev : curr
    )

    return {
      binStep: minBinStepPair.binStep,
      lbPairAddr: minBinStepPair.LBPair
    }
  }, [existingV21LbPairs])

  // get and set active bin
  const contractV21 = {
    abi: LBPairV21ABI,
    address: lbPairAddr,
    chainId
  }
  const { data: reads } = useContractReads({
    contracts: [
      {
        ...contractV21,
        functionName: 'getActiveId'
      },
      {
        ...contractV21,
        functionName: 'getTokenX'
      },
      {
        ...contractV21,
        functionName: 'getTokenY'
      }
    ],
    enabled: !!lbPairAddr
  })

  const activeId = reads?.[0].result as number | undefined
  const tokenXAddr = reads?.[1].result as string | undefined
  const tokenYAddr = reads?.[2].result as string | undefined

  const arePairTokensInversed = tokenXAddr === tokenOut?.address

  const tokenX = tokenXAddr === tokenInAddr ? tokenIn : tokenOut
  const tokenY = tokenYAddr === tokenInAddr ? tokenIn : tokenOut

  // function to calculate price from bin id
  const calculatePriceFromBinId = (binId?: number) => {
    if (!binId || !binStep || !tokenX || !tokenY) {
      return { priceXY: 0, priceYX: 0 }
    }

    const priceXY = Number(
      getPriceFromBinId(binId, binStep, tokenX.decimals, tokenY.decimals, 18) ??
        0
    )

    return {
      priceXY,
      priceYX: 1 / priceXY
    }
  }

  // price of bin
  const { priceXY, priceYX } = calculatePriceFromBinId(inputBinId)

  // price of active bin
  const activePrice = useMemo(() => {
    if (!activeId || !binStep || !tokenX || !tokenY) {
      return 0
    }

    const activePriceXY = Number(
      getPriceFromBinId(
        activeId,
        binStep,
        tokenX.decimals,
        tokenY.decimals,
        18
      ) ?? 0
    )

    // set default price
    const binId = arePairTokensInversed ? activeId - 1 : activeId + 1
    setInputBinId(binId)
    const defaultPriceXY = Number(
      getPriceFromBinId(binId, binStep, tokenX.decimals, tokenY.decimals, 18)
    )

    const newTypedPrice =
      (arePairTokensInversed && !isPriceRatioInversed) ||
      (!arePairTokensInversed && isPriceRatioInversed)
        ? (1 / defaultPriceXY).toString()
        : defaultPriceXY.toString()
    setTypedPrice(newTypedPrice)

    return activePriceXY
  }, [
    activeId,
    binStep,
    tokenX,
    tokenY,
    setTypedPrice,
    arePairTokensInversed,
    isPriceRatioInversed
  ])

  // update input bin id from price and returns the bin id and priceXY
  const updateInputBinIdFromPrice = (price: number) => {
    if (!binStep || !tokenX || !tokenY) {
      return
    }

    const binId = getBinIdFromPrice(
      (arePairTokensInversed ? 1 / price : price).toString(),
      binStep,
      tokenX,
      tokenY
    )
    setInputBinId(binId)

    const { priceXY, priceYX } = calculatePriceFromBinId(binId)

    return { binId, priceXY, priceYX }
  }

  const togglePriceRatio = () => {
    if (!activeId || !inputBinId) return

    const isInversed = !isPriceRatioInversed
    setIsPriceRatioInversed(isInversed)

    const diff = activeId - inputBinId
    const binId = activeId - diff
    setInputBinId(binId)

    const { priceXY, priceYX } = calculatePriceFromBinId(binId)
    setTypedPrice(
      (arePairTokensInversed && !isInversed) ||
        (!arePairTokensInversed && isInversed)
        ? priceYX.toString()
        : priceXY.toString()
    )
  }

  const resetToDefaultPrice = () => {
    if (!activeId || !binStep || !tokenX || !tokenY) {
      return
    }

    const binId = arePairTokensInversed ? activeId - 1 : activeId + 1
    setInputBinId(binId)

    const defaultPriceXY = Number(
      getPriceFromBinId(binId, binStep, tokenX.decimals, tokenY.decimals, 18)
    )
    const newTypedPrice =
      (arePairTokensInversed && !isPriceRatioInversed) ||
      (!arePairTokensInversed && isPriceRatioInversed)
        ? (1 / defaultPriceXY).toString()
        : defaultPriceXY.toString()
    setTypedPrice(newTypedPrice)

    return newTypedPrice
  }

  return {
    activeId,
    activePrice,
    arePairTokensInversed,
    binStep,
    inputBinId,
    isLoadingAllLBPairs,
    isPriceRatioInversed,
    lbPairAddr,
    priceXY,
    priceYX,
    resetToDefaultPrice,
    setTypedPrice,
    togglePriceRatio,
    tokenX,
    tokenY,
    typedPrice,
    updateInputBinIdFromPrice
  }
}

export default usePrepareLimitOrder
