import { CNATIVE, WNATIVE } from '@traderjoe-team/spruce-sdk'
import useChainId from 'hooks/useChainId'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LBPoolVersion } from 'types/pool'

const usePoolDetailV2Params = () => {
  const { binStep, currencyId0, currencyId1, poolVersion } = useParams()
  const navigate = useNavigate()

  const chainId = useChainId()
  const nativeCurrency = CNATIVE.onChain(chainId)
  const wrappedNativeCurrency = WNATIVE[chainId]

  useEffect(() => {
    if (poolVersion && !['v2', 'v21'].includes(poolVersion)) {
      navigate('/404')
    }
  }, [navigate, poolVersion])

  return {
    binStep,
    currencyId0,
    currencyId1,
    lbPoolVersion: poolVersion as LBPoolVersion | undefined,
    token0Address:
      currencyId0 === nativeCurrency?.symbol
        ? wrappedNativeCurrency.address
        : currencyId0,
    token1Address:
      currencyId1 === nativeCurrency?.symbol
        ? wrappedNativeCurrency.address
        : currencyId1
  }
}

export default usePoolDetailV2Params
