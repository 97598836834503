import { FlexProps, Tag } from '@chakra-ui/react'
import PoolCard from 'components/PoolCard'
import { getRewardRates, getRewardTokens, isPoolRewarded } from 'constants/pool'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { Pool } from 'types/pool'
import { formattedNum } from 'utils/format'
import { getPoolDetailUrl } from 'utils/poolUrl'

interface PoolMobileCardProps {
  pool: Pool
  rewardPriceUsdObj: { [key: string]: number }
}

const PoolMobileCard = ({
  pool,
  rewardPriceUsdObj,
  ...props
}: PoolMobileCardProps & FlexProps): JSX.Element => {
  const chainId = useChainId()

  // get rewards APR
  const isRewarded = isPoolRewarded(chainId, pool.pairAddress)
  const rewardRates = getRewardRates(chainId, pool.pairAddress)
  const rewardTokens = getRewardTokens(chainId, pool.pairAddress)

  const rewardPricesUSD =
    rewardRates && rewardTokens
      ? rewardTokens.map(
          (rewardToken) => rewardPriceUsdObj[rewardToken.toLowerCase()]
        )
      : undefined

  const rewardsPerDay =
    rewardPricesUSD && rewardRates
      ? rewardRates.reduce(
          (prev, currRewardRate, index) =>
            prev + currRewardRate * rewardPricesUSD[index],
          0
        )
      : 0
  const rewardsAPR = (rewardsPerDay / pool.liquidityUsd) * 365
  const totalAPR = pool.apr + (rewardsAPR ?? 0)

  return (
    <PoolCard
      token0={pool.tokenX}
      token1={pool.tokenY}
      href={getPoolDetailUrl(pool, chainId)}
      columns={[
        {
          title: 'Liquidity',
          value: formattedNum(pool.liquidityUsd, { usd: true })
        },
        {
          title: 'Volume (24H)',
          value: formattedNum(pool.volumeUsd, { usd: true })
        },
        {
          title: 'APR (24H)',
          value: `${formattedNum(totalAPR * 100, { places: 2 })}%`
        }
      ]}
      tags={[
        <Tag
          key="fees-tag"
          variant="solid"
          size="md"
          bg="bgTertiary"
          textColor="textPrimary"
        >
          {`${pool.feePct}%`}
        </Tag>,
        isRewarded ? (
          <Tag
            key="rewards-tag"
            variant="solid"
            size="md"
            bg="green.400"
            textColor="white"
          >
            REWARD
          </Tag>
        ) : null,
        pool.isMigrated ? (
          <Tag
            key="migrated-tag"
            variant="solid"
            size="md"
            bg="yellowBar"
            textColor="black"
          >
            OUTDATED
          </Tag>
        ) : null
      ]}
      {...props}
    />
  )
}

export default PoolMobileCard
