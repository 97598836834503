import { ArrowBackIcon } from '@chakra-ui/icons'
import { Button, ButtonProps, Flex } from '@chakra-ui/react'
import React from 'react'

const BackButton = ({ ...props }: ButtonProps) => {
  return (
    <Button
      borderRadius={0}
      alignItems="center"
      justifyContent="center"
      bg="silver"
      _dark={{ _hover: { bg: 'joeDark.500' }, bg: 'joeDark.600' }}
      p={0}
      {...props}
    >
      <Flex alignItems="center" justifyContent="center">
        <ArrowBackIcon boxSize="20px" />
      </Flex>
    </Button>
  )
}

export default BackButton
