import { ChevronDownIcon } from '@chakra-ui/icons'
import { Button, ButtonProps } from '@chakra-ui/react'
import { Currency } from '@traderjoe-team/spruce-sdk'
import CurrencyLogo from 'components/CurrencyLogo'
import React from 'react'

interface CurrencySelectButtonProps {
  onCurrencyPickerOpen: () => void
  currency?: Currency
  currencyAddress?: string
  currencyPickerButtonProps?: ButtonProps
  hideDownIcon?: boolean
  isDisabled?: boolean
}

const CurrencySelectButton = ({
  currency,
  currencyAddress,
  currencyPickerButtonProps,
  hideDownIcon,
  isDisabled,
  onCurrencyPickerOpen
}: CurrencySelectButtonProps) => {
  return (
    <Button
      width="100%"
      data-cy="currency-picker-button"
      bg="joeLight.400"
      _hover={{ bg: 'hover' }}
      size="md"
      leftIcon={
        currency ? (
          <CurrencyLogo
            address={currencyAddress}
            symbol={currency.symbol}
            boxSize={5}
          />
        ) : undefined
      }
      rightIcon={!hideDownIcon ? <ChevronDownIcon /> : undefined}
      isDisabled={isDisabled}
      onClick={onCurrencyPickerOpen}
      {...currencyPickerButtonProps}
      cursor="pointer"
    >
      {currency?.symbol ?? 'Select token'}
    </Button>
  )
}

export default CurrencySelectButton
