import { ArrowDownIcon } from '@chakra-ui/icons'
import {
  Button,
  Center,
  Flex,
  SimpleGrid,
  Text,
  VStack
} from '@chakra-ui/react'
import NumericalInput from 'components/NumericalInput'
import Web3Button from 'components/Web3Button'
import React, { useCallback, useMemo, useState } from 'react'
import { formattedNum } from 'utils/format'

interface WithdrawSharesPanelProps {
  isRemoving: boolean
  onPercentageChange: (percentage: string, isInvalid: boolean) => void
  percentage: string
  amountDesired0?: number
  amountDesired1?: number
  bodyContent?: JSX.Element
  footerText?: string
  onRemoveClick?: () => void
  tokenSymbol0?: string
  tokenSymbol1?: string
}

const WithdrawSharesPanel = ({
  amountDesired0,
  amountDesired1,
  bodyContent,
  footerText,
  isRemoving,
  onPercentageChange,
  onRemoveClick,
  percentage,
  tokenSymbol0,
  tokenSymbol1
}: WithdrawSharesPanelProps) => {
  const options = ['25', '50', '75', '100']
  const [activeOption, setActiveOption] = useState<string | undefined>()

  const getIsInvalid = (value: string) => {
    if (value === '') return false
    return Number(value) <= 0 || Number(value) > 100
  }

  const onTypedPercentageChange = useCallback(
    (typedAmount: string) => {
      setActiveOption(undefined)
      onPercentageChange(typedAmount, getIsInvalid(typedAmount))
    },
    [onPercentageChange]
  )

  const onOptionChange = useCallback(
    (option: string) => {
      setActiveOption(option)
      onPercentageChange(option, getIsInvalid(option))
    },
    [onPercentageChange]
  )

  const inputError = useMemo(() => {
    if (!getIsInvalid(percentage)) return undefined
    if (Number(percentage) <= 0) {
      return 'The percentage must be greater than 0'
    }
    if (Number(percentage) > 100) {
      return 'The percentage must be lower than 100'
    }
    return undefined
  }, [percentage])

  return (
    <VStack spacing={4}>
      <VStack align="flex-start" w="full">
        <Text fontSize="sm" color="textSecondary">
          Percentage to withdraw:
        </Text>
        <NumericalInput
          data-cy="withdraw-shares-input"
          inputType="integer"
          size="lg"
          placeholder="0"
          isInvalid={!!inputError}
          value={percentage}
          onValueChange={onTypedPercentageChange}
        />
        {inputError ? (
          <Text fontSize="sm" color="red">
            {inputError}
          </Text>
        ) : null}
      </VStack>
      <SimpleGrid columns={options.length} columnGap={4} w="full">
        {options.map((option, i) => (
          <Button
            data-cy={`${option}-percent-button`}
            key={i}
            variant="outline"
            isActive={activeOption === option}
            onClick={() => onOptionChange(option)}
            bg="white"
            _active={{ _dark: { bg: 'joeDark.500' }, bg: 'joeLight.400' }}
          >{`${option}%`}</Button>
        ))}
      </SimpleGrid>
      <Center>
        <ArrowDownIcon />
      </Center>
      <VStack
        align="flex-start"
        w="full"
        border="1px"
        borderColor="border"
        borderRadius="xl"
        p={4}
      >
        <Flex justify="space-between" w="full">
          <Text data-cy="amount-desired-0">
            {amountDesired0 ? formattedNum(amountDesired0) : '-'}
          </Text>
          <Text>{tokenSymbol0}</Text>
        </Flex>
        <Flex justify="space-between" w="full">
          <Text data-cy="amount-desired-1">
            {amountDesired1 ? formattedNum(amountDesired1) : '-'}
          </Text>
          <Text>{tokenSymbol1}</Text>
        </Flex>
      </VStack>
      {bodyContent ? bodyContent : null}
      <Web3Button
        data-cy="remove-liquidity-button"
        variant="primary"
        colorScheme="accent"
        size="xl"
        w="full"
        isLoading={isRemoving}
        loadingText="Removing"
        isDisabled={!onRemoveClick || !!inputError}
        onClick={onRemoveClick}
      >
        Remove Liquidity
      </Web3Button>
      {footerText ? (
        <Text textAlign="center" fontSize="sm" textColor="textSecondary">
          {footerText}
        </Text>
      ) : null}
    </VStack>
  )
}

export default WithdrawSharesPanel
