import { Box, Flex, IconButton, VStack } from '@chakra-ui/react'
import { CurrencyInputProps } from 'components/CurrencyInput'
import React from 'react'
import { ArrowUpDownIcon } from 'theme/icons'

import TradeCurrencyInput from './TradeCurrencyInput'

interface TradeCurrencyInputsProps {
  bottomContent: JSX.Element
  inputCurrencyProps: CurrencyInputProps
  onChangeSwapDirectionClick: () => void
  outputCurrencyProps: CurrencyInputProps
  isChangeSwapDirectionDisabled?: boolean
}

const TradeCurrencyInputs = ({
  bottomContent,
  inputCurrencyProps,
  isChangeSwapDirectionDisabled,
  onChangeSwapDirectionClick,
  outputCurrencyProps
}: TradeCurrencyInputsProps) => {
  return (
    <VStack
      pos="relative"
      overflow="hidden"
      w="full"
      px={{ base: 4, md: 8 }}
      spacing={4}
      pt={4}
    >
      <TradeCurrencyInput
        data-cy="trade-currency-input"
        {...inputCurrencyProps}
      />
      <Flex w="full" align="center" gap={1}>
        <Box borderRadius="full" h="1px" bg="border" w="full" />
        <IconButton
          data-cy="change-swap-direction-button"
          bg="bgCard"
          borderRadius="full"
          icon={<ArrowUpDownIcon />}
          aria-label="change swap direction"
          isDisabled={isChangeSwapDirectionDisabled}
          onClick={onChangeSwapDirectionClick}
        />
        <Box borderRadius="full" h="1px" bg="border" w="full" />
      </Flex>
      <TradeCurrencyInput
        data-cy="trade-currency-output"
        {...outputCurrencyProps}
      />
      {bottomContent}
    </VStack>
  )
}

export default TradeCurrencyInputs
