import {
  Box,
  Center,
  Divider,
  Flex,
  Grid,
  Heading,
  Spacer,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack
} from '@chakra-ui/react'
import CurrencyAmountsPanel from 'components/CurrencyAmountsPanel'
import PageHelmet from 'components/PageHelmet'
import {
  getRewarderInfo,
  isPoolRewarded,
  POOL_HELMET_DESCRIPTION,
  POOL_HELMET_TITLE,
  REWARD_TOKENS
} from 'constants/pool'
import { FEATURE_FLAGS } from 'featureFlags'
import useLBPairData from 'hooks/pool/v2/useLBPairData'
import useLBPairRewards from 'hooks/pool/v2/useLBPairRewards'
import useLBPosition from 'hooks/pool/v2/useLBPosition'
import usePoolDetailV2Params from 'hooks/pool/v2/usePoolDetailV2Params'
import usePoolV2 from 'hooks/pool/v2/usePoolV2'
import useChainId from 'hooks/useChainId'
import useSdkCurrencies from 'hooks/useSdkCurrencies'
import { useTokenBalance } from 'hooks/useTokenBalance'
import useTokenPriceUSD from 'hooks/useTokenPriceUSD'
import debounce from 'lodash.debounce'
import PoolDetailHeader from 'pages/PoolDetail/PoolDetailHeader'
import React, { useMemo, useState } from 'react'
import { computeAndParsePriceFromBin } from 'utils/prices'
import { getCurrencyAddress, wrappedCurrency } from 'utils/wrappedCurrency'
import { useAccount } from 'wagmi'

import AddRemoveLiquidityPanelV2 from './AddRemoveLiquidityPanelV2'
import ClaimFees from './ClaimFees'
import ClaimRewards from './ClaimRewards'
import Leaderboard from './Leaderboard'
import MigratedPoolBanner from './MigratedPoolBanner'
import PoolDetailV2AnalyticsPanel from './PoolDetailV2AnalyticsPanel'
import PoolLimitOrders from './PoolLimitOrders'
import RewardingDetails from './RewardingDetails'
import RewardsHistoryTable from './RewardsHistoryTable'
import UserEarnedFees from './UserEarnedFees'
import UserLiquidityChart from './UserLiquidityChart'

const PoolDetailV2 = () => {
  const { address: account, isConnected } = useAccount()
  const {
    binStep,
    currencyId0,
    currencyId1,
    lbPoolVersion,
    token0Address,
    token1Address
  } = usePoolDetailV2Params()
  const chainId = useChainId()

  const isV21 = lbPoolVersion === 'v21'
  const isPlaceOrdersEnabled = FEATURE_FLAGS.placeOrders && isV21

  enum TabType {
    LIQUIDITY = 0,
    ORDERS = 1,
    ANALYTICS = isPlaceOrdersEnabled ? 2 : 1,
    REWARDS_LEADERBOARD = isPlaceOrdersEnabled ? 3 : 2
  }
  const [selectedTab, setSelectedTab] = useState<TabType>(TabType.LIQUIDITY)

  const {
    activeBinId,
    feeParameters: { baseFee },
    isLoading,
    lbPairInfo,
    refetchReservesAndActiveBinId,
    reserveX,
    reserveY
  } = useLBPairData({
    binStep,
    isV21,
    token0Address,
    token1Address
  })

  const { data: pool, isLoading: isLoadingPoolV2 } = usePoolV2({
    address: lbPairInfo?.LBPair
  })

  const {
    tokens: [currency0, currency1]
  } = useSdkCurrencies({
    addresses: [currencyId0, currencyId1],
    // NOTE: override the symbol for the bridged USDC pools
    symbolByAddressOverrides:
      pool && pool.tokenX.address && pool.tokenY.address
        ? {
            [pool.tokenX.address]: pool.tokenX.symbol,
            [pool.tokenY.address]: pool.tokenY.symbol
          }
        : undefined
  })
  const wrappedCurrency0 = wrappedCurrency(currency0, chainId)
  const wrappedCurrency1 = wrappedCurrency(currency1, chainId)

  const { data: balance0, refetch: refetchBalance0 } = useTokenBalance({
    token: getCurrencyAddress(currency0)
  })
  const debouncedRefetchBalance0 = debounce(() => refetchBalance0(), 4000)
  const { data: balance1, refetch: refetchBalance1 } = useTokenBalance({
    token: getCurrencyAddress(currency1)
  })
  const debouncedRefetchBalance1 = debounce(() => refetchBalance1(), 4000)

  const {
    isLoadingLBPosition: isLoadingUserLBPosition,
    pooledTokens0,
    pooledTokens1,
    refetchLBPosition: refetchUserLBPosition,
    userBalances
  } = useLBPosition({
    lbPairAddress: lbPairInfo?.LBPair,
    owner: account,
    token0: wrappedCurrency0,
    token1: wrappedCurrency1
  })

  const hasRewarder = lbPairInfo
    ? Boolean(getRewarderInfo(chainId, lbPairInfo.LBPair))
    : false
  const isRewarded = lbPairInfo
    ? isPoolRewarded(chainId, lbPairInfo.LBPair)
    : false

  // get reward token price
  const rewardTokens = REWARD_TOKENS[chainId]
  const { data } = useTokenPriceUSD({
    tokens: rewardTokens
  })
  const rewardPriceUsdObj: { [key: string]: number } =
    data && data.length === rewardTokens.length
      ? rewardTokens.reduce(
          (prev, curr, i) => ({ ...prev, [curr.toLowerCase()]: data[i] }),
          {}
        )
      : {}

  const {
    claimableRewards,
    handleClaimRewards,
    isLoading: isRewardLoading,
    rewardsHistoryData
  } = useLBPairRewards({
    isRewarded: hasRewarder,
    lbPairAddress: lbPairInfo?.LBPair
  })

  // allow the user to toggle between price ratios
  const [inversePriceRatios, setPriceRatiosInversed] = useState(false)
  const togglePriceRatiosClicked = () => setPriceRatiosInversed((prev) => !prev)

  // calculate active bin price
  const activeBinPrice = useMemo((): string | undefined => {
    if (!activeBinId) return undefined
    const wrappedCurrency0 = wrappedCurrency(currency0, chainId)
    const wrappedCurrency1 = wrappedCurrency(currency1, chainId)
    if (!wrappedCurrency0 || !wrappedCurrency1) {
      return undefined
    }
    const price = computeAndParsePriceFromBin(
      activeBinId,
      Number(binStep),
      wrappedCurrency0,
      wrappedCurrency1,
      wrappedCurrency1?.decimals
    )
    return inversePriceRatios ? (1 / Number(price)).toFixed(18) : price
  }, [activeBinId, binStep, currency0, currency1, inversePriceRatios, chainId])

  if (isLoading || !lbPairInfo || isLoadingPoolV2) {
    return (
      <Center minH="100vh">
        <Spinner />
      </Center>
    )
  }

  // wait for the indexer to update the user's positions, then ask for a refetch
  const debouncedRefetchUserLBPosition = debounce(
    () => refetchUserLBPosition(),
    10000
  )

  return (
    <Box pt={{ base: 6, md: 28 }} w="full">
      <PageHelmet
        title={POOL_HELMET_TITLE}
        description={POOL_HELMET_DESCRIPTION}
        url={location.pathname}
      />
      <PoolDetailHeader
        address={lbPairInfo?.LBPair}
        token0={{
          address: currency0?.isToken ? currency0.address : undefined,
          symbol: currency0?.isNative
            ? currency0?.symbol
            : pool?.tokenX.symbol ?? currency0?.symbol
        }}
        token1={{
          address: currency1?.isToken ? currency1.address : undefined,
          symbol: currency1?.isNative
            ? currency1?.symbol
            : pool?.tokenY.symbol ?? currency0?.symbol
        }}
        tag={baseFee ? `${baseFee} bps` : ''}
        isRewarded={isRewarded}
        slippageSettingsPickerType="poolV2"
        px={4}
        inversePriceRatios={inversePriceRatios}
        onInversePriceRatiosClick={togglePriceRatiosClicked}
        activeBinPrice={activeBinPrice}
        mb={{ base: 4, md: 0 }}
        showHotkeyTooltip
      />
      <Tabs
        variant="lb-pro-line"
        pos="relative"
        mt={{ base: 0, md: '-42px' }}
        w="full"
        onChange={setSelectedTab}
      >
        <Box overflowX="auto" overflowY="hidden">
          <TabList
            maxW={{ '2xl': 'calc(1600px - 32px)', base: 'calc(1400px - 32px)' }}
            margin="0 auto"
            borderBottom="1px"
            borderBottomColor="border"
          >
            <Tab>Manage</Tab>
            {isPlaceOrdersEnabled ? (
              <Tab data-cy="orders-tab">Orders</Tab>
            ) : null}
            <Tab>Analytics</Tab>
            <Tab>{hasRewarder ? 'Leaderboard / Rewards' : 'Leaderboard'}</Tab>
          </TabList>
        </Box>
        <TabPanels w="full" minH="calc(100vh - 250px)">
          <TabPanel
            pb={32}
            pt={0}
            maxW={{ '2xl': '1600px', base: '1400px' }}
            margin="0 auto"
            w="full"
            px={4}
          >
            <Grid
              templateColumns={{
                base: 'minmax(0, 1fr)',
                xl: 'minmax(0, 1fr) minmax(0, 1fr)'
              }}
              alignItems="flex-start"
            >
              <VStack
                spacing={4}
                borderRight={{ base: 'none', md: '1px solid' }}
                borderColor={{ base: 'none', md: 'border' }}
              >
                {pool && pool.isMigrated && binStep ? (
                  <MigratedPoolBanner
                    pairAddress={pool.pairAddress}
                    tokenX={wrappedCurrency0?.address}
                    tokenY={wrappedCurrency1?.address}
                    binStep={Number(binStep)}
                  />
                ) : null}
                <Box w="full">
                  <Box>
                    {activeBinId && binStep ? (
                      <UserLiquidityChart
                        currency0={currency0}
                        currency1={currency1}
                        binStep={binStep}
                        userBalances={userBalances}
                        activeBinId={activeBinId}
                        isLoadingUserBalances={isLoadingUserLBPosition}
                        isPriceRatioInversed={inversePriceRatios}
                      />
                    ) : null}
                  </Box>
                  <Divider />
                  {wrappedCurrency0 && wrappedCurrency1 ? (
                    <CurrencyAmountsPanel
                      title="DEPOSIT BALANCE"
                      tokens={[
                        wrappedCurrency0.address,
                        wrappedCurrency1.address
                      ]}
                      tokenAmounts={[pooledTokens0 ?? 0, pooledTokens1 ?? 0]}
                      tokenSymbols={[currency0?.symbol, currency1?.symbol]}
                      boxProps={{ pr: { base: 0, md: 8 } }}
                    />
                  ) : null}
                </Box>
                {isConnected && wrappedCurrency0 && wrappedCurrency1 ? (
                  <Box w="full" borderTop="1px solid" borderColor="border">
                    <Box py={{ base: 4, md: 6 }}>
                      <UserEarnedFees
                        currency0={currency0}
                        currency1={currency1}
                        tokenAddrs={[
                          wrappedCurrency0.address,
                          wrappedCurrency1.address
                        ]}
                        lbPairAddress={lbPairInfo?.LBPair}
                        isPriceRatioInversed={inversePriceRatios}
                        isV21={isV21}
                        boxProps={{ pr: { base: 0, md: 8 } }}
                      />
                    </Box>
                    {lbPoolVersion === 'v2' ? (
                      <>
                        <Divider />
                        <ClaimFees
                          lbPairAddress={lbPairInfo?.LBPair}
                          wrappedCurrency0={wrappedCurrency0}
                          wrappedCurrency1={wrappedCurrency1}
                          currency0Symbol={currency0?.symbol}
                          currency1Symbol={currency1?.symbol}
                          userPositions={userBalances?.positions}
                        />
                      </>
                    ) : null}
                    {isConnected && hasRewarder ? (
                      <>
                        <Divider />
                        <ClaimRewards
                          claimableRewards={claimableRewards}
                          isLoading={isRewardLoading}
                          handleClaimRewards={handleClaimRewards}
                          poolSymbol={`${currency0?.symbol}-${currency1?.symbol}`}
                        />
                      </>
                    ) : null}
                  </Box>
                ) : null}
              </VStack>

              {currency0 && currency1 && lbPairInfo && lbPoolVersion ? (
                <Box>
                  <AddRemoveLiquidityPanelV2
                    lbPairAddress={lbPairInfo.LBPair}
                    isPoolMigrated={pool?.isMigrated ?? false}
                    lbPoolVersion={lbPoolVersion}
                    currency0={currency0}
                    currency1={currency1}
                    balance0={balance0}
                    balance1={balance1}
                    binStep={binStep}
                    activeBinId={activeBinId}
                    userBalances={userBalances}
                    onAddLiquiditySuccess={() => {
                      debouncedRefetchBalance0()
                      debouncedRefetchBalance1()
                      debouncedRefetchUserLBPosition()
                    }}
                    onRemoveLiquiditySuccess={() => {
                      debouncedRefetchBalance0()
                      debouncedRefetchBalance1()
                      debouncedRefetchUserLBPosition()
                    }}
                    onRemoveLiquidityConfigError={() => {
                      refetchReservesAndActiveBinId()
                      refetchUserLBPosition()
                    }}
                    inversePriceRatios={inversePriceRatios}
                    togglePriceRatiosClicked={togglePriceRatiosClicked}
                  />
                </Box>
              ) : null}
            </Grid>
          </TabPanel>
          {isPlaceOrdersEnabled ? (
            <TabPanel
              pb={32}
              pt={8}
              maxW={{ '2xl': '1600px', base: '1400px' }}
              margin="0 auto"
              w="full"
              px={4}
            >
              <PoolLimitOrders
                lbPairAddr={lbPairInfo?.LBPair}
                binStep={binStep}
                activeBinId={activeBinId}
                currency0={currency0}
                currency1={currency1}
                balance0={balance0}
                balance1={balance1}
                tokenX={wrappedCurrency0}
                tokenY={wrappedCurrency1}
                isPriceRatioInversed={inversePriceRatios}
                togglePriceRatiosClicked={togglePriceRatiosClicked}
              />
            </TabPanel>
          ) : null}
          <TabPanel
            pb={32}
            pt={8}
            maxW={{ '2xl': '1600px', base: '1400px' }}
            margin="0 auto"
          >
            {currency0 && currency1 ? (
              <PoolDetailV2AnalyticsPanel
                lbPairAddress={lbPairInfo?.LBPair}
                activeBinId={activeBinId}
                baseFee={baseFee}
                isSelected={selectedTab === 1}
                currency0={currency0}
                currency1={currency1}
                reserveX={reserveX}
                reserveY={reserveY}
                isPriceRatioInversed={inversePriceRatios}
                rewardPriceUsdObj={rewardPriceUsdObj}
                liquidityDepthTokenX={pool?.liquidityDepthTokenX}
                liquidityDepthTokenY={pool?.liquidityDepthTokenY}
              />
            ) : null}
          </TabPanel>
          {hasRewarder ? (
            <TabPanel
              pb={32}
              pt={10}
              maxW={{ '2xl': '1600px', base: '1400px' }}
              margin="0 auto"
            >
              <Heading size="lg">Rewards</Heading>
              <Spacer mt="1rem" />
              <Grid
                templateColumns={{ base: '1fr', lg: '1.5fr 1fr' }}
                gap={{ base: 4, md: 8 }}
                alignItems="flex-start"
              >
                <Flex gap={{ base: 4, md: 8 }} direction="column">
                  {isRewarded ? (
                    <RewardingDetails
                      lbPairAddress={lbPairInfo.LBPair}
                      rewardPriceUsdObj={rewardPriceUsdObj}
                    />
                  ) : null}
                  <RewardsHistoryTable
                    rewardsHistoryData={rewardsHistoryData}
                  />
                </Flex>
                {hasRewarder ? (
                  <Box border="1px solid" borderColor="border">
                    <ClaimRewards
                      claimableRewards={claimableRewards}
                      isLoading={isRewardLoading}
                      handleClaimRewards={handleClaimRewards}
                      poolSymbol={`${currency0?.symbol}-${currency1?.symbol}`}
                    />
                  </Box>
                ) : null}
              </Grid>
              <Spacer mt={{ base: 4, md: 8 }} />
              <Leaderboard
                currency0={currency0}
                currency1={currency1}
                lbPairAddress={lbPairInfo?.LBPair}
              />
            </TabPanel>
          ) : null}
          <TabPanel
            pb={32}
            pt={10}
            maxW={{ '2xl': '1600px', base: '1400px' }}
            margin="0 auto"
          >
            {currency0 && currency1 ? (
              <Leaderboard
                currency0={currency0}
                currency1={currency1}
                lbPairAddress={lbPairInfo?.LBPair}
              />
            ) : null}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default PoolDetailV2
