import { Divider, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import ColorModeButton from 'components/Header/ColorModeButton'
import React from 'react'
import { ArrowLeftIcon } from 'theme/icons'
import { WalletMenuEnum } from 'types/walletMenu'

import WalletMenuItem from '../ConnectedScreen/WalletMenuItem'

interface SettingsScreenProps {
  setWalletMenuDisplay: (menuDisplay: WalletMenuEnum) => void
}

const SettingsScreen = ({ setWalletMenuDisplay }: SettingsScreenProps) => {
  return (
    <>
      <HStack px={3} align="center" pb={4}>
        <WalletMenuItem
          columnGap={4}
          onClick={() => setWalletMenuDisplay(WalletMenuEnum.Default)}
        >
          <ArrowLeftIcon />
          <Flex flexDir="column" alignItems="flex-start">
            <Text fontWeight="bold" fontSize="lg" textColor="textPrimary">
              Settings
            </Text>
          </Flex>
        </WalletMenuItem>
      </HStack>
      <Divider />
      <HStack px={6} pt={4} w="full">
        <VStack w="full" alignItems="flex-start">
          <Text fontWeight="bold">Theme</Text>
        </VStack>
        <VStack w="full" alignItems="flex-end">
          <ColorModeButton />
        </VStack>
      </HStack>
    </>
  )
}

export default SettingsScreen
