import { Currency } from '@traderjoe-team/spruce-sdk'
import { LiquidityDistributionParams } from '@traderjoe-team/spruce-sdk-v2'
import { WEI_PER_ETHER } from 'constants/bigint'

export interface AddUniformLiquidityBatch {
  amount0: bigint
  amount1: bigint
  binRange: number[]
  distributionParams: LiquidityDistributionParams
}

export const getAddUniformLiquidityBatches = (
  binPerBatch: number,
  binRange?: number[],
  activeBinId?: number,
  currency0?: Currency,
  currency1?: Currency,
  totalAmount0?: bigint,
  totalAmount1?: bigint,
  distributionParams?: LiquidityDistributionParams
): AddUniformLiquidityBatch[] | undefined => {
  if (
    !binRange ||
    !activeBinId ||
    !currency0 ||
    !currency1 ||
    totalAmount0 === undefined ||
    totalAmount1 === undefined ||
    !distributionParams
  ) {
    return undefined
  }

  const start = binRange[0]
  const end = binRange[1]

  const isActiveBinTargetAdd =
    binRange.length === 2 &&
    binRange[0] === binRange[1] &&
    binRange[0] === activeBinId

  if (
    start > end ||
    binRange.includes(NaN) ||
    !!binRange.find((val) => !isFinite(val)) ||
    (totalAmount0 === BigInt(0) && totalAmount1 === BigInt(0)) ||
    (!isActiveBinTargetAdd &&
      totalAmount1 === BigInt(0) &&
      start < activeBinId) ||
    (!isActiveBinTargetAdd &&
      totalAmount0 === BigInt(0) &&
      start > activeBinId) ||
    (isActiveBinTargetAdd &&
      totalAmount1 === BigInt(0) &&
      totalAmount0 === BigInt(0))
  ) {
    return undefined
  }

  const nbOfBins = binRange[1] - binRange[0] + 1
  const nbOfBatchs = binRange ? Math.ceil(nbOfBins / binPerBatch) : 1

  return [...Array(nbOfBatchs).keys()].map((i) => {
    const offset = i === 0 ? 0 : 1
    const batchStart = start + i * binPerBatch + offset
    const batchEnd = Math.min(start + (i + 1) * binPerBatch, end)

    const nbBinsInBatch = batchEnd - batchStart + 1
    const sliceStartIndex = i * binPerBatch + offset
    const sliceEndIndex = sliceStartIndex + nbBinsInBatch

    const deltaIds = distributionParams.deltaIds.slice(
      sliceStartIndex,
      sliceEndIndex
    )

    // we scale the distribution X to make sure the sum is 1e18
    const distributionX = distributionParams.distributionX.slice(
      sliceStartIndex,
      sliceEndIndex
    )
    const sumDistributionX = distributionX.reduce(
      (acc, val) => acc + val,
      BigInt(0)
    )
    const missingDistributionX = WEI_PER_ETHER - sumDistributionX
    const scaledDistributionX = distributionX.map((val) =>
      sumDistributionX > 0 && missingDistributionX > 0
        ? val + (missingDistributionX * val) / sumDistributionX
        : val
    )

    // we scale the distribution Y to make sure the sum is 1e18
    const distributionY = distributionParams.distributionY.slice(
      sliceStartIndex,
      sliceEndIndex
    )
    const sumDistributionY = distributionY.reduce(
      (acc, val) => acc + val,
      BigInt(0)
    )
    const missingDistributionY = WEI_PER_ETHER - sumDistributionY
    const scaledDistributionY = distributionY.map((val) =>
      sumDistributionY > 0 && missingDistributionY > 0
        ? val + (missingDistributionY * val) / sumDistributionY
        : val
    )

    const amount0 =
      nbOfBatchs > 1
        ? distributionX.reduce(
            (acc, val) => acc + (val * totalAmount0) / WEI_PER_ETHER,
            BigInt(0)
          )
        : totalAmount0

    const amount1 =
      nbOfBatchs > 1
        ? distributionY.reduce(
            (acc, val) => acc + (val * totalAmount1) / WEI_PER_ETHER,
            BigInt(0)
          )
        : totalAmount1

    return {
      amount0,
      amount1,
      binRange: [batchStart, batchEnd],
      distributionParams: {
        deltaIds,
        distributionX: scaledDistributionX,
        distributionY: scaledDistributionY
      }
    }
  })
}
