import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import {
  setChainId,
  setIsDedicatedRPC,
  setIsRemoveLiquidityInNativeCurrencyEnabled,
  setIsSafeModeEnabled,
  setSlippage,
  setTransactionDeadline
} from './actions'
import { initialSlippageSettings, SlippageType } from './reducer'

const useSelectedChainId = () => {
  const dispatch = useAppDispatch()
  const selectedChainId = useAppSelector((state) => state.settings.chainId)
  const setSelectedChainId = useCallback(
    (chainId: number) => {
      dispatch(setChainId(chainId))
    },
    [dispatch]
  )
  return { selectedChainId, setSelectedChainId }
}

const useSlippageSettings = () => {
  const dispatch = useAppDispatch()
  const slippageSettings = useAppSelector(
    (state) => state.settings.slippageSettings
  )
  const updateSlippage = useCallback(
    (type: SlippageType, value: string) => {
      dispatch(
        setSlippage({
          type,
          value: value
            ? Math.trunc(parseFloat(value) * 100) / 100
            : initialSlippageSettings[type]
        })
      )
    },
    [dispatch]
  )
  return { slippageSettings, updateSlippage }
}

const useUserTransactionDeadline = () => {
  const userTransactionDeadline = useAppSelector(
    (state) => state.settings.transactionDeadline
  )

  const dispatch = useAppDispatch()
  const setUserTransactionDeadline = useCallback(
    (value: string) => {
      dispatch(setTransactionDeadline(value ? parseFloat(value) : undefined))
    },
    [dispatch]
  )

  return { setUserTransactionDeadline, userTransactionDeadline }
}

const useIsSafeModeEnabled = () => {
  const dispatch = useAppDispatch()
  const isSafeModeEnabled = useAppSelector(
    (state) => state.settings.isSafeModeEnabled
  )
  const updateIsSafeModeEnabled = useCallback(
    (isEnabled: boolean) => {
      dispatch(setIsSafeModeEnabled(isEnabled))
    },
    [dispatch]
  )
  return { isSafeModeEnabled, updateIsSafeModeEnabled }
}

const useIsDedicatedRPC = () => {
  const dispatch = useAppDispatch()
  const isDedicatedRPCSelected = useAppSelector(
    (state) => state.settings.isDedicatedRPC
  )
  const updateDedicatedRPC = useCallback(
    (isEnabled: boolean) => {
      dispatch(setIsDedicatedRPC(isEnabled))
    },
    [dispatch]
  )
  return { isDedicatedRPCSelected, updateDedicatedRPC }
}

const useIsRemoveLiquidityInNativeCurrencyEnabled = () => {
  const dispatch = useAppDispatch()
  const isRemoveLiquidityInNativeCurrencyEnabled = useAppSelector(
    (state) => state.settings.isRemoveLiquidityInNativeCurrencyEnabled
  )
  const updateIsRemoveLiquidityInNativeCurrencyEnabled = useCallback(
    (isEnabled: boolean) => {
      dispatch(setIsRemoveLiquidityInNativeCurrencyEnabled(isEnabled))
    },
    [dispatch]
  )
  return {
    isRemoveLiquidityInNativeCurrencyEnabled,
    updateIsRemoveLiquidityInNativeCurrencyEnabled
  }
}

export {
  useIsDedicatedRPC,
  useIsRemoveLiquidityInNativeCurrencyEnabled,
  useIsSafeModeEnabled,
  useSelectedChainId,
  useSlippageSettings,
  useUserTransactionDeadline
}
