import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Box, Flex, HStack, Link, Text } from '@chakra-ui/react'
import CurrencyAmountsPanel from 'components/CurrencyAmountsPanel'
import Web3Button from 'components/Web3Button'
import React from 'react'
import { FormattedReward } from 'types/rewards'
import { poolClaimRewards } from 'utils/measure'

interface ClaimRewardsProps {
  claimableRewards: FormattedReward[]
  handleClaimRewards: (() => void) | undefined
  isLoading: boolean
  poolSymbol: string
}

const ClaimRewards = ({
  claimableRewards,
  handleClaimRewards,
  isLoading,
  poolSymbol
}: ClaimRewardsProps) => {
  return (
    <CurrencyAmountsPanel
      title="Claimable Rewards"
      tokens={claimableRewards.map((reward) => reward.tokenAddress)}
      tokenAmounts={claimableRewards.map((reward) => reward.tokenAmount)}
      tokenSymbols={claimableRewards.map((reward) => reward.token?.symbol)}
      bottomContent={
        claimableRewards.length === 0 ? (
          <Flex
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            py={8}
          >
            <Text
              fontSize="sm"
              color="textPrimary"
              fontWeight="semibold"
              textAlign="center"
            >
              You don&apos;t have any claimable rewards yet
            </Text>
            <Link
              isExternal
              aria-label="Rewards for Trader Joe Liquidity Book"
              href="https://support.traderjoexyz.com/en/articles/6971431-liquidity-book-rewards-program"
              color="accent.500"
              fontSize="sm"
              fontWeight="semibold"
              textAlign="center"
            >
              <HStack spacing={1} justifyContent="center">
                <Box as="span">Learn More</Box>
                <ExternalLinkIcon color="accent.500" />
              </HStack>
            </Link>
          </Flex>
        ) : (
          <Web3Button
            variant="primary"
            colorScheme="accent"
            size="xl"
            w="full"
            mt={4}
            isDisabled={!handleClaimRewards}
            isLoading={isLoading}
            loadingText="Claiming rewards"
            onClick={() => {
              handleClaimRewards?.()
              poolClaimRewards(poolSymbol)
            }}
          >
            Claim Rewards
          </Web3Button>
        )
      }
    />
  )
}

export default ClaimRewards
