import {
  Box,
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@chakra-ui/react'
import { Currency } from '@traderjoe-team/spruce-sdk'
import { RemoveLiquidityV2Option } from 'hooks/pool/v2/useRemoveLiquidityV2'
import useChainId from 'hooks/useChainId'
import React, { useMemo } from 'react'
import { getMaxBinPerRemoveLiquidityBatch } from 'utils/getMaxBinPerBatch'

import PriceRangeInputs from './PriceRangeInputs'

const RemoveSingleTokenText = ({
  keyword,
  symbol
}: {
  keyword: 'higher' | 'lower'
  symbol?: string
}): JSX.Element => {
  return (
    <Box fontSize="sm" textColor="textPrimary">
      You will remove{' '}
      <Box as="span" fontWeight="semibold">
        {symbol}
      </Box>{' '}
      tokens from bins with prices{' '}
      <Box as="span" fontWeight="semibold">
        {keyword}
      </Box>{' '}
      than that of the{' '}
      <Box as="span" fontWeight="semibold">
        active bin
      </Box>
      .{' '}
      <Box as="span" fontWeight="semibold">
        {symbol}
      </Box>{' '}
      tokens in the{' '}
      <Box as="span" fontWeight="semibold">
        active bin
      </Box>{' '}
      will remain in the pool.
    </Box>
  )
}

interface RemoveLiquidityOptionsProps {
  binStep: number
  currency0: Currency
  currency1: Currency
  currencyPrice0: number | undefined
  currencyPrice1: number | undefined
  onRangeChange: (range: number[]) => void
  onRemoveLiquidityOptionChange: (option: RemoveLiquidityV2Option) => void
  range: number[]
  userPositions: number[]
  inversePriceRatios?: boolean
}

const RemoveLiquidityOptions = ({
  binStep,
  currency0,
  currency1,
  currencyPrice0,
  currencyPrice1,
  inversePriceRatios,
  onRangeChange,
  onRemoveLiquidityOptionChange,
  range,
  userPositions
}: RemoveLiquidityOptionsProps) => {
  const chainId = useChainId()

  const options = useMemo(
    () => [
      {
        name: `Remove Both`,
        option: RemoveLiquidityV2Option.BOTH_TOKENS
      },
      {
        name: `Remove ${currency0.symbol}`,
        option: RemoveLiquidityV2Option.TOKEN_A
      },
      {
        name: `Remove ${currency1.symbol}`,
        option: RemoveLiquidityV2Option.TOKEN_B
      }
    ],
    [currency0, currency1]
  )

  const nbBins = useMemo(
    () =>
      userPositions.filter(
        (position) => position >= range[0] && position <= range[1]
      ).length,
    [userPositions, range]
  )
  const largeRangeDisclaimerText = useMemo(
    () =>
      nbBins > getMaxBinPerRemoveLiquidityBatch(chainId)
        ? 'Removing liquidity from a large range requires more than one transaction.'
        : undefined,
    [nbBins, chainId]
  )

  return (
    <Tabs
      isFitted
      colorScheme="accent"
      size="sm"
      onChange={(index) => onRemoveLiquidityOptionChange(options[index].option)}
    >
      <TabList>
        {options.map((option, i) => (
          <Tab key={i} data-cy={option.name}>
            {option.name}
          </Tab>
        ))}
      </TabList>
      <TabPanels py={4}>
        <TabPanel p={0}>
          <Flex justifyContent="space-between" alignItems="center">
            <Heading size="md" mt={2} mb={4}>
              PRICE RANGE
            </Heading>
          </Flex>
          <PriceRangeInputs
            currencyPrice0={currencyPrice0}
            currencyPrice1={currencyPrice1}
            min={userPositions[0]}
            max={userPositions[userPositions.length - 1]}
            binRange={range}
            binStep={binStep}
            currency0={currency0}
            currency1={currency1}
            largeRangeDisclaimerText={largeRangeDisclaimerText}
            onBinRangeChange={onRangeChange}
            inversePriceRatios={inversePriceRatios}
          />
        </TabPanel>
        <TabPanel>
          <RemoveSingleTokenText symbol={currency0.symbol} keyword="higher" />
        </TabPanel>
        <TabPanel>
          <RemoveSingleTokenText symbol={currency1.symbol} keyword="lower" />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default RemoveLiquidityOptions
