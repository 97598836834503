import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Box, Flex, Heading, HStack, Link, VStack } from '@chakra-ui/react'
import { LiquidityDistribution } from '@traderjoe-team/spruce-sdk-v2'
import React from 'react'

import DistributionOptions from './DistributionOptions'

interface LiquidityShapePickerProps {
  distribution: LiquidityDistribution
  onDistributionChange: (distribution: LiquidityDistribution) => void
}

const LiquidityShapePicker = ({
  distribution,
  onDistributionChange
}: LiquidityShapePickerProps) => {
  return (
    <VStack align="flex-start" spacing={4} w="full">
      <Flex w="full" align="center" justify="space-between">
        <Heading size="md">SET LIQUIDITY SHAPE</Heading>
        <Link
          isExternal
          aria-label="Liquidity strategies for Trader Joe Liquidity Book"
          href="https://support.traderjoexyz.com/en/articles/6707938-liquidity-strategies"
          color="textPrimary"
          fontSize="sm"
          flexShrink={0}
        >
          <HStack spacing={1}>
            <Box as="span">Learn more</Box>
            <ExternalLinkIcon color="textPrimary" />
          </HStack>
        </Link>
      </Flex>
      <DistributionOptions
        distribution={distribution}
        onDistributionChange={onDistributionChange}
      />
    </VStack>
  )
}

export default LiquidityShapePicker
