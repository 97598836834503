import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Flex,
  Heading,
  HStack,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { Currency } from '@traderjoe-team/spruce-sdk'
import { format, formatDistance } from 'date-fns'
import useActiveChain from 'hooks/useActiveChain'
import React from 'react'
import { SwapEvent } from 'types/dexbarn'
import { formattedNum } from 'utils/format'

interface PoolSwapsTableProps {
  currency0: Currency
  currency1: Currency
  swaps: SwapEvent[]
}

const PoolSwapsTable = ({
  currency0,
  currency1,
  swaps
}: PoolSwapsTableProps) => {
  const { blockExplorers } = useActiveChain()
  const now = new Date()

  return (
    <Flex flexDir="column" gap={8}>
      <Heading size="md">RECENT SWAPS</Heading>
      <TableContainer>
        <Table maxWidth="100%" size="sm">
          <Thead>
            <Tr borderBottom="1px" borderBottomColor="border">
              <Th>From</Th>
              <Th>To</Th>
              <Th>Price</Th>
              <Th>Bin ID</Th>
              <Th textAlign="right">Timestamp</Th>
            </Tr>
          </Thead>
          <Tbody>
            {swaps &&
              swaps.map((swap, key) =>
                SwapRow({
                  blockExplorerUrl: blockExplorers?.default.url,
                  currency0,
                  currency1,
                  key,
                  now,
                  swap
                })
              )}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  )
}

interface SwapRowInterface {
  currency0: Currency
  currency1: Currency
  key: number
  now: Date
  swap: SwapEvent
  blockExplorerUrl?: string
}

const SwapRow = ({
  blockExplorerUrl,
  currency0,
  currency1,
  key,
  now,
  swap
}: SwapRowInterface) => {
  const from = `${formattedNum(swap.amountIn, { places: 4 })} ${
    swap.swapForY ? currency0.symbol : currency1.symbol
  }`
  const to = `${formattedNum(swap.amountOut, { places: 4 })} ${
    swap.swapForY ? currency1.symbol : currency0.symbol
  }`
  const price = formattedNum(swap.swapForY ? swap.priceXY : swap.priceYX, {
    places: 6
  })
  const timestamp = new Date(swap.timestamp)
  return (
    <Tr key={key} borderBottom="1px" borderBottomColor="border">
      <Td verticalAlign="top">{from}</Td>
      <Td verticalAlign="top">{to}</Td>
      <Td verticalAlign="top">{price}</Td>
      <Td verticalAlign="top">{swap.binId}</Td>
      <Td verticalAlign="top" textAlign="right">
        <Flex flexDir="column" align="flex-end">
          <Link
            aria-label="Link to swap transaction on explorer"
            isExternal
            href={`${blockExplorerUrl}/tx/${swap.transactionHash}`}
          >
            <HStack spacing={1}>
              <Text>{format(timestamp, 'yyyy-MM-dd HH:mm:ss')}</Text>
              <ExternalLinkIcon />
            </HStack>
          </Link>
          <Text color={'GrayText'} fontSize={'xs'} fontWeight="semibold">
            {formatDistance(timestamp, now, {
              addSuffix: true,
              includeSeconds: true
            })}
          </Text>
        </Flex>
      </Td>
    </Tr>
  )
}

export default PoolSwapsTable
