import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  Tabs,
  VStack
} from '@chakra-ui/react'
import { CNATIVE, Currency, Token } from '@traderjoe-team/spruce-sdk'
import SearchBar from 'components/SearchBar'
import useTokensList from 'hooks/tokensList/useTokensList'
import useChainId from 'hooks/useChainId'
import useScrollTabIntoView from 'hooks/useScrollTabIntoView'
import React, { useMemo, useState } from 'react'
import { TokenInfoTag } from 'types/tokensList'
import { formattedNum } from 'utils/format'
import { useAccount } from 'wagmi'

import CurrencyRow from './CurrencyRow'

interface CurrencyPickerModalProps {
  isOpen: boolean
  onClose: () => void
  onCurrencyChange: (currency: Currency) => void
}

const CurrencyPickerModal = ({
  isOpen,
  onClose,
  onCurrencyChange
}: CurrencyPickerModalProps) => {
  const { isConnected } = useAccount()
  const chainId = useChainId()
  const [query, setQuery] = useState<string>('')

  const [tabIndex, setTabIndex] = useState(0)
  const handleChange = (nextTabIndex: number) => {
    if (nextTabIndex !== tabIndex) {
      setTabIndex(nextTabIndex)
    }
  }
  const tags = useMemo(() => {
    const tags = [TokenInfoTag.TOP, TokenInfoTag.FX, TokenInfoTag.COMMODITY]
    if (isConnected) {
      tags.splice(0, 0, TokenInfoTag.USER)
    }
    return tags
  }, [isConnected])

  const { isLoadingBalances, tokenFoundByAddress, tokens } = useTokensList({
    activeTag: tags.length > 0 ? tags[tabIndex] : undefined,
    query
  })

  const visibleTokens = tokenFoundByAddress ? [tokenFoundByAddress] : tokens
  const tablistRef = useScrollTabIntoView(tabIndex)

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent overflow="hidden" borderRadius="0 !important" px={4}>
        <ModalHeader mt={4}>SELECT A TOKEN</ModalHeader>
        <ModalCloseButton
          mt={4}
          mx={4}
          bg="white"
          _dark={{ _hover: { bg: 'joeDark.500' }, bg: 'joeDark.700' }}
          _hover={{ bg: 'silver' }}
        />
        <ModalBody p={0}>
          <Box px={6} mb={4}>
            <SearchBar
              size="lg"
              data-cy="currency-picker-search-bar"
              placeholder="Search by name, symbol or address"
              value={query}
              onValueChange={setQuery}
              bg="bgPrimary"
            />
          </Box>
          {tags.length > 0 ? (
            <Tabs
              variant="lb-pro-line"
              index={tabIndex}
              onChange={handleChange}
              mx={6}
              borderBottom="1px solid"
              borderColor="border"
            >
              <TabList
                ref={tablistRef}
                overflowX="scroll"
                sx={{
                  '::-webkit-scrollbar': {
                    display: 'none'
                  },
                  scrollbarWidth: 'none'
                }}
              >
                {tags.map((tag, i) => (
                  <Tab key={i} flexShrink={0} isDisabled={query.length > 0}>
                    {tag}
                  </Tab>
                ))}
              </TabList>
            </Tabs>
          ) : null}
          <Box>
            <VStack
              w="full"
              minH="280px"
              bg="bgPrimary"
              maxH="66vh"
              p={4}
              overflow="auto"
            >
              {visibleTokens.map((token, i) => (
                <CurrencyRow
                  key={i}
                  balance={
                    token.balance ? formattedNum(token.balance) : undefined
                  }
                  subtitle={
                    tokenFoundByAddress ? 'Found by address' : undefined
                  }
                  isLoadingBalance={isLoadingBalances}
                  tokenInfo={token}
                  shouldShowTags={true}
                  onClick={() => {
                    if (!token.address) {
                      onCurrencyChange(CNATIVE.onChain(chainId))
                      onClose()
                      return
                    }
                    onCurrencyChange(
                      new Token(
                        token.chainId,
                        token.address,
                        token.decimals,
                        token.symbol,
                        token.name
                      )
                    )
                    onClose()
                  }}
                  onTagClick={(tag) => setTabIndex(tags.indexOf(tag))}
                />
              ))}
            </VStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CurrencyPickerModal
