import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { Currency, WNATIVE, WNativeABI } from '@traderjoe-team/spruce-sdk'
import { WriteContractResult } from '@wagmi/core'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import { useCallback } from 'react'
import { capturePrepareContractWriteError } from 'utils/logger'
import {
  getCurrencyAddress,
  isWrappedNativeCurrency
} from 'utils/wrappedCurrency'
import { getAddress, parseEther } from 'viem'
import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi'

interface UseWrapUnwrapNativeCurrencyProps {
  amount?: string
  currency0?: Currency
  currency1?: Currency
  onSuccess?: () => void
}

const useWrapUnwrapNativeCurrency = ({
  amount,
  currency0,
  currency1,
  onSuccess
}: UseWrapUnwrapNativeCurrencyProps) => {
  const chainId = useChainId()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const isNativeInAndWrappedNativeOut =
    currency0 &&
    currency1 &&
    currency0.isNative &&
    isWrappedNativeCurrency(getCurrencyAddress(currency1), chainId)

  const isWrappedNativeInAndNativeOut =
    currency0 &&
    currency1 &&
    currency1.isNative &&
    isWrappedNativeCurrency(getCurrencyAddress(currency0), chainId)

  const parsedAmount = amount ? parseEther(amount as `${number}`) : undefined

  const { config: depositConfig } = usePrepareContractWrite({
    abi: WNativeABI,
    address: getAddress(WNATIVE[chainId].address),
    cacheTime: 0,
    enabled: (isNativeInAndWrappedNativeOut && !!parsedAmount) || false,
    functionName: 'deposit',
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    },
    value: parsedAmount ?? BigInt(0)
  })

  const { config: withdrawConfig } = usePrepareContractWrite({
    abi: WNativeABI,
    address: getAddress(WNATIVE[chainId].address),
    args: parsedAmount ? [parsedAmount] : undefined,
    cacheTime: 0,
    enabled: (isWrappedNativeInAndNativeOut && !!parsedAmount) || false,
    functionName: 'withdraw',
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const onWriteSuccess = useCallback(
    (data: WriteContractResult) => {
      const description = isNativeInAndWrappedNativeOut
        ? `Deposit ${currency0.symbol}`
        : `Withdraw ${currency0?.symbol}`
      addRecentTransaction({ description, hash: data.hash })
      addTransactionToast({ description, hash: data.hash })
    },
    [
      currency0,
      isNativeInAndWrappedNativeOut,
      addRecentTransaction,
      addTransactionToast
    ]
  )

  const {
    data: depositData,
    isLoading: isDepositLoading,
    write: deposit
  } = useContractWrite({
    ...depositConfig,
    onSuccess: onWriteSuccess
  })

  const {
    data: withdrawData,
    isLoading: isWithdrawLoading,
    write: withdraw
  } = useContractWrite({
    ...withdrawConfig,
    onSuccess: onWriteSuccess
  })

  const { isLoading: isWaitingForTransaction } = useWaitForTransaction({
    hash: isNativeInAndWrappedNativeOut
      ? depositData?.hash
      : withdrawData?.hash,
    onSuccess
  })

  return {
    isLoading:
      isWaitingForTransaction ||
      (isNativeInAndWrappedNativeOut ? isDepositLoading : isWithdrawLoading),
    state:
      parsedAmount === undefined
        ? undefined
        : isNativeInAndWrappedNativeOut
        ? 'Wrap'
        : isWrappedNativeInAndNativeOut
        ? 'Unwrap'
        : undefined,
    write: isNativeInAndWrappedNativeOut ? deposit : withdraw
  }
}

export default useWrapUnwrapNativeCurrency
