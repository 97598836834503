import {
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { UserIcon } from 'theme/icons'
import { WalletMenuEnum } from 'types/walletMenu'

import ActivityScreen from './ActivityScreen'
import ConnectedScreen from './ConnectedScreen'
import Settings from './SettingScreen'

interface WalletMenuProps {
  walletAddress: string
}

const WalletMenu = ({ walletAddress }: WalletMenuProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [walletMenuDisplay, setWalletMenuDisplay] = useState<WalletMenuEnum>(
    WalletMenuEnum.Default
  )

  const renderWalletMenu = () => {
    switch (walletMenuDisplay) {
      case WalletMenuEnum.Default:
        return (
          <ConnectedScreen
            walletAddress={walletAddress}
            setWalletMenuDisplay={setWalletMenuDisplay}
          />
        )
      case WalletMenuEnum.Settings:
        return <Settings setWalletMenuDisplay={setWalletMenuDisplay} />
      case WalletMenuEnum.Activity:
        return (
          <ActivityScreen
            walletAddress={walletAddress}
            setWalletMenuDisplay={setWalletMenuDisplay}
          />
        )
    }
  }

  return (
    <Popover
      isOpen={isOpen}
      onOpen={() => {
        setWalletMenuDisplay(WalletMenuEnum.Default)
        onOpen()
      }}
      onClose={onClose}
      trigger="hover"
      placement="bottom-end"
    >
      <PopoverTrigger>
        <Flex cursor="pointer" alignItems="center" rowGap={4}>
          <UserIcon />
        </Flex>
      </PopoverTrigger>
      <PopoverContent py={4} minW="450px" zIndex={5}>
        {renderWalletMenu()}
      </PopoverContent>
    </Popover>
  )
}

export default WalletMenu
