import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Box, Flex, Heading, IconButton, Text } from '@chakra-ui/react'
import { MIGRATED_POOL_URL_BY_ADDRESS } from 'constants/pool'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FarmTractorIcon } from 'theme/icons'
import { getPoolDetailV2Url } from 'utils/poolUrl'
import { getAddress } from 'viem'

interface MigratedPoolBannerProps {
  binStep: number
  pairAddress: string
  tokenX: string | undefined
  tokenY: string | undefined
}

const MigratedPoolBanner = ({
  binStep,
  pairAddress,
  tokenX,
  tokenY
}: MigratedPoolBannerProps) => {
  const chainId = useChainId()
  const navigate = useNavigate()

  const migratedPoolUrl: string =
    MIGRATED_POOL_URL_BY_ADDRESS[chainId][getAddress(pairAddress)] ??
    getPoolDetailV2Url('v21', binStep, chainId, tokenX, tokenY) // default to v21 with same tokens and bin step

  return (
    <Flex
      data-cy="migrated-pool-banner"
      w="full"
      backgroundColor="yellowBar"
      p={{ base: 4, md: 8 }}
      alignContent="center"
      justifyContent="center"
      flexDirection="column"
      mb={2}
    >
      <Flex alignItems="center">
        <FarmTractorIcon
          boxSize={{ base: '44px', md: '56px' }}
          marginRight={5}
          fill="joeDark.500"
        />
        <Box>
          <Heading size="md" textColor="joeDark.500">
            Migrate your liquidity
          </Heading>
          <Text size="md" textColor="joeDark.500">
            New pool has custom liquidity range and lower gas cost.
          </Text>
        </Box>
        <IconButton
          data-cy="migrated-pool-banner-button"
          variant="ghost"
          bg="joeLight.300"
          _hover={{ bg: 'joeLight.400' }}
          _active={{
            bg: 'joeLight.600'
          }}
          marginLeft="auto"
          size="md"
          boxSize={{ base: '44px', md: '56px' }}
          aria-label="go to pool v21 page"
          icon={
            <ArrowForwardIcon
              boxSize={{ base: '20px', md: '24px' }}
              textColor="joeDark.500"
            />
          }
          color="textSecondary"
          onClick={() => navigate(migratedPoolUrl)}
        />
      </Flex>
    </Flex>
  )
}

export default MigratedPoolBanner
