import { connectorsForWallets } from '@rainbow-me/rainbowkit'
import {
  coinbaseWallet,
  injectedWallet,
  ledgerWallet,
  metaMaskWallet
} from '@rainbow-me/rainbowkit/wallets'
import { ChainId } from '@traderjoe-team/spruce-sdk'
import { publicProvider } from '@wagmi/core/providers/public'
import { supportedChains } from 'constants/chains'
import store from 'state/store'
import { e2eTestsConfig, isRunningE2ETests } from 'utils/e2e'
import { createWalletClient, getAddress, http } from 'viem'
import { generatePrivateKey, privateKeyToAccount } from 'viem/accounts'
import { createConfig } from 'wagmi'
import { configureChains } from 'wagmi'
import { MockConnector } from 'wagmi/connectors/mock'
import { infuraProvider } from 'wagmi/providers/infura'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'

import { core } from './wallet'

const infuraApiKey = process.env.REACT_APP_INFURA_KEY ?? ''

const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [
      core({ chains: supportedChains }),
      coinbaseWallet({ appName: 'Trader Joe', chains: supportedChains }),
      metaMaskWallet({ chains: supportedChains }),
      ledgerWallet({ chains: supportedChains }),
      injectedWallet({ chains: supportedChains })
    ]
  }
])

// check if we're running end-to-end tests
if (isRunningE2ETests) {
  const testWalletAddress = localStorage.getItem('testWalletAddress')

  const testAccount = testWalletAddress
    ? getAddress(testWalletAddress)
    : privateKeyToAccount(generatePrivateKey()).address

  const testWalletClient = createWalletClient({
    account: testAccount,
    chain: e2eTestsConfig.chain,
    transport: http(e2eTestsConfig.rpcUrl)
  })

  const mockConnector = new MockConnector({
    chains: supportedChains,
    options: {
      chainId: e2eTestsConfig.chain.id,
      walletClient: testWalletClient
    }
  })

  mockConnector.connect({ chainId: e2eTestsConfig.chain.id })
}

const isDedicatedRPC = store.getState().settings.isDedicatedRPC

const { chains: wagmiChains, publicClient } = configureChains(
  supportedChains,
  isRunningE2ETests
    ? e2eTestsConfig.providers
    : isDedicatedRPC
    ? [
        infuraProvider({
          apiKey: infuraApiKey
        }),
        jsonRpcProvider({
          rpc: (chain) => {
            switch (chain.id) {
              case ChainId.FUJI:
                return {
                  http: `https://avalanche-fuji.infura.io/v3/${infuraApiKey}`
                }
              case ChainId.SPRUCE:
                return {
                  http: `https://subnets.avax.network/insti-1/testnet/rpc`
                }
            }
            return null
          }
        })
      ]
    : [publicProvider()],
  {
    batch: { multicall: true },
    // anvil is slow so we don't timeout requests
    stallTimeout: !isRunningE2ETests ? 3000 : undefined
  }
)

const wagmiConfig = createConfig({
  autoConnect: false,
  connectors: [...connectors()],
  publicClient
})

export const wagmi = {
  wagmiChains,
  wagmiConfig
}
