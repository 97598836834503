import {
  ChainId,
  Currency,
  CurrencyAmount,
  JSBI,
  Percent,
  Token,
  TokenAmount,
  WNATIVE
} from '@traderjoe-team/spruce-sdk'
import { TradeV2 } from '@traderjoe-team/spruce-sdk-v2'
import { parseUnits } from 'viem'

type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export const USDC: { [chainId in ChainId]: Token } = {
  [ChainId.FUJI]: new Token(
    ChainId.FUJI,
    '0xB6076C93701D6a07266c31066B298AeC6dd65c2d',
    6,
    'USDC',
    'USD Coin'
  ),
  [ChainId.SPRUCE]: new Token(
    ChainId.SPRUCE,
    '0xA771A8C08F56C351C7dcF5f4e4Fb9f1d702D27C8',
    18,
    'WUSDCf',
    'WUSDCf'
  )
}

export const WNATIVE_ONLY: ChainTokenList = {
  [ChainId.FUJI]: [WNATIVE[ChainId.FUJI]],
  [ChainId.SPRUCE]: [WNATIVE[ChainId.SPRUCE]]
}

export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WNATIVE_ONLY,
  [ChainId.FUJI]: [...WNATIVE_ONLY[ChainId.FUJI], USDC[ChainId.FUJI]]
}

export const tryParseAmount = (
  value?: string,
  currency?: Currency,
  parseZero?: boolean
): CurrencyAmount | undefined => {
  if (!value || !currency) {
    return undefined
  }
  try {
    const typedValueParsed = parseUnits(
      value as `${number}`,
      currency.decimals
    ).toString()
    if (typedValueParsed !== '0' || parseZero) {
      return currency instanceof Token
        ? new TokenAmount(currency, JSBI.BigInt(typedValueParsed))
        : CurrencyAmount.ether(currency.chainId, JSBI.BigInt(typedValueParsed))
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.error(`Failed to parse input amount: "${value}"`, error)
  }
  // necessary for all paths to return a value
  return undefined
}

export const getCurrencyAmount = (currency?: Currency, amount?: bigint) => {
  if (amount === undefined || !currency) return undefined
  return currency instanceof Token
    ? new TokenAmount(currency, JSBI.BigInt(amount.toString()))
    : CurrencyAmount.ether(currency.chainId, JSBI.BigInt(amount.toString()))
}

export enum Field {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT'
}

function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

export function computeSlippageAdjustedAmounts(
  trade: TradeV2 | undefined,
  allowedSlippage: number
): { [field in Field]?: CurrencyAmount } {
  const pct = basisPointsToPercent(allowedSlippage)
  return {
    [Field.INPUT]: trade?.maximumAmountIn(pct),
    [Field.OUTPUT]: trade?.minimumAmountOut(pct)
  }
}
