import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import {
  LIMIT_ORDER_MANAGER_ADDRESS,
  LimitOrderManagerABI
} from '@traderjoe-team/spruce-sdk-v2'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import {
  LimitOrder as DexbarnLimitOrder,
  OrderType as DexbarnOrderType
} from 'types/dexbarn'
import { OrderAction, OrderType } from 'types/limitOrder'
import { capturePrepareContractWriteError } from 'utils/logger'
import { getAddress } from 'viem'
import {
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi'

interface UseMakeOrderActionProps {
  limitOrder: DexbarnLimitOrder | undefined
  onTransactionSuccess: () => void
  orderAction: OrderAction
  enabled?: boolean
}

const useMakeOrderAction = ({
  enabled = true,
  limitOrder,
  onTransactionSuccess,
  orderAction
}: UseMakeOrderActionProps) => {
  const chainId = useChainId()
  const walletChainId = useNetwork().chain?.id

  const {
    binId,
    orderType,
    pairLbBinStep: lbBinStep,
    tokenOut
  } = limitOrder || {}
  const { tokenX, tokenY } = tokenOut || {}
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  // prepare contract write
  const args =
    tokenX?.tokenId && tokenY?.tokenId && lbBinStep && binId && orderType
      ? ([
          getAddress(tokenX.tokenId),
          getAddress(tokenY.tokenId),
          lbBinStep,
          orderType === DexbarnOrderType.BID ? OrderType.BID : OrderType.ASK,
          binId
        ] as const)
      : undefined

  const { config } = usePrepareContractWrite({
    abi: LimitOrderManagerABI,
    address: getAddress(LIMIT_ORDER_MANAGER_ADDRESS[chainId]),
    args,
    cacheTime: 0,
    enabled: enabled && !!args && chainId === walletChainId,
    functionName: orderAction,
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const { data, isLoading, write } = useContractWrite({
    ...config,
    onSuccess: (data) => {
      const description =
        orderAction === 'cancelOrder'
          ? 'Cancel limit order'
          : 'Claim limit order'
      addRecentTransaction({ description, hash: data.hash })
      addTransactionToast({ description, hash: data.hash })
    }
  })

  const { data: receipt } = useWaitForTransaction({
    hash: data?.hash,
    onSuccess: (data) => {
      if (data.status === 'success') onTransactionSuccess()
    }
  })

  return { isLoading, isSuccess: receipt?.status === 'success', write }
}

export default useMakeOrderAction
