import {
  Box,
  Center,
  Flex,
  Heading,
  SimpleGrid,
  Spinner,
  useToken
} from '@chakra-ui/react'
import { Currency } from '@traderjoe-team/spruce-sdk'
import AnalyticsChart from 'components/AnalyticsChart'
import AnalyticsStat from 'components/AnalyticsStat'
import BinsTradedChart from 'components/BinsTradedChart'
import ChartZoomButtons from 'components/ChartZoomButtons'
import LBPairDistributionChart from 'components/LBPairDistributionChart'
import {
  getRewardRates,
  getRewardTokens,
  POOL_DISTRIBUTION_CHART_RADIUS
} from 'constants/pool'
import useBinAnalytics from 'hooks/analytics/useBinAnalytics'
import useLBPairDetailAnalytics from 'hooks/analytics/useLBPairDetailAnalytics'
import useLBPairSwaps from 'hooks/pool/v2/useLBPairSwaps'
import useChainId from 'hooks/useChainId'
import React, { useState } from 'react'
import { formattedNum } from 'utils/format'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { formatUnits } from 'viem'

import FeesBpsChart from './FeesBpsChart'
import PoolInfoTable from './PoolInfoTable'
import PoolSwapsTable from './PoolSwapsTable'

interface PoolDetailV2AnalyticsPanelProps {
  currency0: Currency
  currency1: Currency
  isPriceRatioInversed: boolean
  isSelected: boolean
  rewardPriceUsdObj: { [key: string]: number }
  activeBinId?: number
  baseFee?: number
  lbPairAddress?: string
  liquidityDepthTokenX?: number
  liquidityDepthTokenY?: number
  reserveX?: bigint
  reserveY?: bigint
}

const PoolDetailV2AnalyticsPanel = ({
  activeBinId,
  baseFee,
  currency0,
  currency1,
  isPriceRatioInversed,
  isSelected,
  lbPairAddress,
  liquidityDepthTokenX,
  liquidityDepthTokenY,
  reserveX,
  reserveY,
  rewardPriceUsdObj
}: PoolDetailV2AnalyticsPanelProps) => {
  const chainId = useChainId()
  const [graphPurple] = useToken('colors', ['graphPurpleDark'])

  const tokenA = wrappedCurrency(currency0, chainId)
  const tokenB = wrappedCurrency(currency1, chainId)

  const { data, isLoading } = useLBPairDetailAnalytics({
    enabled: isSelected,
    lbPairAddress
  })

  const { data: swaps } = useLBPairSwaps({
    address: lbPairAddress,
    enabled: isSelected,
    first: 10
  })

  const [distributionRadius, setDistributionRadius] = useState<number>(
    POOL_DISTRIBUTION_CHART_RADIUS.initial
  )
  const { binsTraded24, poolDistributionData } = useBinAnalytics({
    activeBinId,
    binTradedRadius: 100,
    distributionRadius,
    enabled: isSelected,
    lbPairAddress,
    tokenA,
    tokenB
  })

  // get rewards APR
  const rewardRates = lbPairAddress
    ? getRewardRates(chainId, lbPairAddress)
    : []
  const rewardTokens = lbPairAddress
    ? getRewardTokens(chainId, lbPairAddress)
    : []

  const rewardPricesUSD =
    rewardRates && rewardTokens
      ? rewardTokens.map(
          (rewardToken) => rewardPriceUsdObj[rewardToken.toLowerCase()]
        )
      : undefined

  const rewardsPerDay =
    rewardPricesUSD && rewardRates
      ? rewardRates.reduce(
          (prev, currRewardRate, index) =>
            prev + currRewardRate * rewardPricesUSD[index],
          0
        )
      : 0
  const rewardsAPR = data ? (rewardsPerDay / data?.tvl) * 365 : 0

  if (isLoading) {
    return (
      <Center p={8}>
        <Spinner />
      </Center>
    )
  }

  if (!data) return null

  return (
    <Flex flexDir="column" gap={4}>
      <SimpleGrid columns={{ base: 2, md: 3 }} gap={4}>
        <AnalyticsStat
          title="Liquidity"
          value={formattedNum(data.tvl, { usd: true })}
          change={data.tvlDelta}
        />
        <AnalyticsStat
          title="Volume (24H)"
          value={formattedNum(data.volume24H, { usd: true })}
          change={data.volume24HDelta}
        />
        <AnalyticsStat
          title="Fees (24H)"
          value={formattedNum(data.fees24H, { usd: true })}
          change={data.fees24HDelta}
        />
        <AnalyticsStat title="APR (7D)" value={`${data.apr7D.toFixed(2)}%`} />
        {reserveX ? (
          <AnalyticsStat
            title={`${currency0.symbol} Reserves`}
            value={`${formattedNum(
              formatUnits(reserveX, currency0.decimals)
            )} ${currency0.symbol}`}
          />
        ) : null}
        {reserveY ? (
          <AnalyticsStat
            title={`${currency1.symbol} Reserves`}
            value={`${formattedNum(
              formatUnits(reserveY, currency1.decimals)
            )} ${currency1.symbol}`}
          />
        ) : null}
        {liquidityDepthTokenX ? (
          <AnalyticsStat
            title={`+2% depth`}
            value={`${formattedNum(liquidityDepthTokenX)} ${currency0.symbol}`}
          />
        ) : null}
        {liquidityDepthTokenY ? (
          <AnalyticsStat
            title={`-2% depth`}
            value={`${formattedNum(liquidityDepthTokenY)} ${currency1.symbol}`}
          />
        ) : null}
        {rewardsAPR ? (
          <AnalyticsStat
            title="Rewards APR (24H)"
            value={`${formattedNum(rewardsAPR * 100, { places: 2 })}%`}
          />
        ) : null}
      </SimpleGrid>
      <Box p={{ base: 4, md: 8 }} border="1px" borderColor="border">
        <PoolInfoTable lbPairAddress={lbPairAddress} />
      </Box>
      {activeBinId ? (
        <Box p={{ base: 4, md: 8 }} border="1px" borderColor="border">
          <LBPairDistributionChart
            title="POOL DISTRIBUTION"
            data={poolDistributionData}
            activeBinId={activeBinId}
            currency0={currency0}
            currency1={currency1}
            bottomRightElement={
              <ChartZoomButtons
                minValue={POOL_DISTRIBUTION_CHART_RADIUS.min}
                maxValue={POOL_DISTRIBUTION_CHART_RADIUS.max}
                value={distributionRadius}
                step={POOL_DISTRIBUTION_CHART_RADIUS.step}
                onChange={setDistributionRadius}
              />
            }
            isPriceRatioInversed={isPriceRatioInversed}
          />
        </Box>
      ) : null}
      {activeBinId && binsTraded24.length > 0 ? (
        <Box p={{ base: 4, md: 8 }} border="1px" borderColor="border">
          <BinsTradedChart
            isPriceRatioInversed={isPriceRatioInversed}
            activeBinId={activeBinId}
            binsTraded24={binsTraded24}
            currency0={currency0}
            currency1={currency1}
          />
        </Box>
      ) : null}
      <Box p={{ base: 4, md: 8 }} border="1px" borderColor="border">
        <AnalyticsChart
          id="tvl"
          data={data.tvlDatas}
          header={<Heading size="md">TVL (TOTAL LOCKED VALUE)</Heading>}
          h="220px"
          tooltipTitle="TVL"
          fill={graphPurple}
          stroke={graphPurple}
          showXAxis
          showYAxis
        />
      </Box>
      <Box p={{ base: 4, md: 8 }} border="1px" borderColor="border">
        <AnalyticsChart
          id="volume"
          data={data.volumeDatas}
          header={<Heading size="md">VOLUME (24H)</Heading>}
          h="220px"
          tooltipTitle="Volume"
          fill={graphPurple}
          stroke={graphPurple}
          showXAxis
          showYAxis
        />
      </Box>
      {baseFee ? (
        <Box p={{ base: 4, md: 8 }} border="1px" borderColor="border">
          <FeesBpsChart
            feesBpsHourDatas={data.feesBpsHourDatas}
            feesBpsDayDatas={data.feesBpsDayDatas}
            baseFee={baseFee}
          />
        </Box>
      ) : null}
      {swaps && (
        <Box p={{ base: 4, md: 8 }} border="1px" borderColor="border">
          <PoolSwapsTable
            swaps={swaps}
            currency0={currency0}
            currency1={currency1}
          />
        </Box>
      )}
    </Flex>
  )
}

export default PoolDetailV2AnalyticsPanel
