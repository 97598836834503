import { useMemo } from 'react'
import { useUserTransactionDeadline } from 'state/settings/hooks'

export const defaultTransactionDeadline = 30

// combines the current timestamp with the user setting to give the deadline that should be used for any submitted transaction
export default function useTransactionDeadline(): bigint | undefined {
  const { userTransactionDeadline } = useUserTransactionDeadline()
  return useMemo(() => {
    const ttl = userTransactionDeadline ?? defaultTransactionDeadline
    // computes current timestamp in seconds because it is used to check against the block's timestamp, which is in seconds
    const currentTimestamp = BigInt(
      Math.floor((new Date().getTime() + 100000) / 1000) // divide by 1000 to change timestamp to seconds and Math.floor() to round dow
    )
    return currentTimestamp && ttl
      ? currentTimestamp + BigInt(ttl * 60)
      : undefined
  }, [userTransactionDeadline])
}
