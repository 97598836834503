import {
  Box,
  Flex,
  Heading,
  Hide,
  Show,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack
} from '@chakra-ui/react'
import PageHelmet from 'components/PageHelmet'
import { POOL_HELMET_DESCRIPTION, POOL_HELMET_TITLE } from 'constants/pool'
import usePoolSearchParams from 'hooks/pool/usePoolSearchParams'
import { PoolSortMethod } from 'hooks/pool/useSortedPools'
import usePoolsV2 from 'hooks/pool/v2/usePoolsV2'
import usePairsSearch from 'hooks/usePairsSearch'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { PoolStatus } from './constants'
import PoolMainAnalytics from './PoolMainAnalytics'
import PoolsMobileList from './PoolsMobileList'
import PoolsTable from './PoolsTable'
import UserPositionsTable from './UserPositions/UserPositionsTable'

const Pool = () => {
  const location = useLocation()
  const { selectedTab, setSelectedTab } = usePoolSearchParams()

  const [poolsStatus, setPoolsStatus] = useState<PoolStatus>('main')
  const [sortMethod, setSortMethod] = useState<PoolSortMethod>(
    PoolSortMethod.VOLUME
  )

  // fetch pools v2
  const pageSize = 25
  const {
    data: poolsV2,
    fetchNextPage: fetchNextPagePoolsV2,
    hasNextPage: hasNextPagePoolsV2,
    isFetchingNextPage: isFetchingNextPagePoolsV2,
    isLoading: isLoadingPoolsV2
  } = usePoolsV2({
    orderBy: sortMethod === PoolSortMethod.LIQUIDITY ? 'liquidity' : 'volume',
    pageSize,
    status: poolsStatus
  })

  // pagination state
  const isFetchingNextPoolsPage = isFetchingNextPagePoolsV2
  const hasNextPage = hasNextPagePoolsV2

  // load more callback
  const onLoadMoreClick = useCallback(() => {
    fetchNextPagePoolsV2()
  }, [fetchNextPagePoolsV2])

  // search
  const [query, setQuery] = useState<string>('')
  const { data: searchedPools, isLoading: isLoadingSearch } = usePairsSearch({
    query,
    status: poolsStatus
  })

  // calculate pools to display
  const pools = useMemo(() => {
    if (query.length > 0) {
      return searchedPools
    }
    return poolsV2
  }, [poolsV2, query, searchedPools])

  // loading state
  const isLoadingPools = isLoadingPoolsV2 || isLoadingSearch

  // reset query when chain changes
  useEffect(() => {
    setQuery('')
  }, [])

  return (
    <Box pt={{ base: 6, md: 24 }} bg="bgPrimary">
      <Box maxW={{ '2xl': '1600px', base: '1400px' }} margin="0 auto">
        <PageHelmet
          title={POOL_HELMET_TITLE}
          description={POOL_HELMET_DESCRIPTION}
          url={location.pathname}
        />
        <Flex px={4} align="center" justify="space-between">
          <VStack pb={6} spacing={0} align="flex-start">
            <Heading>Pools</Heading>
          </VStack>
        </Flex>
        <PoolMainAnalytics />
      </Box>
      <Tabs
        variant="lb-pro-line"
        index={selectedTab}
        onChange={(index) => setSelectedTab(index)}
      >
        <TabList
          maxW={{ '2xl': 'calc(1600px - 32px)', base: 'calc(1400px - 32px)' }}
          margin="0 auto"
          borderBottom="1px solid"
          borderColor="border"
          overflowX="auto"
          overflowY="hidden"
        >
          <Tab flexShrink={0}>Pools</Tab>
          <Tab flexShrink={0}>My Pools</Tab>
        </TabList>
        <TabPanels minH="60vh" pb={{ base: 4, md: 24 }}>
          <TabPanel maxW={{ '2xl': '1600px', base: '1400px' }} margin="0 auto">
            <Hide below="md">
              <PoolsTable
                isFetchingPage={isFetchingNextPoolsPage}
                pools={pools}
                onPoolsStatusChange={setPoolsStatus}
                selectedPoolStatus={poolsStatus}
                isLoadingPools={isLoadingPools}
                rewardPriceUsdObj={{}}
                onLoadMoreClick={onLoadMoreClick}
                hasNextPage={hasNextPage}
                query={query}
                onQueryChange={setQuery}
                sortMethod={sortMethod}
                setSortMethod={setSortMethod}
              />
            </Hide>
            <Show below="md">
              <PoolsMobileList
                pools={pools}
                onPoolsStatusChange={setPoolsStatus}
                selectedPoolStatus={poolsStatus}
                isLoadingPools={isLoadingPools}
                rewardPriceUsdObj={{}}
                isFetchingPage={isFetchingNextPoolsPage}
                onLoadMoreClick={onLoadMoreClick}
                hasNextPage={hasNextPage}
                query={query}
                onQueryChange={setQuery}
                sortMethod={sortMethod}
                setSortMethod={setSortMethod}
              />
            </Show>
          </TabPanel>
          <TabPanel maxW={{ '2xl': '1600px', base: '1400px' }} margin="0 auto">
            <UserPositionsTable />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default Pool
