import {
  Center,
  Flex,
  Hide,
  HStack,
  Image,
  useColorMode
} from '@chakra-ui/react'
import LBProLogoDark from 'assets/lb-pro-dark.svg'
import LBProLogoLight from 'assets/lb-pro-light.svg'
import ConnectButton from 'components/ConnectButton'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { Link } from 'react-router-dom'
import { getChainSlug } from 'utils/chains'

import HeaderLink from './HeaderLink'

const Header = (): JSX.Element => {
  const chainId = useChainId()
  const { colorMode } = useColorMode()
  const isDark = colorMode === 'dark' ? true : false

  return (
    <Flex
      zIndex={11}
      position="fixed"
      alignItems="center"
      justifyContent="space-between"
      width="full"
      top={0}
      h="80px"
      bg="white"
      _dark={{ bg: 'joeDark.700' }}
      borderBottom="1px"
      borderBottomColor={'border'}
      padding={{
        base: '1rem 0.25rem 1rem 1rem',
        md: '1rem 2rem',
        xs: '1rem'
      }}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        w="full"
        maxW="1600px"
        margin="0 auto"
        px={4}
        gap={4}
      >
        <Flex gap={6} flexShrink={0}>
          <Center
            as={Link}
            aria-label="Link to Home page"
            to={`/${getChainSlug(chainId)}`}
            flexShrink={0}
          >
            <Image
              objectFit="contain"
              fallbackSrc={isDark ? LBProLogoLight : LBProLogoDark}
            />
          </Center>
        </Flex>
        <Hide below="lg">
          <HStack spacing={4} flexShrink={0}>
            <HeaderLink
              aria-label="Link to Trade page"
              to="trade"
              title="Trade"
            />
            <HeaderLink aria-label="Link to Pool page" to="pool" title="Pool" />
          </HStack>
        </Hide>
        <Hide below="lg">
          <Flex gap={4} flexShrink={0}>
            <ConnectButton />
          </Flex>
        </Hide>
      </Flex>
    </Flex>
  )
}

export default Header
