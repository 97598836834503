import { createAction } from '@reduxjs/toolkit'

import { SlippageType } from './reducer'

export const setChainId = createAction<number>('settings/chainId')

export const setSlippage = createAction<{ type: SlippageType; value: number }>(
  'settings/slippage'
)

export const setTransactionDeadline = createAction<number | undefined>(
  'settings/transactionDeadline'
)

export const setIsSafeModeEnabled = createAction<boolean>(
  'settings/isSafeModeEnabled'
)

export const setIsDedicatedRPC = createAction<boolean>(
  'settings/isDedicatedRPC'
)

export const setIsRemoveLiquidityInNativeCurrencyEnabled =
  createAction<boolean>('settings/isRemoveLiquidityInNativeCurrencyEnabled')
