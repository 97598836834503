import { ChainId } from '@traderjoe-team/spruce-sdk'
import { getAddress } from 'viem'
import { useAccount, useBalance } from 'wagmi'

import useActiveChain from './useActiveChain'

interface UseTokenBalanceProps {
  enabled?: boolean
  token?: string
}

export const useTokenBalance = ({ enabled, token }: UseTokenBalanceProps) => {
  const { address: account } = useAccount()
  const chain = useActiveChain()
  return useBalance({
    address: account,
    chainId: chain.id,
    enabled: enabled ? enabled : !!account,
    token: token ? getAddress(token) : undefined
  })
}

export const useNativeBalance = (chainId?: ChainId) => {
  const { address: account } = useAccount()
  const result = useBalance({ address: account, chainId, enabled: !!chainId })
  return {
    ...result,
    balance: result.data?.value
  }
}
