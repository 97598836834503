import { Box, SimpleGrid, useDisclosure } from '@chakra-ui/react'
import ConnectButton from 'components/ConnectButton'
import { IconHeaderLink } from 'components/Header/HeaderLink'
import MobileSearchPanel from 'components/MobileSearchPanel'
import React from 'react'
import { PoolIcon, TradeIcon } from 'theme/icons'

const MobileBottomTabBar = () => {
  const { isOpen, onClose } = useDisclosure()
  return (
    <>
      <Box
        pos="fixed"
        zIndex={5}
        bottom={0}
        bg="bgPrimary"
        w="full"
        borderTop="1px"
        borderColor="border"
      >
        <SimpleGrid columns={3} p={2}>
          <IconHeaderLink aria-label="Link to Trade page" to="trade">
            <TradeIcon boxSize={6} fill="textPrimary" />
          </IconHeaderLink>
          <IconHeaderLink aria-label="Link to Pool page" to="pool">
            <PoolIcon boxSize={6} fill="textPrimary" />
          </IconHeaderLink>
          <ConnectButton />
        </SimpleGrid>
      </Box>
      <MobileSearchPanel isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export default MobileBottomTabBar
