import { ChainId } from '@traderjoe-team/spruce-sdk'

export interface Announcement {
  description: string
  durationMillis: number | null
  id: number
  imageUrl: string | null
  title: string
}

export type Chain = 'avalanche' | 'binance' | 'arbitrum' | 'spruce'

export interface Token {
  chain: Chain
  decimals: number
  name: string
  pctChange: number
  priceNative: number
  priceUsd: number
  reserve: number
  symbol: string
  tokenAddress: string
  volume: number
}

interface BaseToken {
  address: string
  decimals: number
  name: string
  symbol: string
}

type PoolStatus = 'main' | 'old'
type PoolVersion = 'v2.0' | 'v2.1'

export interface Pool {
  chain: Chain
  feesUsd: number
  lbBaseFeePct: number
  lbBinStep: number
  lbMaxFeePct: number
  liquidityDepthMinus: number
  liquidityDepthPlus: number
  liquidityDepthTokenX: number
  liquidityDepthTokenY: number
  liquidityUsd: number
  name: string
  pairAddress: string
  protocolSharePct: number | null
  reserveX: number
  reserveY: number
  status: PoolStatus
  tokenX: BaseToken
  tokenY: BaseToken
  version: PoolVersion
  volumeUsd: number
}

interface UserLBPositionToken extends BaseToken {
  amount: number
  amountRaw: string
  priceUsd: number
}

export interface UserLBPosition {
  chain: string
  lbBaseFeePct: number
  lbBinStep: number
  lbMaxFeePct: number
  liquidity: number
  pairName: string
  poolAddress: string
  status: PoolStatus
  tokenX: UserLBPositionToken
  tokenY: UserLBPositionToken
  version: PoolVersion
}

export interface UserLBBinPosition extends UserLBPosition {
  binId: number
}

export namespace PoolQueryParam {
  export type FilterBy = '1h' | '1d' | '7d' | '30d'
  export type OrderBy = 'liquidity' | 'volume' | 'name'
  export type Status = 'main' | 'old' | 'all'
}

export interface BinAnalyticsData {
  binId: number
  feesNative: number
  feesUsd: number
  priceXY: number
  priceYX: number
  reserveX: number
  reserveXRaw: string
  reserveY: number
  reserveYRaw: string
  totalSupply: string
  volumeNative: number
  volumeUsd: number
  volumeX: number
  volumeY: number
}

export interface UserFeesIntervalData {
  accruedFeesL: number
  accruedFeesX: number
  accruedFeesY: number
  date: Date
  timestamp: number
}

export interface UserFeesBinData {
  accruedFeesL: number
  accruedFeesX: number
  accruedFeesY: number
  binId: number
  mostRecentDepositTime: Date
  priceXY: number
  priceYX: number
  timestamp: number
}

export interface SwapEvent {
  amountIn: number
  amountOut: number
  binId: number
  blockNumber: number
  logIndex: number
  pairId: string
  priceXY: number
  priceYX: number
  swapForY: boolean
  timestamp: string
  transactionHash: string
  userId: string
}

export interface Vault {
  address: string
  apr1d: number
  chain: Chain
  chainId: ChainId
  feesUsd: number
  name: string
  pair: {
    address: string
    baseFeePct: number
    binStep: number
    version: PoolVersion
  }
  strategy: {
    address: string
    aumAnnualFeePct: number
  }
  tokenX: {
    address: string
    decimals: number
    symbol: string
  }
  tokenY: {
    address: string
    decimals: number
    symbol: string
  }
  tvlUsd: number
}

export interface VaultQueuedWithdrawal {
  chain: Chain
  round: number
  shares: number
  userAddress: string
  vaultAddress: string
}

export enum OrderEventType {
  CANCELLED = 'cancelled',
  CLAIMED = 'claimed',
  EXECUTED = 'executed',
  PLACED = 'placed'
}

export enum OrderType {
  ASK = 'ask',
  BID = 'bid'
}

interface OrderToken {
  amount: number
  amountRaw: string
  decimals: number
  name: string
  symbol: string
  tokenId: string
}

export interface LimitOrder {
  binId: number
  chain: string
  orderPlacedTimestamp: Date
  orderType: OrderType
  orderUpdatedTimestamp: Date
  pairId: string
  pairLbBinStep: number
  pairName: string
  positionId: number
  status: OrderEventType
  tokenIn: OrderToken
  tokenOut: {
    tokenX: OrderToken
    tokenY: OrderToken
  }
}

export interface VaultTvlHistoryData {
  amountX: number
  amountXUsd: number
  amountY: number
  amountYUsd: number
  date: string
  timestamp: number
  tvlUsd: number
}
