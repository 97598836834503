import {
  LB_FACTORY_V21_ADDRESS,
  LBFactoryV21ABI
} from '@traderjoe-team/spruce-sdk-v2'
import useChainId from 'hooks/useChainId'
import { getAddress } from 'viem'
import { useContractRead } from 'wagmi'

interface UseGetAllLBPairsProps {
  tokenXAddress?: string
  tokenYAddress?: string
}

const useGetAllLBPairs = ({
  tokenXAddress,
  tokenYAddress
}: UseGetAllLBPairsProps) => {
  const chainId = useChainId()
  return useContractRead({
    abi: LBFactoryV21ABI,
    address: getAddress(LB_FACTORY_V21_ADDRESS[chainId]),
    args:
      tokenXAddress && tokenYAddress
        ? [getAddress(tokenXAddress), getAddress(tokenYAddress)]
        : undefined,
    chainId,
    enabled: !!tokenXAddress && !!tokenYAddress,
    functionName: 'getAllLBPairs'
  })
}

export default useGetAllLBPairs
