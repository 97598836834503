import { Flex, SimpleGrid, Stat, StatLabel, StatNumber } from '@chakra-ui/react'
import CurrencyLogo from 'components/CurrencyLogo'
import { getRewardRates, getRewardTokens } from 'constants/pool'
import usePoolV2 from 'hooks/pool/v2/usePoolV2'
import useChainId from 'hooks/useChainId'
import useSdkCurrencies from 'hooks/useSdkCurrencies'
import React from 'react'
import { formattedNum } from 'utils/format'
import { wrappedCurrency } from 'utils/wrappedCurrency'

interface RewardingDetailsProps {
  lbPairAddress: string
  rewardPriceUsdObj: { [key: string]: number }
}

const RewardingDetails = ({
  lbPairAddress,
  rewardPriceUsdObj
}: RewardingDetailsProps) => {
  const chainId = useChainId()

  const { data: pool } = usePoolV2({ address: lbPairAddress }) || {}
  const { liquidityUsd } = pool || {}

  const rewardRates = getRewardRates(chainId, lbPairAddress)
  const rewardTokens = getRewardTokens(chainId, lbPairAddress)

  const { tokens: sdkCurrencies } = useSdkCurrencies({
    addresses: rewardTokens || []
  })
  const sdkTokens = sdkCurrencies.map((sdkCurrency) =>
    wrappedCurrency(sdkCurrency, chainId)
  )

  const rewardPricesUSD =
    rewardRates && rewardTokens
      ? rewardTokens.map(
          (rewardToken) => rewardPriceUsdObj[rewardToken.toLowerCase()]
        )
      : undefined

  const rewardsPerDay =
    rewardPricesUSD && rewardRates
      ? rewardRates.reduce(
          (prev, currRewardRate, index) =>
            prev + currRewardRate * rewardPricesUSD[index],
          0
        )
      : 0

  const rewardsAPR = liquidityUsd ? (rewardsPerDay / liquidityUsd) * 365 : 0

  return (
    <SimpleGrid columns={{ base: 2, lg: 2, md: 3, sm: 3, xl: 3 }} gap={2}>
      <Stat>
        <StatLabel>{`Rewarded Token${
          rewardTokens && rewardTokens.length > 1 ? 's' : ''
        }`}</StatLabel>
        <Flex mt={1} flexWrap="wrap">
          {sdkTokens.map((sdkToken, i) => (
            <Flex flexDir="row" gap={1} mr={4} key={i} alignItems="center">
              <CurrencyLogo
                address={sdkToken?.address}
                symbol={sdkToken?.symbol}
                boxSize={5}
              />
              <StatNumber fontSize={20}>{sdkToken?.symbol}</StatNumber>
            </Flex>
          ))}
        </Flex>
      </Stat>

      <Stat>
        <StatLabel>Rewards per day</StatLabel>
        <Flex mt={1} flexWrap="wrap">
          {rewardRates?.map((rewardRate, index) => (
            <StatNumber key={rewardRate} mr={4} fontSize={20}>
              {`${formattedNum(Number(rewardRate))} ${
                sdkTokens[index]?.symbol
              }`}
            </StatNumber>
          ))}
        </Flex>
      </Stat>

      <Stat sx={{ display: 'flex' }}>
        <StatLabel>Rewards APR (24H)</StatLabel>
        <StatNumber fontSize={20}>
          {`${formattedNum(rewardsAPR * 100, { places: 2 })}%`}
        </StatNumber>
      </Stat>
    </SimpleGrid>
  )
}

export default RewardingDetails
