import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import {
  LIMIT_ORDER_MANAGER_ADDRESS,
  LimitOrderManagerABI
} from '@traderjoe-team/spruce-sdk-v2'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import { useMemo } from 'react'
import {
  LimitOrder as DexbarnLimitOrder,
  OrderType as DexbarnOrderType
} from 'types/dexbarn'
import { BatchOrdersAction, OrderType } from 'types/limitOrder'
import { capturePrepareContractWriteError } from 'utils/logger'
import { getAddress } from 'viem'
import {
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi'

interface UseBatchMakeOrdersActionProps {
  batchOrdersAction: BatchOrdersAction
  limitOrders: DexbarnLimitOrder[] | undefined
  enabled?: boolean
  onSuccess?: () => void
}

const useBatchMakeOrdersAction = ({
  batchOrdersAction,
  enabled = true,
  limitOrders,
  onSuccess
}: UseBatchMakeOrdersActionProps) => {
  const chainId = useChainId()
  const walletChainId = useNetwork().chain?.id

  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  // prepare contract write
  const args = useMemo(
    () =>
      limitOrders && limitOrders?.length > 0
        ? ([
            limitOrders.map(
              ({
                binId,
                orderType,
                pairLbBinStep: binStep,
                tokenOut: { tokenX, tokenY }
              }) => ({
                binId: binId,
                binStep: binStep,
                orderType:
                  orderType === DexbarnOrderType.BID
                    ? OrderType.BID
                    : OrderType.ASK,
                tokenX: getAddress(tokenX.tokenId),
                tokenY: getAddress(tokenY.tokenId)
              })
            )
          ] as const)
        : undefined,
    [limitOrders]
  )

  const { config } = usePrepareContractWrite({
    abi: LimitOrderManagerABI,
    address: getAddress(LIMIT_ORDER_MANAGER_ADDRESS[chainId]),
    args,
    cacheTime: 0,
    enabled: enabled && !!args && chainId === walletChainId,
    functionName: batchOrdersAction,
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const { data, isLoading, write } = useContractWrite({
    ...config,
    onSuccess: (data) => {
      const description =
        batchOrdersAction === 'batchCancelOrders'
          ? 'Cancel limit orders'
          : 'Claim limit orders'
      addRecentTransaction({ description, hash: data.hash })
      addTransactionToast({ description, hash: data.hash })
    }
  })

  const { isLoading: isWaitingForTransaction } = useWaitForTransaction({
    hash: data?.hash,
    onSuccess
  })

  return { isLoading: isLoading || isWaitingForTransaction, write }
}

export default useBatchMakeOrdersAction
