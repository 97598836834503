import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { LBPairABI } from '@traderjoe-team/spruce-sdk-v2'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import debounce from 'lodash.debounce'
import { useMemo } from 'react'
import { getConfigWithGasLimitIncreased } from 'utils/gas'
import { capturePrepareContractWriteError } from 'utils/logger'
import { getAddress } from 'viem'
import {
  useAccount,
  useContractRead,
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi'

interface UseLBPairClaimFeesProps {
  lbPairAddress?: string
  poolName?: string
  userPositions?: number[]
}

const useLBPairClaimFees = ({
  lbPairAddress,
  poolName,
  userPositions
}: UseLBPairClaimFeesProps) => {
  const { address: account } = useAccount()
  const chainId = useChainId()
  const walletChainId = useNetwork().chain?.id
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const userBins = useMemo(
    () =>
      userPositions ? userPositions.map((position) => BigInt(position)) : [],
    [userPositions]
  )

  const { data: pendingFees, refetch: refetchPendingFees } = useContractRead({
    abi: LBPairABI,
    address: lbPairAddress ? getAddress(lbPairAddress) : undefined,
    args: account && userBins ? [account, userBins] : undefined,
    chainId,
    enabled: !!account,
    functionName: 'pendingFees'
  })

  const { config } = usePrepareContractWrite({
    abi: LBPairABI,
    address: lbPairAddress ? getAddress(lbPairAddress) : undefined,
    args: account && userBins ? [account, userBins] : undefined,
    cacheTime: 0,
    chainId,
    enabled: !!account && chainId === walletChainId,
    functionName: 'collectFees',
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const { data, isLoading, write } = useContractWrite({
    ...getConfigWithGasLimitIncreased({ config, percentageIncrease: 10 }),
    onSuccess: (data) => {
      const description = `Claim fees for ${poolName} pool`
      addRecentTransaction({ description, hash: data.hash })
      addTransactionToast({ description, hash: data.hash })
    }
  })

  const debouncedRefetchPendingFees = debounce(() => refetchPendingFees(), 4000)
  const { isLoading: isWaitingForTransaction } = useWaitForTransaction({
    hash: data?.hash,
    onSuccess: (receipt) => {
      if (receipt.status === 'success') debouncedRefetchPendingFees()
    }
  })

  return {
    claimFees: write,
    isLoading: isLoading || isWaitingForTransaction,
    pendingFees
  }
}

export default useLBPairClaimFees
