import { Box, useColorMode } from '@chakra-ui/react'
import {
  ChartingLibraryWidgetOptions,
  ResolutionString,
  widget
} from 'charting_library'
import React, { useEffect, useRef } from 'react'

import Datafeed from './datafeed'

const TradingViewChart = () => {
  const chartContainerRef = useRef<HTMLDivElement | null>(null)
  const { colorMode } = useColorMode()
  const isDark = colorMode === 'dark'

  useEffect(() => {
    if (chartContainerRef.current === null) return

    const widgetOptions: ChartingLibraryWidgetOptions = {
      autosize: true,
      charts_storage_api_version: '1.1',
      charts_storage_url: 'https://saveload.tradingview.com',
      client_id: 'traderjoexyz.com',
      container: chartContainerRef.current,
      datafeed: Datafeed,
      debug: process.env.NODE_ENV === 'development' ? true : false,
      disabled_features: ['use_localstorage_for_settings', 'popup_hints'],
      enabled_features: ['study_templates'],
      fullscreen: false,
      interval: 'D' as ResolutionString,
      library_path: '/charting_library/',
      locale: 'en',
      studies_overrides: {},
      symbol: 'Bitfinex:BTC/USDT',
      theme: isDark ? 'dark' : 'light'
    }

    const tvWidget = new widget(widgetOptions)

    return () => {
      tvWidget.remove()
    }
  }, [isDark])

  return (
    <Box
      ref={chartContainerRef}
      className="TradingViewChart"
      width="full"
      height="full"
    />
  )
}

export default TradingViewChart
