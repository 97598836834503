import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Box, Flex, HStack, Link, Td, Text, Tr } from '@chakra-ui/react'
import { ChainId } from '@traderjoe-team/spruce-sdk'
import { STRATEGIES_INFO } from 'constants/vaults'
import useActiveChain from 'hooks/useActiveChain'
import React from 'react'
import { AchievementIcon } from 'theme/icons'
import { LeaderboardRowProps } from 'types/leaderboard'
import { shortenAddress } from 'utils/addresses'
import { formattedNum } from 'utils/format'

const LeaderboardRow = ({
  accruedFeesUsd,
  currency0,
  currency1,
  currentBinCount,
  currentPositionTokenX,
  currentPositionTokenY,
  position,
  shareVolumeTradedUsd,
  userAddress
}: LeaderboardRowProps) => {
  const { blockExplorers, id: chainId } = useActiveChain()

  const autoPoolName = STRATEGIES_INFO[chainId as ChainId][userAddress]?.name
  const userName = autoPoolName ?? shortenAddress(userAddress, 6)

  const renderTrophy = (position: number | undefined) => {
    switch (position) {
      case 1:
        return <AchievementIcon fill="yellow.400" />
      case 2:
        return <AchievementIcon fill="gray.400" />
      case 3:
        return <AchievementIcon fill="yellow.600" />
      default:
        return null
    }
  }

  return (
    <Tr role="group">
      <Td>{position}</Td>
      <Td>
        <HStack>
          {renderTrophy(position)}
          <Link
            aria-label="Link to user address on explorer"
            isExternal
            href={`${blockExplorers?.default.url}/address/${userAddress}`}
          >
            <Flex flexDir="row" alignItems="center" columnGap={2}>
              <Box>{userName}</Box>
              <ExternalLinkIcon />
            </Flex>
          </Link>
        </HStack>
      </Td>
      <Td>
        <Flex columnGap={3} justifyItems="start">
          <Text>
            {formattedNum(currentPositionTokenX)}
            <Text as="span" fontWeight="normal">
              {' '}
              {currency0?.symbol}
            </Text>
          </Text>
          <Text>
            {formattedNum(currentPositionTokenY)}
            <Text as="span" fontWeight="normal">
              {' '}
              {currency1?.symbol}
            </Text>
          </Text>
          <Text>
            {currentBinCount}
            <Text as="span" fontWeight="normal">
              {' '}
              bins
            </Text>
          </Text>
        </Flex>
      </Td>
      <Td textAlign="right" fontWeight="normal">
        {formattedNum(shareVolumeTradedUsd, { usd: true })}
      </Td>
      <Td textAlign="right" fontWeight={400}>
        {formattedNum(accruedFeesUsd, { usd: true })}
      </Td>
    </Tr>
  )
}

export default LeaderboardRow
