import { useOutsideClick } from '@chakra-ui/react'
import SearchPanel from 'components/SearchPanel'
import usePairsSearch from 'hooks/usePairsSearch'
import React, { useState } from 'react'

import SearchResults from './SearchResults'

interface HeaderSearchPanelProps {
  toggleSearch: () => void
}

const HeaderSearchPanel = ({ toggleSearch }: HeaderSearchPanelProps) => {
  const [query, setQuery] = useState('')
  const [focused, setFocused] = useState(false)
  const { data: pairs, isLoading } = usePairsSearch({ enabled: focused, query })

  const ref = React.useRef<HTMLDivElement | null>(null)
  useOutsideClick({
    handler: () => {
      setFocused(false)
      toggleSearch()
    },
    ref: ref
  })

  return (
    <SearchPanel
      searchPanelRef={ref}
      placeholder="Search by symbol or name"
      query={query}
      setQuery={setQuery}
      queryMinLength={2}
      focused={focused}
      setFocused={setFocused}
      searchResults={
        <SearchResults
          pairs={pairs}
          isLoading={isLoading}
          onRowClick={() => {
            setQuery('')
            setFocused(false)
          }}
        />
      }
    />
  )
}

export default HeaderSearchPanel
