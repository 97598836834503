import { TradeFee, TradeV2 } from '@traderjoe-team/spruce-sdk-v2'
import { useEffect, useState } from 'react'

const useTradeFee = (trade: TradeV2 | undefined) => {
  const [tradeFee, setTradeFee] = useState<TradeFee | undefined>()

  useEffect(() => {
    const fetchFee = async () => {
      const fee = await trade?.getTradeFee()
      setTradeFee(fee)
    }
    fetchFee()
  }, [trade])

  return tradeFee
}

export default useTradeFee
