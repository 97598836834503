import { Grid, Heading, VStack } from '@chakra-ui/react'
import { Currency } from '@traderjoe-team/spruce-sdk'
import {
  LB_ROUTER_V21_ADDRESS,
  LiquidityDistribution
} from '@traderjoe-team/spruce-sdk-v2'
import { FetchBalanceResult } from '@wagmi/core'
import ApproveTokenButton from 'components/ApproveTokenButton'
import CurrencyInput from 'components/CurrencyInput'
import LBPairDistributionChart from 'components/LBPairDistributionChart'
import MaxButton from 'components/MaxButton'
import Web3Button from 'components/Web3Button'
import useAddLiquidityV2, {
  UseAddLiquidityV2Props
} from 'hooks/pool/v2/useAddLiquidityV2'
import useApproveSpenderIfNeeded from 'hooks/useApproveSpenderIfNeeded'
import useChainId from 'hooks/useChainId'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import useDebounce from 'hooks/useDebounce'
import React, { useCallback, useMemo, useState } from 'react'
import { LBPoolVersion } from 'types/pool'
import { getAddUniformLiquidityBatches } from 'utils/getAddUniformLiquidityBatches'
import { getMaxBinPerAddLiquidityBatch } from 'utils/getMaxBinPerBatch'
import { poolAddLiquidity, poolSelectDistributionShape } from 'utils/measure'
import {
  convertDistributionParamsToLiquidityDistribution,
  getAddLiquidityDistributionParams
} from 'utils/poolV2'
import { getCurrencyAddress, wrappedCurrency } from 'utils/wrappedCurrency'
import { useAccount } from 'wagmi'

import AddUniformLiquidityPanel from './AddUniformLiquidityPanel'
import LiquidityShapePicker from './LiquidityShapePicker'
import PriceRangeSelection from './PriceRangeSelection'

// default distribution option
const DEFAULT_DISTRIBUTION = LiquidityDistribution.SPOT

// calculate default bin range
const getDefaultBinRange = (
  dist: LiquidityDistribution,
  activeBinId: number | undefined
) => {
  return dist === LiquidityDistribution.SPOT && activeBinId
    ? [activeBinId - 12, activeBinId + 13]
    : undefined
}

interface AddLiquidityPanelV2Props {
  currencyPrice0: number | undefined
  currencyPrice1: number | undefined
  lbPoolVersion: LBPoolVersion
  onAddLiquiditySuccess: () => void
  activeBinId?: number
  balance0?: FetchBalanceResult
  balance1?: FetchBalanceResult
  binStep?: string
  currency0?: Currency
  currency1?: Currency
  inversePriceRatios?: boolean
  togglePriceRatiosClicked?: () => void
}

const AddLiquidityPanelV2 = ({
  activeBinId,
  balance0,
  balance1,
  binStep,
  currency0,
  currency1,
  currencyPrice0,
  currencyPrice1,
  inversePriceRatios,
  lbPoolVersion,
  onAddLiquiditySuccess,
  togglePriceRatiosClicked
}: AddLiquidityPanelV2Props) => {
  const chainId = useChainId()
  const { isConnected } = useAccount()

  const poolName = `${currency0?.symbol}-${currency1?.symbol}-${binStep}`

  const {
    amount: amount0,
    amountBN: amount0BN,
    setAmount: setAmount0
  } = useCurrencyInputAmount({ currency: currency0 })
  const {
    amount: amount1,
    amountBN: amount1BN,
    setAmount: setAmount1
  } = useCurrencyInputAmount({ currency: currency1 })

  const [distribution, setDistribution] = useState<LiquidityDistribution>(
    LiquidityDistribution.SPOT
  )

  // bin range
  const defaultBinRange = useMemo(
    () => getDefaultBinRange(DEFAULT_DISTRIBUTION, activeBinId),
    [activeBinId]
  )
  const [_binRange, setBinRange] = useState<number[] | undefined>()
  const binRange = useMemo(
    () => _binRange ?? defaultBinRange,
    [_binRange, defaultBinRange]
  )
  const debouncedBinRange = useDebounce(binRange, 1000)

  const isToken0Disabled = useMemo(
    () =>
      activeBinId && debouncedBinRange
        ? debouncedBinRange[1] < activeBinId
        : false,
    [activeBinId, debouncedBinRange]
  )

  const isToken1Disabled = useMemo(
    () =>
      activeBinId && debouncedBinRange
        ? activeBinId < debouncedBinRange[0]
        : false,
    [activeBinId, debouncedBinRange]
  )

  const tokenAddress0 = getCurrencyAddress(currency0)
  const tokenAddress1 = getCurrencyAddress(currency1)

  const isExceedingBalance0 = balance0
    ? Number(balance0.formatted) < Number(amount0)
    : false
  const isExceedingBalance1 = balance1
    ? Number(balance1.formatted) < Number(amount1)
    : false

  const isAddLiquidityDisabled = isExceedingBalance0 || isExceedingBalance1
  const isV21 = lbPoolVersion === 'v21'
  const isCustomRangeEnabled = isV21

  const isPriceRangeEnabled =
    activeBinId &&
    currency0 &&
    currency1 &&
    binStep &&
    binRange &&
    (isCustomRangeEnabled || distribution === LiquidityDistribution.SPOT)

  const lbRouterAddress = LB_ROUTER_V21_ADDRESS[chainId]

  const {
    approvalType: approvalType0,
    approve: approveToken0,
    isApproved: isToken0Approved,
    isApproving: isApprovingToken0,
    setApprovalType: setApprovalType0
  } = useApproveSpenderIfNeeded({
    amount: amount0BN,
    spender: lbRouterAddress,
    token: tokenAddress0,
    tokenSymbol: currency0?.symbol
  })

  const {
    approvalType: approvalType1,
    approve: approveToken1,
    isApproved: isToken1Approved,
    isApproving: isApprovingToken1,
    setApprovalType: setApprovalType1
  } = useApproveSpenderIfNeeded({
    amount: amount1BN,
    spender: lbRouterAddress,
    token: tokenAddress1,
    tokenSymbol: currency1?.symbol
  })

  // reset inputs on add liquidity success
  const onSuccess = useCallback(() => {
    setAmount0('')
    setAmount1('')
    onAddLiquiditySuccess()
  }, [setAmount0, setAmount1, onAddLiquiditySuccess])

  const [binPerBatch, setBinPerBatch] = useState<number>(
    getMaxBinPerAddLiquidityBatch(chainId)
  )

  const distributionParams = useMemo(
    () =>
      getAddLiquidityDistributionParams({
        activeBinId,
        amount0: amount0BN,
        amount1: amount1BN,
        binRange: debouncedBinRange,
        currency0,
        currency1,
        liquidityDistribution: distribution
      }),
    [
      activeBinId,
      amount0BN,
      amount1BN,
      debouncedBinRange,
      currency0,
      currency1,
      distribution
    ]
  )

  const addUniformLiquidityBatches = getAddUniformLiquidityBatches(
    binPerBatch,
    debouncedBinRange,
    activeBinId,
    currency0,
    currency1,
    amount0BN,
    amount1BN,
    distributionParams
  )

  const isActiveBinTargetAdd =
    debouncedBinRange?.length === 2 &&
    debouncedBinRange[0] === debouncedBinRange[1] &&
    debouncedBinRange[0] === activeBinId

  const isSingleBinDepositWithToken0 =
    amount0BN && activeBinId && debouncedBinRange
      ? debouncedBinRange[1] <= activeBinId && amount0BN >= 0
      : false
  const isSingleBinDepositWithToken1 =
    amount1BN && activeBinId && debouncedBinRange
      ? activeBinId <= debouncedBinRange[0] && amount1BN >= 0
      : false

  const validAmount0 =
    (amount0BN && amount0BN > BigInt(0)) ||
    (amount0BN && isActiveBinTargetAdd) ||
    isToken0Disabled ||
    isSingleBinDepositWithToken0
  const validAmount1 =
    (amount1BN && amount1BN > BigInt(0)) ||
    (amount1BN && isActiveBinTargetAdd) ||
    isToken1Disabled ||
    isSingleBinDepositWithToken1
  const validAmounts = validAmount0 && validAmount1

  const addLiquidityProps: UseAddLiquidityV2Props = {
    activeBinId: activeBinId ? BigInt(activeBinId) : undefined,
    amount0: amount0BN,
    amount1: amount1BN,
    binStep,
    currency0,
    currency1,
    distributionParams,
    enabled:
      validAmounts &&
      !isExceedingBalance0 &&
      !isExceedingBalance1 &&
      (isToken0Approved === true || !tokenAddress0) &&
      (isToken1Approved === true || !tokenAddress1) &&
      !!addUniformLiquidityBatches
  }

  const { addLiquidity, isLoading: isAddingLiquidity } = useAddLiquidityV2({
    ...addLiquidityProps,
    onSuccess
  })

  const simulatedLiquidityDistribution = useMemo(
    () =>
      convertDistributionParamsToLiquidityDistribution({
        activeBinId,
        binStep,
        distributionParams,
        token0: wrappedCurrency(currency0, chainId),
        token1: wrappedCurrency(currency1, chainId),
        totalAmount0: amount0BN,
        totalAmount1: amount1BN
      }),
    [
      distributionParams,
      activeBinId,
      amount0BN,
      amount1BN,
      currency0,
      currency1,
      binStep,
      chainId
    ]
  )

  const hintText = useMemo(() => {
    if (!validAmount0) {
      return `Enter ${currency0?.symbol} amount`
    }
    if (!validAmount1) {
      return `Enter ${currency1?.symbol} amount`
    }
    if (isExceedingBalance0 || isExceedingBalance1) {
      return 'Not enough balance'
    }
    return undefined
  }, [
    currency0,
    currency1,
    isExceedingBalance0,
    isExceedingBalance1,
    validAmount0,
    validAmount1
  ])

  const onBinRangeChange = useCallback(
    (range: number[]) => {
      if (activeBinId && range && range[1] < activeBinId) {
        setAmount0('0')
      }
      if (activeBinId && range && activeBinId < range[0]) {
        setAmount1('0')
      }
      setBinRange(range)
    },
    [setBinRange, activeBinId, setAmount0, setAmount1]
  )

  return (
    <VStack align="flex-start" spacing={12}>
      <VStack align="flex-start" w="full">
        <Heading size="md">DEPOSIT LIQUIDITY</Heading>
        <Grid w="full" templateColumns="1fr" gap={2} alignItems="flex-start">
          <CurrencyInput
            data-cy="add-liquidity-currency-input-0"
            currency={currency0}
            currencyAddress={tokenAddress0}
            value={amount0}
            onValueChange={setAmount0}
            balance={balance0?.formatted}
            error={
              isExceedingBalance0
                ? `Not enough ${currency0?.symbol}`
                : undefined
            }
            isDisabled={isToken0Disabled}
            rightElement={
              balance0 ? (
                <MaxButton
                  balance={balance0.formatted}
                  isDisabled={isToken0Disabled}
                  onClick={() => setAmount0(balance0.formatted)}
                />
              ) : undefined
            }
          />
          <CurrencyInput
            data-cy="add-liquidity-currency-input-1"
            currency={currency1}
            currencyAddress={tokenAddress1}
            value={amount1}
            onValueChange={setAmount1}
            balance={balance1?.formatted}
            error={
              isExceedingBalance1
                ? `Not enough ${currency1?.symbol}`
                : undefined
            }
            isDisabled={isToken1Disabled}
            rightElement={
              balance1 ? (
                <MaxButton
                  balance={balance1.formatted}
                  isDisabled={isToken1Disabled}
                  onClick={() => setAmount1(balance1.formatted)}
                />
              ) : undefined
            }
          />
        </Grid>
      </VStack>
      <LiquidityShapePicker
        distribution={distribution}
        onDistributionChange={(dist) => {
          setDistribution(dist)
          poolSelectDistributionShape(dist.toString())
          if (!isCustomRangeEnabled) {
            setBinRange(getDefaultBinRange(dist, activeBinId))
          }
        }}
      />
      {isPriceRangeEnabled ? (
        <PriceRangeSelection
          currencyPrice0={currencyPrice0}
          currencyPrice1={currencyPrice1}
          currency0={currency0}
          currency1={currency1}
          binStep={Number(binStep)}
          activeBinId={activeBinId}
          binRange={binRange}
          onBinRangeChange={onBinRangeChange}
          inversePriceRatios={inversePriceRatios}
          togglePriceRatiosClick={togglePriceRatiosClicked}
          resetBinRange={() => {
            setBinRange(
              getDefaultBinRange(LiquidityDistribution.SPOT, activeBinId)
            )
          }}
        />
      ) : null}
      {isCustomRangeEnabled &&
      simulatedLiquidityDistribution &&
      activeBinId &&
      !isAddLiquidityDisabled &&
      validAmounts ? (
        <LBPairDistributionChart
          currency0={currency0}
          currency1={currency1}
          activeBinId={activeBinId}
          data={simulatedLiquidityDistribution}
          isPriceRatioInversed={inversePriceRatios}
          title="SIMULATED LIQUIDITY DISTRIBUTION"
        />
      ) : null}
      <VStack w="full" spacing={4}>
        {!isToken0Approved &&
        !isToken0Disabled &&
        approveToken0 &&
        currency0 &&
        !isAddLiquidityDisabled ? (
          <ApproveTokenButton
            data-cy="add-liquidity-approve-button-0"
            amount={amount0}
            currencySymbol={currency0.symbol}
            approvalType={approvalType0}
            onApprovalTypeSelect={setApprovalType0}
            isLoading={isApprovingToken0}
            onClick={() => approveToken0()}
          >
            {`Approve ${currency0.symbol}`}
          </ApproveTokenButton>
        ) : null}
        {!isToken1Approved &&
        !isToken1Disabled &&
        approveToken1 &&
        currency1 &&
        !isAddLiquidityDisabled ? (
          <ApproveTokenButton
            data-cy="add-liquidity-approve-button-1"
            amount={amount1}
            currencySymbol={currency1.symbol}
            approvalType={approvalType1}
            onApprovalTypeSelect={setApprovalType1}
            isLoading={isApprovingToken1}
            onClick={() => approveToken1()}
          >
            {`Approve ${currency1.symbol}`}
          </ApproveTokenButton>
        ) : null}
        {binStep &&
        binRange &&
        currency0 &&
        currency1 &&
        addUniformLiquidityBatches &&
        addUniformLiquidityBatches.length > 1 &&
        isConnected ? (
          <AddUniformLiquidityPanel
            batches={addUniformLiquidityBatches}
            currency0={currency0}
            currency1={currency1}
            binRange={binRange}
            binStep={Number(binStep)}
            addLiquidityProps={addLiquidityProps}
            inversePriceRatios={inversePriceRatios}
            binPerBatch={binPerBatch}
            onAddLiquidityPrepareContractWriteError={() =>
              setBinPerBatch((old) => Math.max(40, old - 20))
            }
          />
        ) : (
          <Web3Button
            data-cy="add-liquidity-button"
            variant="primary"
            colorScheme="accent"
            bg="proAccent"
            size="xl"
            w="full"
            borderRadius="0"
            isDisabled={isAddLiquidityDisabled || !addLiquidity}
            isLoading={isAddingLiquidity}
            loadingText="Adding Liquidity"
            onClick={() => {
              addLiquidity?.()
              poolAddLiquidity(poolName)
            }}
          >
            {hintText ?? 'Add Liquidity'}
          </Web3Button>
        )}
      </VStack>
    </VStack>
  )
}

export default AddLiquidityPanelV2
