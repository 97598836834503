import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { FeesPeriod } from 'pages/PoolDetailV2/UserEarnedFees'
import { UserFeesBinData, UserFeesIntervalData } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'

interface UseUserEarnedFeesProps {
  feesPeriod: FeesPeriod
  lbPairAddress?: string
  owner?: string
}

interface LastRefreshedTimestamp {
  timestamp: number
}

const useUserEarnedFees = ({
  feesPeriod,
  lbPairAddress,
  owner
}: UseUserEarnedFeesProps) => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)

  const now = dayjs()
  const yesterday = now.subtract(1, 'day')
  const week = now.subtract(7, 'day')
  const month = now.subtract(30, 'day')

  const urlParams =
    feesPeriod === FeesPeriod.DAY
      ? `startTime=${yesterday.unix()}&aggregateBy=hourly`
      : feesPeriod === FeesPeriod.WEEK
      ? `startTime=${week.unix()}&aggregateBy=daily`
      : `startTime=${month.unix()}&aggregateBy=daily`

  const fetchUserEarnedFeesAnalytics = useDexbarnGet<UserFeesIntervalData[]>(
    `/v1/user/fees/analytics/${chain}/${lbPairAddress?.toLowerCase()}/${owner?.toLowerCase()}?${urlParams}`
  )

  const { data: userFeesIntervalData, isFetching: isFetchingIntervalData } =
    useQuery<UserFeesIntervalData[]>(
      ['UserEarnedFees', chain, owner, lbPairAddress, feesPeriod],
      () => fetchUserEarnedFeesAnalytics(),
      { enabled: !!lbPairAddress && !!owner, keepPreviousData: true }
    )

  const fetchUserEarnedFeesPerBin = useDexbarnGet<UserFeesBinData[]>(
    `/v1/user/fees-earned/${chain}/${owner?.toLowerCase()}/${lbPairAddress?.toLowerCase()}`
  )

  const { data: userFeesDataBins, isFetching: isFetchinBins } = useQuery<
    UserFeesBinData[]
  >(
    ['UserEarnedFeesPerBin', chain, owner, lbPairAddress],
    () => fetchUserEarnedFeesPerBin(),
    { enabled: !!lbPairAddress && !!owner, keepPreviousData: true }
  )

  const fetchLastRefreshedTimestamp = useDexbarnGet<LastRefreshedTimestamp>(
    `v1/user/fees/last-refreshed/${chain}/${lbPairAddress?.toLowerCase()}`
  )

  const { data: lastRefreshedTimestamp, isFetching: isFetchingRefreshedTime } =
    useQuery<LastRefreshedTimestamp>(
      ['UserEarnedFeesRefreshedTimestamp', chain, lbPairAddress],
      () => fetchLastRefreshedTimestamp(),
      { enabled: !!lbPairAddress, keepPreviousData: true }
    )

  return {
    isFetchinBins,
    isFetchingIntervalData,
    isFetchingRefreshedTime,
    lastRefreshedTimestamp,
    userFeesDataBins: userFeesDataBins ?? [],
    userFeesIntervalData: userFeesIntervalData ?? []
  }
}

export default useUserEarnedFees
