import { Currency } from '@traderjoe-team/spruce-sdk'
import { Bin } from '@traderjoe-team/spruce-sdk-v2'

export const getPriceFromBinId = (
  binId: number,
  binStep: number,
  currencyDecimals0: number,
  currencyDecimals1: number,
  places = 2
) => {
  const price = Bin.getPriceFromId(binId, binStep)
  const priceInYDecimals =
    (price * 10 ** currencyDecimals0) / 10 ** currencyDecimals1
  return priceInYDecimals.toFixed(places)
}

export const getBinIdFromPrice = (
  price: string,
  binStep: number,
  currency0: Currency,
  currency1: Currency
) => {
  return Bin.getIdFromPrice(
    (parseFloat(price) * 10 ** currency1.decimals) / 10 ** currency0.decimals,
    binStep
  )
}

export function getBinPctRange(binStep: number, numBins: number) {
  const binStepsInBps = binStep * 0.0001
  const rawValue = (1 + binStepsInBps) ** numBins
  const percentage = rawValue - 1 === -1 ? 1 : rawValue - 1
  return `${(percentage * 100).toFixed(2)}%`
}
