import {
  Box,
  Flex,
  FlexProps,
  Heading,
  Hide,
  HStack,
  Tab,
  TabList,
  Tabs,
  Tag,
  Text
} from '@chakra-ui/react'
import BackButton from 'components/BackButton'
import ContractLink from 'components/ContractLink'
import CurrencyLogo from 'components/CurrencyLogo'
import HotkeyTooltipButton from 'components/HotkeyTooltipButton'
import SlippageSettingsPicker, {
  SlippageSettingsPickerType
} from 'components/SlippageSettingsPicker'
import useChainId from 'hooks/useChainId'
import useKeyPress from 'hooks/useKeyPress'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getChainSlug } from 'utils/chains'
import { formattedNum } from 'utils/format'

interface PoolDetailHeaderProps {
  activeBinPrice?: string
  address?: string
  inversePriceRatios?: boolean
  isRewarded?: boolean
  onInversePriceRatiosClick?: () => void
  settingsBottom?: number
  showHotkeyTooltip?: boolean
  slippageSettingsPickerType?: SlippageSettingsPickerType
  tag?: string
  token0?: { address?: string; symbol?: string }
  token1?: { address?: string; symbol?: string }
}

const PoolDetailHeader = ({
  activeBinPrice,
  address,
  inversePriceRatios,
  isRewarded,
  onInversePriceRatiosClick,
  settingsBottom = 0,
  showHotkeyTooltip,
  slippageSettingsPickerType,
  tag,
  token0,
  token1,
  ...props
}: PoolDetailHeaderProps & FlexProps) => {
  const chainId = useChainId()
  const navigate = useNavigate()

  const [priceDecimals, setPriceDecimals] = useState(5)
  useKeyPress({
    onUp: () => setPriceDecimals((prev) => (prev === 8 ? 5 : 8)),
    targetKey: 'g'
  })

  return (
    <Flex
      maxW={{ '2xl': '1600px', base: '1400px' }}
      margin="0 auto"
      flexDir="column"
      align="flex-start"
      gap={2}
      pos="relative"
      h={{ base: 'fit-content', md: '130px' }}
      {...props}
    >
      <Flex
        zIndex={1}
        gap={2}
        flexDir={{ base: 'column', md: 'row' }}
        align={{ base: 'flex-start', md: 'center' }}
        w="full"
      >
        <HStack>
          <BackButton
            mr={4}
            onClick={() => navigate(`/${getChainSlug(chainId)}/pool`)}
          />
          <HStack>
            <CurrencyLogo
              address={token0?.address}
              symbol={token0?.symbol}
              boxSize={7}
            />
            <Heading size="lg">{token0?.symbol ?? token0?.address}</Heading>
          </HStack>
          <Heading size="lg">/</Heading>
          <HStack>
            <CurrencyLogo
              address={token1?.address}
              symbol={token1?.symbol ?? token1?.address}
              boxSize={7}
            />
            <Heading size="lg">{token1?.symbol ?? token1?.address}</Heading>
          </HStack>
        </HStack>
        <Flex
          flexWrap="wrap"
          gap={2}
          pl={{ base: 0, md: 4 }}
          mb={{ base: 2, md: 0 }}
        >
          {tag ? (
            <Tag variant="outline" borderRadius="0" borderColor="border">
              {tag}
            </Tag>
          ) : null}
          {isRewarded ? (
            <Tag variant="outline" borderRadius="0" borderColor="border">
              Rewards
            </Tag>
          ) : null}
          <Tag variant="outline" borderRadius="0" borderColor="border">
            <ContractLink color="textPrimary" address={address} />
          </Tag>
        </Flex>
        <Flex ml={{ base: 'none', md: 'auto' }} columnGap={6}>
          {activeBinPrice ? (
            <Box>
              <Text fontSize="sm" textColor="textSecondary">
                Current Price:
              </Text>
              <Text fontWeight="semibold">{`1 ${
                inversePriceRatios ? token1?.symbol : token0?.symbol
              } = ${formattedNum(activeBinPrice, { places: priceDecimals })} ${
                inversePriceRatios ? token0?.symbol : token1?.symbol
              }`}</Text>
            </Box>
          ) : null}
          {onInversePriceRatiosClick ? (
            <Tabs
              isManual
              variant="lb-pro"
              index={inversePriceRatios ? 1 : 0}
              onChange={onInversePriceRatiosClick}
            >
              <TabList>
                <Tab>{token0?.symbol}</Tab>
                <Tab>{token1?.symbol}</Tab>
              </TabList>
            </Tabs>
          ) : null}
        </Flex>
      </Flex>
      <Flex
        pos={{ base: 'relative', md: 'absolute' }}
        bottom={{ base: 0, md: settingsBottom }}
        mt={{ base: 2, md: 0 }}
        alignSelf={{ base: 'flex-start', md: 'flex-end' }}
        justifyContent={{ base: 'space-between', md: 'flex-start' }}
        alignItems="center"
        zIndex={4}
        gap={{ base: 2, md: 4 }}
        w={{ base: 'full', md: 'auto' }}
        pt={{ base: 0, md: 4 }}
      >
        <HStack spacing={{ base: 0 }}>
          {slippageSettingsPickerType ? (
            <SlippageSettingsPicker
              type={slippageSettingsPickerType}
              iconButtonProps={{
                _dark: { bg: 'joeDark.700' },
                'aria-label': 'open settings',
                bg: 'white'
              }}
            />
          ) : null}
          {showHotkeyTooltip ? (
            <Hide below="md">
              <HotkeyTooltipButton />
            </Hide>
          ) : null}
        </HStack>
      </Flex>
    </Flex>
  )
}

export default PoolDetailHeader
