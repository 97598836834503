import { Button, ButtonProps } from '@chakra-ui/react'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { supportedChains } from 'constants/chains'
import useChainFromUrlParam from 'hooks/useChainFromUrlParam'
import useSwitchNetwork from 'hooks/useSwitchNetwork'
import React from 'react'
import { useSelectedChainId } from 'state/settings/hooks'
import { useAccount, useNetwork } from 'wagmi'

const Web3Button = (props: ButtonProps) => {
  const { isConnected } = useAccount()
  const { chain } = useNetwork()
  const { openConnectModal } = useConnectModal()

  const { switchNetwork } = useSwitchNetwork()

  const { selectedChainId } = useSelectedChainId()
  const selectedChain = supportedChains.filter(
    (chain) => chain.id === selectedChainId
  )[0]

  const chainInUrlParam = useChainFromUrlParam()
  const targetChain = chainInUrlParam ?? selectedChain

  const currentChainId = chain?.unsupported ? selectedChain.id : chain?.id

  return isConnected &&
    (chain?.unsupported ||
      (chainInUrlParam && chainInUrlParam.id !== currentChainId)) ? (
    <Button
      {...props}
      borderRadius="0"
      leftIcon={undefined}
      rightIcon={undefined}
      isDisabled={false}
      onClick={() => switchNetwork?.(targetChain.id)}
    >
      {`Switch to ${targetChain.name}`}
    </Button>
  ) : isConnected ? (
    <Button {...props} borderRadius="0" />
  ) : (
    <Button
      {...props}
      borderRadius="0"
      isDisabled={false}
      onClick={openConnectModal}
    >
      Connect Wallet
    </Button>
  )
}

export default Web3Button
