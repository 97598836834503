import { ChainId, CNATIVE } from '@traderjoe-team/spruce-sdk'
import { LBPool, LBPoolVersion, Pool } from 'types/pool'

import { getChainSlug } from './chains'
import { isWrappedNativeCurrency } from './wrappedCurrency'

export const getPoolDetailV1Url = (
  chainId: ChainId,
  token0?: string,
  token1?: string
) => {
  const nativeCurrency = CNATIVE.onChain(chainId)
  return `/${getChainSlug(chainId)}/pool/v1/${
    isWrappedNativeCurrency(token0, chainId) ? nativeCurrency.symbol : token0
  }/${
    isWrappedNativeCurrency(token1, chainId) ? nativeCurrency.symbol : token1
  }`
}

export const getPoolDetailV2Url = (
  lbPoolVersion: LBPoolVersion,
  binStep: number,
  chainId: ChainId,
  token0?: string,
  token1?: string
) => {
  const nativeCurrency = CNATIVE.onChain(chainId)
  return `/${getChainSlug(chainId)}/pool/${lbPoolVersion}/${
    isWrappedNativeCurrency(token0, chainId) ? nativeCurrency.symbol : token0
  }/${
    isWrappedNativeCurrency(token1, chainId) ? nativeCurrency.symbol : token1
  }/${binStep}`
}

export const getPoolDetailUrl = (
  pool: Pool | LBPool,
  chainId: ChainId
): string => {
  if ('lbBinStep' in pool) {
    return getPoolDetailV2Url(
      pool.lbPoolVersion,
      pool.lbBinStep,
      chainId,
      pool.tokenX.address,
      pool.tokenY.address
    )
  }
  return getPoolDetailV1Url(chainId, pool.tokenX.address, pool.tokenY.address)
}
