import { generateSymbol, makeApiRequest, parseFullSymbol } from './helpers'

// DatafeedConfiguration implementation
const configurationData = {
  // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
  exchanges: [
    { desc: 'Bitfinex', name: 'Bitfinex', value: 'Bitfinex' },
    { desc: 'Kraken bitcoin exchange', name: 'Kraken', value: 'Kraken' }
  ],

  // Represents the resolutions for bars supported by your datafeed
  supported_resolutions: ['1D', '1W', '1M'],
  // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
  symbols_types: [{ name: 'crypto', value: 'crypto' }]
}

// Obtains all symbols for all exchanges supported by CryptoCompare API
async function getAllSymbols() {
  const data = await makeApiRequest('data/v3/all/exchanges')
  let allSymbols = [] as any

  for (const exchange of configurationData.exchanges) {
    const pairs = data.Data[exchange.value].pairs

    for (const leftPairPart of Object.keys(pairs)) {
      const symbols = pairs[leftPairPart].map((rightPairPart: any) => {
        const symbol = generateSymbol(
          exchange.value,
          leftPairPart,
          rightPairPart
        )
        return {
          description: symbol.short,
          exchange: exchange.value,
          full_name: symbol.full,
          symbol: symbol.short,
          type: 'crypto'
        }
      })
      allSymbols = [...allSymbols, ...symbols]
    }
  }
  return allSymbols
}

export default {
  getBars: async (
    symbolInfo: any,
    resolution: any,
    periodParams: any,
    onHistoryCallback: any,
    onErrorCallback: any
  ) => {
    const { from, to } = periodParams
    console.log('[getBars]: Method call', symbolInfo, resolution, from, to)
    const parsedSymbol: any = parseFullSymbol(symbolInfo.full_name)
    const urlParameters = {
      e: parsedSymbol.exchange,
      fsym: parsedSymbol.fromSymbol,
      limit: 2000,
      toTs: to,
      tsym: parsedSymbol.toSymbol
    } as any
    const query = Object.keys(urlParameters)
      .map((name) => `${name}=${encodeURIComponent(urlParameters[name])}`)
      .join('&')
    try {
      const data = await makeApiRequest(`data/histoday?${query}`)
      if (
        (data.Response && data.Response === 'Error') ||
        data.Data.length === 0
      ) {
        // "noData" should be set if there is no data in the requested period
        onHistoryCallback([], { noData: true })
        return
      }
      let bars: any = []
      data.Data.forEach((bar: any) => {
        if (bar.time >= from && bar.time < to) {
          bars = [
            ...bars,
            {
              close: bar.close,
              high: bar.high,
              low: bar.low,
              open: bar.open,
              time: bar.time * 1000
            }
          ]
        }
      })
      console.log(`[getBars]: returned ${bars.length} bar(s)`)
      onHistoryCallback(bars, { noData: false })
    } catch (error) {
      console.log('[getBars]: Get error', error)
      onErrorCallback(error)
    }
  },

  onReady: (callback: any) => {
    console.log('[onReady]: Method call')
    setTimeout(() => callback(configurationData))
  },

  resolveSymbol: async (
    symbolName: string,
    onSymbolResolvedCallback: any,
    onResolveErrorCallback: any
  ) => {
    console.log('[resolveSymbol]: Method call', symbolName)
    const symbols = await getAllSymbols()
    const symbolItem = symbols.find(
      ({ full_name }: { full_name: string }) => full_name === symbolName
    )
    if (!symbolItem) {
      console.log('[resolveSymbol]: Cannot resolve symbol', symbolName)
      onResolveErrorCallback('Cannot resolve symbol')
      return
    }
    // Symbol information object
    const symbolInfo = {
      data_status: 'streaming',
      description: symbolItem.description,
      exchange: symbolItem.exchange,
      has_intraday: false,
      has_weekly_and_monthly: false,
      minmov: 1,
      name: symbolItem.symbol,
      pricescale: 100,
      session: '24x7',
      supported_resolutions: configurationData.supported_resolutions,
      ticker: symbolItem.full_name,
      timezone: 'Etc/UTC',
      type: symbolItem.type,
      visible_plots_set: 'ohlc',
      volume_precision: 2
    }
    console.log('[resolveSymbol]: Symbol resolved', symbolName)
    onSymbolResolvedCallback(symbolInfo)
  },

  searchSymbols: async (
    userInput: any,
    exchange: any,
    symbolType: any,
    onResultReadyCallback: any
  ) => {
    console.log('[searchSymbols]: Method call')
    const symbols = await getAllSymbols()
    const newSymbols = symbols.filter((symbol: any) => {
      const isExchangeValid = exchange === '' || symbol.exchange === exchange
      const isFullSymbolContainsInput =
        symbol.full_name.toLowerCase().indexOf(userInput.toLowerCase()) !== -1
      return isExchangeValid && isFullSymbolContainsInput
    })
    onResultReadyCallback(newSymbols)
  },

  subscribeBars: (
    symbolInfo: any,
    resolution: any,
    onRealtimeCallback: any,
    subscriberUID: any
  ) => {
    console.log(
      '[subscribeBars]: Method call with subscriberUID:',
      subscriberUID
    )
  },
  unsubscribeBars: (subscriberUID: any) => {
    console.log(
      '[unsubscribeBars]: Method call with subscriberUID:',
      subscriberUID
    )
  }
}
