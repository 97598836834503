import { createReducer } from '@reduxjs/toolkit'
import { defaultChain } from 'constants/chains'

import {
  setChainId,
  setIsDedicatedRPC,
  setIsRemoveLiquidityInNativeCurrencyEnabled,
  setIsSafeModeEnabled,
  setSlippage,
  setTransactionDeadline
} from './actions'

export enum SlippageType {
  LIQUIDITY_AMOUNT_V2 = 'liquidityAmountV2',
  LIQUIDITY_PRICE_V2 = 'liquidityPriceV2',
  SWAP_V2 = 'swapV2',
  V1 = 'v1'
}

type SlippageSettings = { [type in SlippageType]: number }

interface SettingsState {
  readonly chainId: number
  readonly isDedicatedRPC: boolean
  readonly isRemoveLiquidityInNativeCurrencyEnabled: boolean
  readonly isSafeModeEnabled: boolean
  readonly slippageSettings: SlippageSettings
  readonly transactionDeadline?: number
}

export const initialSlippageSettings: SlippageSettings = {
  [SlippageType.V1]: 0.5,
  [SlippageType.SWAP_V2]: 0.05,
  [SlippageType.LIQUIDITY_AMOUNT_V2]: 0.1,
  [SlippageType.LIQUIDITY_PRICE_V2]: 0.5
}

const initialState: SettingsState = {
  // Default chain for first time users
  chainId: defaultChain.id,
  isDedicatedRPC: true,
  isRemoveLiquidityInNativeCurrencyEnabled: true,
  isSafeModeEnabled: true,
  slippageSettings: initialSlippageSettings,
  transactionDeadline: undefined
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setChainId, (state, action) => {
      state.chainId = action.payload
    })
    .addCase(setSlippage, (state, action) => {
      state.slippageSettings[action.payload.type] = action.payload.value
    })
    .addCase(setTransactionDeadline, (state, action) => {
      state.transactionDeadline = action.payload
    })
    .addCase(setIsSafeModeEnabled, (state, action) => {
      state.isSafeModeEnabled = action.payload
    })
    .addCase(setIsDedicatedRPC, (state, action) => {
      state.isDedicatedRPC = action.payload
    })
    .addCase(setIsRemoveLiquidityInNativeCurrencyEnabled, (state, action) => {
      state.isRemoveLiquidityInNativeCurrencyEnabled = action.payload
    })
    .addDefaultCase((state) => {
      if (state.isRemoveLiquidityInNativeCurrencyEnabled === undefined) {
        state.isRemoveLiquidityInNativeCurrencyEnabled =
          initialState.isRemoveLiquidityInNativeCurrencyEnabled
      }
    })
)
