import metatagImage from 'assets/metatagimage_1x.webp'
import React from 'react'
import { Helmet } from 'react-helmet'

interface PageHelmetProps {
  url: string
  description?: string
  title?: string
}

const PageHelmet = ({ description, title, url }: PageHelmetProps) => (
  <Helmet>
    <title>
      {title ?? 'LB Pro | Decentralized Exchange for Institutions.'}
    </title>
    <meta
      name="description"
      content={
        description ??
        'Enterprise grade Decentralized Exchange. DeFi experience for compliant institutions. Powered by Liquidity Book Protocol. '
      }
    />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={`https://app.lb-pro.com/${url}`} />
    <meta
      property="og:title"
      content={title ?? 'LB Pro | Decentralized Exchange for Institutions.'}
    />
    <meta
      property="og:description"
      content={
        description ??
        'Enterprise grade Decentralized Exchange. DeFi experience for compliant institutions. Powered by Liquidity Book Protocol. '
      }
    />
    <meta property="og:image" content={metatagImage} />
    <meta property="twitter:card" content={metatagImage} />
    <meta property="twitter:url" content={`https://app.lb-pro.com/${url}`} />
    <meta
      property="twitter:title"
      content={title ?? 'LB Pro | Decentralized Exchange for Institutions.'}
    />
    <meta
      property="twitter:description"
      content={
        description ??
        'Enterprise grade Decentralized Exchange. DeFi experience for compliant institutions. Powered by Liquidity Book Protocol. '
      }
    />
    <meta property="twitter:image" content={metatagImage} />
  </Helmet>
)

export default PageHelmet
