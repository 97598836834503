import { useQuery } from '@tanstack/react-query'
import {
  LIQUIDITY_AMOUNTS_HELPER_ADDRESS,
  LiquidityAmountsHelperABI
} from '@traderjoe-team/spruce-sdk-v2'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { UserLBBinPosition } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'
import { convertLBPositionsToUserLBPositions } from 'utils/poolV2'
import { getAddress } from 'viem'
import { useContractReads } from 'wagmi'

interface UseLBPairBalancesProps {
  lbPairAddress?: string
  owner?: string
}

const useLBPairBalances = ({
  lbPairAddress,
  owner
}: UseLBPairBalancesProps) => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)
  const fetchUserBinLiquidity = useDexbarnGet<UserLBBinPosition[]>(
    `/v1/user/bin-position?userAddress=${owner?.toLowerCase()}&chain=${chain}&poolAddress=${lbPairAddress?.toLowerCase()}&pageSize=1000`
  )

  const {
    data: bins,
    isFetching: isFetchingBins,
    refetch: refetchBins
  } = useQuery<UserLBBinPosition[]>(
    ['UserBinLiquidity', chain, owner, lbPairAddress],
    () => fetchUserBinLiquidity(),
    { enabled: !!lbPairAddress && !!owner, keepPreviousData: true }
  )

  // we get the amounts and liquidities from the chain directly
  const binIds = bins?.map((bin) => BigInt(bin.binId))
  const args =
    owner && binIds && lbPairAddress
      ? ([getAddress(owner), binIds, getAddress(lbPairAddress)] as const)
      : undefined
  const {
    data: amountsAndLiquidities,
    isFetching: isFetchingAmountsAndLiquidities,
    refetch: refetchAmountsAndLiquidities
  } = useContractReads({
    contracts: [
      {
        abi: LiquidityAmountsHelperABI,
        address: getAddress(LIQUIDITY_AMOUNTS_HELPER_ADDRESS[chainId]),
        args,
        chainId,
        functionName: 'getAmountsOf'
      },
      {
        abi: LiquidityAmountsHelperABI,
        address: getAddress(LIQUIDITY_AMOUNTS_HELPER_ADDRESS[chainId]),
        args,
        chainId,
        functionName: 'getBalanceOf'
      }
    ],
    enabled: !!lbPairAddress && !!owner && !!binIds && binIds.length > 0
  })

  const amounts = amountsAndLiquidities?.[0].result
  const liquidities = amountsAndLiquidities?.[1].result
  const positions =
    bins && amounts && liquidities
      ? convertLBPositionsToUserLBPositions({
          amounts: { amountsX: amounts[0], amountsY: amounts[1] },
          liquidities,
          userLBPositions: bins
        })
      : undefined

  return {
    data: positions,
    isFetching: isFetchingBins || isFetchingAmountsAndLiquidities,
    refetch: () => {
      refetchBins()
      refetchAmountsAndLiquidities()
    }
  }
}

export default useLBPairBalances
