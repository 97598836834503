import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Center, HStack, Tag, Text } from '@chakra-ui/react'
import { CNATIVE, Token } from '@traderjoe-team/spruce-sdk'
import { PoolVersion } from '@traderjoe-team/spruce-sdk-v2'
import DoubleCurrencyLogo from 'components/DoubleCurrencyLogo'
import useLBPairFeeParameters from 'hooks/pool/v2/useLBPairFeeParameters'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { isWrappedNativeCurrency } from 'utils/wrappedCurrency'

interface RouteDetailsStepProps {
  pair: Token[]
  showForwardArrow: boolean
  lbPairAddress?: string
  poolVersion?: PoolVersion
}

const RouteDetailsStep = ({
  lbPairAddress,
  pair,
  poolVersion,
  showForwardArrow
}: RouteDetailsStepProps) => {
  const chainId = useChainId()
  const nativeCurrency = CNATIVE.onChain(chainId)

  const {
    data: { baseFee }
  } = useLBPairFeeParameters({
    isV21: poolVersion === PoolVersion.V2_1,
    lbPairAddress
  })
  const fmtFee =
    poolVersion === PoolVersion.V1 ? '0.3%' : baseFee ? `${baseFee / 100}%` : ''
  return (
    <HStack spacing={1}>
      <DoubleCurrencyLogo
        address0={pair[0].address}
        symbol0={
          isWrappedNativeCurrency(pair[0].address, chainId)
            ? nativeCurrency.symbol
            : pair[0].symbol
        }
        address1={pair[1].address}
        symbol1={
          isWrappedNativeCurrency(pair[1].address, chainId)
            ? nativeCurrency.symbol
            : pair[1].symbol
        }
        boxSize={5}
      />
      <Text fontSize="sm" fontWeight="semibold">
        {pair[1].symbol}
      </Text>
      <Tag size="md" rounded="full" fontWeight="semibold">
        {fmtFee}
      </Tag>
      {showForwardArrow ? (
        <Center>
          <ArrowForwardIcon boxSize="14px" color="textSecondary" ml={1} />
        </Center>
      ) : null}
    </HStack>
  )
}

export default RouteDetailsStep
