import {
  ButtonProps,
  Flex,
  Heading,
  HeadingProps,
  HStack,
  InputGroup,
  InputProps,
  InputRightElement,
  Text,
  useDimensions,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { Currency } from '@traderjoe-team/spruce-sdk'
import CurrencyPickerModal from 'components/CurrencyPickerModal'
import InputBalanceButton from 'components/InputBalanceButton'
import NumericalInput from 'components/NumericalInput'
import React, { useRef } from 'react'

import CurrencySelectButton from './CurrencySelectButton'

export interface CurrencyInputProps {
  onValueChange: (value: string) => void
  value: string
  balance?: string
  currency?: Currency
  currencyAddress?: string
  currencyPickerButtonProps?: ButtonProps
  error?: string
  heading?: string
  headingProps?: HeadingProps
  headingRightElement?: JSX.Element
  isCurrencyPickerDisabled?: boolean
  isDisabled?: boolean
  onCurrencyChange?: (currency: Currency) => void
  rightElement?: JSX.Element
}

const CurrencyInput = ({
  balance,
  currency,
  currencyAddress,
  currencyPickerButtonProps,
  error,
  heading,
  headingProps,
  headingRightElement,
  isDisabled,
  onCurrencyChange,
  onValueChange,
  rightElement,
  value,
  ...props
}: CurrencyInputProps & InputProps) => {
  const rightElementRef = useRef<HTMLDivElement | null>(null)
  const rightElementDimensions = useDimensions(rightElementRef)

  const {
    isOpen: isCurrencyPickerOpen,
    onClose: onCurrencyPickerClose,
    onOpen: onCurrencyPickerOpen
  } = useDisclosure()

  const isCurrencyPickerEnabled = !!onCurrencyChange

  return (
    <>
      {onCurrencyChange ? (
        <CurrencyPickerModal
          isOpen={isCurrencyPickerOpen}
          onClose={onCurrencyPickerClose}
          onCurrencyChange={onCurrencyChange}
        />
      ) : null}
      <VStack w="full" spacing={1}>
        <Flex
          w="full"
          align="center"
          justify={heading ? 'space-between' : 'flex-end'}
        >
          {heading ? (
            <HStack spacing={1}>
              <Heading
                data-cy="currency-input-heading"
                fontSize="sm"
                w="full"
                textAlign="left"
                textColor="textPrimary"
                fontWeight="normal"
                {...headingProps}
              >
                {heading}
              </Heading>
              {headingRightElement ? headingRightElement : null}
            </HStack>
          ) : null}
          {balance && !!currency ? (
            <InputBalanceButton
              flexShrink={0}
              alignSelf="flex-end"
              balance={balance}
              isDisabled={isDisabled}
              onClick={() => onValueChange(balance)}
            />
          ) : null}
        </Flex>
        <InputGroup>
          <NumericalInput
            size="lg"
            inputType="decimal"
            placeholder="Enter Amount"
            isDisabled={isDisabled}
            isInvalid={!!error}
            value={value}
            onValueChange={onValueChange}
            {...props}
            pr={
              rightElementDimensions
                ? Math.trunc(rightElementDimensions.contentBox.width + 12)
                : undefined
            }
          />
          <InputRightElement
            ref={rightElementRef}
            w="fit-content"
            h="full"
            pr={1}
          >
            {rightElement && rightElement}
            {isCurrencyPickerEnabled ? (
              <CurrencySelectButton
                currency={currency}
                currencyAddress={currencyAddress}
                currencyPickerButtonProps={currencyPickerButtonProps}
                isDisabled={isDisabled}
                onCurrencyPickerOpen={onCurrencyPickerOpen}
              />
            ) : (
              <Text
                fontSize="xs"
                textColor="textPrimary"
                px={4}
                borderLeft="1px solid"
                borderColor="textPrimary"
              >
                {currency?.symbol}
              </Text>
            )}
          </InputRightElement>
        </InputGroup>
        {error ? (
          <Text fontSize="sm" color="red" w="full">
            {error}
          </Text>
        ) : null}
      </VStack>
    </>
  )
}

export default CurrencyInput
