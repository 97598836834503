import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { Currency } from '@traderjoe-team/spruce-sdk'
import { VaultABI } from '@traderjoe-team/spruce-sdk-v2'
import { WriteContractResult } from '@wagmi/core'
import useTransactionToast from 'hooks/useTransactionToast'
import { useCallback, useMemo } from 'react'
import { formattedNum } from 'utils/format'
import { formatUnits, getAddress } from 'viem'
import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi'

interface UseVaultDepositProps {
  vaultAddress: string
  amount0?: bigint
  amount1?: bigint
  currency0?: Currency
  currency1?: Currency
  enabled?: boolean
  onSuccess?: () => void
}

const useVaultDeposit = ({
  amount0,
  amount1,
  currency0,
  currency1,
  enabled = true,
  onSuccess,
  vaultAddress
}: UseVaultDepositProps) => {
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const isUsingNativeCurrency = currency0?.isNative || currency1?.isNative
  const args = useMemo(
    () =>
      amount0 !== undefined &&
      amount1 !== undefined &&
      (amount0 > BigInt(0) || amount1 > BigInt(0))
        ? ([amount0, amount1] as const)
        : undefined,
    [amount0, amount1]
  )
  const _enabled = enabled && !!args && !!currency0 && !!currency1

  const { config: depositNativeConfig } = usePrepareContractWrite({
    abi: VaultABI,
    address: getAddress(vaultAddress),
    args,
    cacheTime: 0,
    enabled: _enabled && isUsingNativeCurrency,
    functionName: 'depositNative',
    value: (currency0?.isNative ? amount0 : amount1) ?? BigInt(0)
  })

  const { config: depositConfig } = usePrepareContractWrite({
    abi: VaultABI,
    address: getAddress(vaultAddress),
    args,
    cacheTime: 0,
    enabled: _enabled && !isUsingNativeCurrency,
    functionName: 'deposit',
    value: BigInt(0) as any // workaround for safe app
  })

  const transactionSummary = useMemo(
    () =>
      amount0 !== undefined && amount1 !== undefined && currency0 && currency1
        ? `Deposit ${formattedNum(formatUnits(amount0, currency0.decimals))} ${
            currency0.symbol
          } and ${formattedNum(formatUnits(amount1, currency1.decimals))} ${
            currency1.symbol
          }`
        : undefined,
    [currency0, currency1, amount0, amount1]
  )

  const onWriteSuccess = useCallback(
    (data: WriteContractResult) => {
      if (!transactionSummary) return
      addRecentTransaction({
        description: transactionSummary,
        hash: data.hash
      })
      addTransactionToast({ description: transactionSummary, hash: data.hash })
    },
    [addRecentTransaction, addTransactionToast, transactionSummary]
  )

  const {
    data: depositNativeData,
    isLoading: isLoadingDepositNative,
    write: depositNative
  } = useContractWrite({ ...depositNativeConfig, onSuccess: onWriteSuccess })

  const {
    data: depositData,
    isLoading: isLoadingDeposit,
    write: deposit
  } = useContractWrite({ ...depositConfig, onSuccess: onWriteSuccess })

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransaction({
      hash: isUsingNativeCurrency ? depositNativeData?.hash : depositData?.hash,
      onSuccess
    })

  return {
    deposit: isUsingNativeCurrency ? depositNative : deposit,
    isLoading:
      isWaitingForTransaction ||
      (isUsingNativeCurrency ? isLoadingDepositNative : isLoadingDeposit),
    isSuccess
  }
}

export default useVaultDeposit
