import { Box, Button, ButtonProps } from '@chakra-ui/react'
import React from 'react'
import { formattedNum } from 'utils/format'

interface InputBalanceButtonProps {
  balance: string
}

const InputBalanceButton = ({
  balance,
  ...props
}: InputBalanceButtonProps & ButtonProps) => (
  <Button
    data-cy="input-balance-button"
    variant="ghost"
    size="xs"
    _hover={{ _dark: { bg: 'joeDark.500' }, bg: 'silver' }}
    {...props}
  >
    <Box as="span" color="textPrimary" fontWeight="normal" pr={0.5}>
      {'Balance: '}
    </Box>
    {formattedNum(balance)}
  </Button>
)

export default InputBalanceButton
