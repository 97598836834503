import { SimpleGrid, Skeleton, Text, useToken } from '@chakra-ui/react'
import AnalyticsChart from 'components/AnalyticsChart'
import usePoolHomeAnalytics from 'hooks/analytics/usePoolHomeAnalytics'
import React from 'react'
import { formattedNum } from 'utils/format'

const PoolMainAnalytics = () => {
  const [graphPurple] = useToken('colors', ['graphPurpleLight'])

  const { analyticsData, isLoading } = usePoolHomeAnalytics()
  const { v2Data } = analyticsData

  const chartData = v2Data

  const fmtTVL =
    chartData.liquidity.length > 0
      ? formattedNum(
          chartData.liquidity[chartData.liquidity.length - 1].value,
          { usd: true }
        )
      : '0'

  return (
    <SimpleGrid columns={{ base: 1, md: 2 }} px={4} pt={4} pb={6} gap={8}>
      <AnalyticsChart
        id="tvl"
        header={
          <Skeleton isLoaded={!isLoading}>
            <Text fontWeight="semibold" fontSize="2xl" minW="100px">
              {fmtTVL}
            </Text>
          </Skeleton>
        }
        subheader="Total Value Locked"
        data={chartData.liquidity}
        fill={graphPurple}
        stroke="#7508FF"
        h={{ base: '80px', md: '200px' }}
      />
      <AnalyticsChart
        id="volume"
        header={
          <Skeleton isLoaded={!isLoading}>
            <Text fontWeight="semibold" fontSize="2xl" minW="100px">
              {formattedNum(chartData.volume24, { usd: true })}
            </Text>
          </Skeleton>
        }
        subheader="Volume"
        data={chartData.volume}
        fill={graphPurple}
        stroke="#7508FF"
        h={{ base: '80px', md: '200px' }}
      />
    </SimpleGrid>
  )
}

export default PoolMainAnalytics
