import { useQuery } from '@tanstack/react-query'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { UserLBPosition } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'
import { useAccount } from 'wagmi'

const useUserLBPositions = () => {
  const { address: account } = useAccount()
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)

  const fetchUserPoolPositions = useDexbarnGet<UserLBPosition[]>(
    `/v1/user/pool-position?userAddress=${account?.toLowerCase()}&chain=${chain}&pageSize=50`
  )
  const result = useQuery<UserLBPosition[]>(
    ['UserLBPositionsQuery', chain, account],
    () => fetchUserPoolPositions(),
    { enabled: !!account, keepPreviousData: true }
  )

  return {
    ...result,
    data: result.data ?? []
  }
}

export default useUserLBPositions
