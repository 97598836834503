import { useQuery } from '@tanstack/react-query'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { LeaderboardData, LeaderboardFilterType } from 'types/leaderboard'
import { getDexbarnChainParam } from 'utils/chains'

interface usePoolLeaderboardProps {
  filterType: LeaderboardFilterType
  lbPairAddress?: string
}

const usePoolLeaderboard = ({
  filterType,
  lbPairAddress
}: usePoolLeaderboardProps) => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)

  const fetchLeaderboardData = useDexbarnGet<LeaderboardData[]>(
    `/v1/pools/leaderboard/${chain}/${lbPairAddress}?filterBy=${filterType}`
  )

  return useQuery<LeaderboardData[]>({
    enabled: !!lbPairAddress && !!filterType,
    queryFn: () => fetchLeaderboardData(),
    queryKey: ['LeaderboardData', lbPairAddress, filterType]
  })
}

export default usePoolLeaderboard
