import 'wagmi/window'

import { Chain, Wallet } from '@rainbow-me/rainbowkit'
import { InjectedConnector } from '@wagmi/core'

import { avalanche } from './chains'

class CoreConnector extends InjectedConnector {
  id = 'core'
  override async getChainId(): Promise<number> {
    // The popup is not prompted when the wallet is locked because `eth_chainId` never completes.
    // We override the chain id to workaround this issue.
    if (
      window.avalanche?.chainId === '0xundefined' ||
      window.avalanche?.chainId === null
    ) {
      return avalanche.id
    }
    return super.getChainId()
  }
}

export const core = ({ chains }: { chains: Chain[] }): Wallet => {
  const isInstalled =
    typeof window !== 'undefined' && window.ethereum?.isAvalanche === true

  return {
    createConnector: (): any => {
      const connector = new CoreConnector({
        chains,
        options: {
          getProvider: () =>
            typeof window !== 'undefined' ? window.avalanche : undefined,
          name: 'Core Wallet'
        }
      })
      return {
        connector,
        extension: {
          instructions: {
            learnMoreUrl:
              'https://support.traderjoexyz.com/en/articles/6711103-wallet-setup-core',
            steps: [
              {
                description:
                  'We recommend pinning Core to your taskbar for quicker access to your wallet.',
                step: 'install',
                title: 'Install the Core extension'
              },
              {
                description:
                  'Be sure to back up your wallet using a secure method. Never share your secret phrase with anyone.',
                step: 'create',
                title: 'Create or Import a Wallet'
              },
              {
                description:
                  'Once you set up your wallet, click below to refresh the browser and load up the extension.',
                step: 'refresh',
                title: 'Refresh your browser'
              }
            ]
          }
        }
      }
    },
    downloadUrls: {
      browserExtension:
        'https://chrome.google.com/webstore/detail/core/agoakfejjabomempkjlepdflaleeobhb',
      qrCode:
        'https://chrome.google.com/webstore/detail/core/agoakfejjabomempkjlepdflaleeobhb'
    },
    iconBackground: '#fff',
    iconUrl: async () => (await import('../assets/core-owl.svg')).default,
    id: 'core',
    installed: isInstalled,
    name: 'Core Wallet'
  }
}
