import { ColorModeScript } from '@chakra-ui/react'
import { QueryClientProvider } from '@tanstack/react-query'
import AxiosInterceptor from 'components/AxiosInterceptor'
import { queryClient } from 'constants/queryClient'
import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import store from 'state/store'
import { initializeErrorLogger } from 'utils/logger'
import { initializeAnalytics } from 'utils/measure'

import { App } from './App'
import reportWebVitals from './reportWebVitals'

initializeAnalytics()
initializeErrorLogger()

const container = document.getElementById('root')
if (!container) throw new Error('Failed to find the root element')
const root = ReactDOM.createRoot(container)

root.render(
  <React.StrictMode>
    <ColorModeScript />
    <QueryClientProvider client={queryClient}>
      <AxiosInterceptor>
        <Provider store={store}>
          <App />
        </Provider>
      </AxiosInterceptor>
    </QueryClientProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
