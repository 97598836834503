import {
  Flex,
  Heading,
  HStack,
  IconButton,
  Text,
  VStack
} from '@chakra-ui/react'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import React from 'react'
import { GKeyIcon, KeyboardIcon, TKeyIcon, UKeyIcon } from 'theme/icons'

const HotkeyTooltipButton = () => {
  return (
    <TouchFriendlyTooltip
      bg="white"
      _dark={{ bg: 'joeDark.500' }}
      boxShadow="0px 8px 24px rgba(0, 0, 0, 0.08)"
      placement="bottom-end"
      label={
        <VStack p={4} spacing={4} alignItems="flex-start">
          <Heading size="sm" textColor="textPrimary">
            Hotkeys
          </Heading>
          <Flex fontSize="sm" alignItems="center" gap={2}>
            <Text>Tap</Text>
            <TKeyIcon boxSize="28px" fill="accent.500" />
            <Text>to toggle xToken/yToken</Text>
          </Flex>
          <HStack fontSize="sm" alignItems="center" spacing={2}>
            <Text>Tap</Text>
            <GKeyIcon boxSize="28px" fill="accent.500" />
            <Text>to display price in 8 decimals</Text>
          </HStack>
          <HStack fontSize="sm" alignItems="center" spacing={2}>
            <Text>Hold</Text>
            <UKeyIcon boxSize="28px" fill="accent.500" />
            <Text>to display price in USD</Text>
          </HStack>
        </VStack>
      }
    >
      <IconButton
        cursor="default"
        variant="ghost"
        aria-label="hotkey-tooltip"
        borderRadius={{ base: 'none' }}
        boxSize="48px"
        icon={<KeyboardIcon boxSize="16px" />}
      />
    </TouchFriendlyTooltip>
  )
}

export default HotkeyTooltipButton
