import { useQuery } from '@tanstack/react-query'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { VaultQueuedWithdrawal as DexbarnVaultQueuedWithdrawal } from 'types/dexbarn'
import { VaultQueuedWithdrawal } from 'types/vault'
import { getDexbarnChainParam } from 'utils/chains'
import { useAccount } from 'wagmi'

const convertDexbarnResponse = (
  data: DexbarnVaultQueuedWithdrawal[]
): VaultQueuedWithdrawal[] | undefined => {
  const vaultQueuedWithdrawals: VaultQueuedWithdrawal[] = data.map(
    (withdrawal) => ({
      round: withdrawal.round,
      shares: BigInt(withdrawal.shares),
      vaultAddress: withdrawal.vaultAddress
    })
  )
  return vaultQueuedWithdrawals
}

interface UseVaultQueuedWithdrawalsProps {
  vaultAddress?: string
}

const useVaultQueuedWithdrawals = ({
  vaultAddress
}: UseVaultQueuedWithdrawalsProps) => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)
  const { address: account } = useAccount()
  const fetchQueuedWithdrawals = useDexbarnGet<DexbarnVaultQueuedWithdrawal[]>(
    `/v1/vaults/${chain}/${vaultAddress}/withdrawals/${account}`
  )
  return useQuery(
    ['VaultQueuedWithdrawalsQuery', chain, account, vaultAddress],
    () => fetchQueuedWithdrawals(),
    {
      enabled: !!account && !!vaultAddress,
      select: convertDexbarnResponse
    }
  )
}

export default useVaultQueuedWithdrawals
