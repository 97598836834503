import { ChevronRightIcon } from '@chakra-ui/icons'
import { Icon, Text, VStack } from '@chakra-ui/react'
import { ReactComponent as LogoutLogo } from 'assets/logout-icon.svg'
import useActiveChain from 'hooks/useActiveChain'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { MyActivityIcon, MyPoolIcon, MySettingsIcon } from 'theme/icons'
import { WalletMenuEnum } from 'types/walletMenu'
import { getChainSlug } from 'utils/chains'
import { useDisconnect } from 'wagmi'

import WalletMenuItem from './WalletMenuItem'

interface WalletMyInfoProps {
  setWalletMenuDisplay: (menuDisplay: WalletMenuEnum) => void
}

const WalletMyInfo = ({ setWalletMenuDisplay }: WalletMyInfoProps) => {
  const chain = useActiveChain()
  const navigate = useNavigate()
  const { disconnect } = useDisconnect()

  return (
    <VStack w="full" alignItems="flex-start" py={4} px={3} rowGap={2}>
      <WalletMenuItem
        onClick={() => navigate(`/${getChainSlug(chain.id)}/pool?tab=my-pools`)}
      >
        <MyPoolIcon boxSize="20px" mr={4} fill="textPrimary" />
        <Text fontWeight="semibold">My Pools</Text>
        <ChevronRightIcon textColor="textPrimary" ml="auto" boxSize="20px" />
      </WalletMenuItem>
      <WalletMenuItem
        onClick={() => setWalletMenuDisplay(WalletMenuEnum.Activity)}
      >
        <MyActivityIcon boxSize="20px" mr={4} fill="textPrimary" />
        <Text fontWeight="semibold">Activity</Text>
        <ChevronRightIcon textColor="textPrimary" ml="auto" boxSize="20px" />
      </WalletMenuItem>
      <WalletMenuItem
        onClick={() => setWalletMenuDisplay(WalletMenuEnum.Settings)}
      >
        <MySettingsIcon boxSize="20px" mr={4} fill="textPrimary" />
        <Text fontWeight="semibold">Settings</Text>
        <ChevronRightIcon textColor="textPrimary" ml="auto" boxSize="20px" />
      </WalletMenuItem>
      <WalletMenuItem onClick={() => disconnect()}>
        <Icon boxSize="20px" mr={4} as={LogoutLogo} fill="textPrimary" />
        <Text fontWeight="semibold">Disconnect</Text>
      </WalletMenuItem>
    </VStack>
  )
}

export default WalletMyInfo
