import {
  Currency,
  Percent,
  Token,
  TokenAmount
} from '@traderjoe-team/spruce-sdk'
import {
  getBidAskDistributionFromBinRange,
  getCurveDistributionFromBinRange,
  getUniformDistributionFromBinRange,
  LiquidityDistribution,
  LiquidityDistributionParams,
  normalizeDist
} from '@traderjoe-team/spruce-sdk-v2'
import { WEI_PER_ETHER } from 'constants/bigint'
import { Pool as DexbarnPool, UserLBBinPosition } from 'types/dexbarn'
import { LBPool } from 'types/pool'
import {
  BinVolumeData,
  LBPairDistribution,
  LBPairUserBalances
} from 'types/poolV2'
import { formatEther, formatUnits, parseEther } from 'viem'

import { getPriceFromBinId } from './bin'
import { computeAndParsePriceFromBin } from './prices'
import { getCurrencyAmount } from './swap'

export const convertLBPositionsToUserLBPositions = ({
  amounts,
  liquidities,
  userLBPositions
}: {
  amounts: {
    amountsX: readonly bigint[]
    amountsY: readonly bigint[]
  }
  liquidities: readonly bigint[]
  userLBPositions: UserLBBinPosition[]
}) => {
  const userBalances: LBPairUserBalances = {
    amounts: [],
    liquidity: [],
    positions: [],
    prices: []
  }

  const amountsX = amounts.amountsX
  const amountsY = amounts.amountsY

  userLBPositions.forEach(
    (
      {
        binId,
        lbBinStep,
        tokenX: { decimals: decimalsX },
        tokenY: { decimals: decimalsY }
      },
      i
    ) => {
      const amountRawX = amountsX[i].toString()
      const amountRawY = amountsY[i].toString()
      const amountX = parseFloat(formatUnits(amountsX[i], decimalsX))
      const amountY = parseFloat(formatUnits(amountsY[i], decimalsY))
      userBalances.positions.push(binId)
      userBalances.liquidity.push(liquidities[i].toString())
      userBalances.prices.push(
        parseFloat(
          getPriceFromBinId(binId, lbBinStep, decimalsX, decimalsY, 18)
        )
      )
      userBalances.amounts.push({
        amountX: amountX < 0 ? 0 : amountX,
        amountY: amountY < 0 ? 0 : amountY,
        rawAmountX: !amountRawX.includes('-') ? amountRawX : '0',
        rawAmountY: !amountRawY.includes('-') ? amountRawY : '0'
      })
    }
  )

  return userBalances
}

export const inverseLBPairDistributions = (
  pairDistributions: LBPairDistribution[]
): LBPairDistribution[] => {
  return pairDistributions.map((distribution) => ({
    ...distribution,
    price: (1 / Number(distribution.price)).toFixed(18)
  }))
}

export const inverseBinsData = (bins: BinVolumeData[]): BinVolumeData[] => {
  return bins.map((bin) => ({
    ...bin,
    priceXY: 1 / bin.priceXY,
    priceYX: 1 / bin.priceYX
  }))
}

interface ConvertLBPositionToLiquidityChartDataProps {
  activeBinId: number
  binStep: string
  token0?: Token
  token1?: Token
  userBalances?: LBPairUserBalances
}

export const convertLBPositionToLiquidityChartData = ({
  activeBinId,
  binStep,
  token0,
  token1,
  userBalances
}: ConvertLBPositionToLiquidityChartDataProps) => {
  try {
    if (!userBalances || !token0 || !token1) {
      return []
    }

    const userData = userBalances.positions.map((binId, i) => {
      const amountX = userBalances.amounts[i].amountX
      const amountY = userBalances.amounts[i].amountY
      const rawAmountX = userBalances.amounts[i].rawAmountX
      const rawAmountY = userBalances.amounts[i].rawAmountY
      const price = userBalances.prices[i]
      const amountYRatio =
        amountY > 0 || amountX > 0 ? amountY / (amountX * price + amountY) : 0
      return {
        amountX: new TokenAmount(token0, rawAmountX),
        amountY: new TokenAmount(token1, rawAmountY),
        amountYPct: (amountYRatio * 100).toFixed(2),
        binId,
        isActiveBin: binId === activeBinId,
        liquidity: Number(
          formatUnits(BigInt(userBalances.liquidity[i]), token1.decimals)
        ),
        price: `${computeAndParsePriceFromBin(
          binId,
          Number(binStep),
          token0,
          token1,
          token1.decimals
        )}`
      }
    })

    // insert empty bins
    const finalData: LBPairDistribution[] = []
    userData.forEach((data, i) => {
      let thisBinId = i === 0 ? 0 : finalData[finalData.length - 1].binId + 1
      while (i !== 0 && thisBinId < data.binId) {
        finalData.push({
          amountX: new TokenAmount(token0, '0'),
          amountY: new TokenAmount(token0, '0'),
          amountYPct: '0',
          binId: thisBinId,
          isActiveBin: thisBinId === activeBinId,
          liquidity: 0,
          price: `${computeAndParsePriceFromBin(
            thisBinId,
            Number(binStep),
            token0,
            token1,
            token1.decimals
          )}`
        })
        thisBinId += 1
      }
      finalData.push(data)
    })
    return finalData
  } catch {
    return []
  }
}

export const convertDexbarnPoolToPool = (pool: DexbarnPool): LBPool => {
  return {
    apr: (pool.feesUsd * 365) / pool.liquidityUsd,
    feePct: pool.lbBaseFeePct,
    feesUsd: pool.feesUsd,
    isMigrated: pool.status === 'old',
    lbBinStep: pool.lbBinStep,
    lbMaxFeePct: pool.lbMaxFeePct,
    lbPoolVersion: pool.version === 'v2.1' ? 'v21' : 'v2',
    liquidityDepthTokenX: pool.liquidityDepthTokenX,
    liquidityDepthTokenY: pool.liquidityDepthTokenY,
    liquidityUsd: pool.liquidityUsd,
    liquidityUsdDepthMinus: pool.liquidityDepthMinus,
    liquidityUsdDepthPlus: pool.liquidityDepthPlus,
    name: pool.name,
    pairAddress: pool.pairAddress,
    protocolFeePct: pool.protocolSharePct ?? 0,
    tokenX: pool.tokenX,
    tokenY: pool.tokenY,
    volumeUsd: pool.volumeUsd
  }
}

// convert delta ids, distributionX, distributionY to an array of LBPairDistribution
export const convertDistributionParamsToLiquidityDistribution = ({
  activeBinId,
  binStep,
  distributionParams,
  token0,
  token1,
  totalAmount0,
  totalAmount1
}: {
  activeBinId?: number
  binStep?: string
  distributionParams?: LiquidityDistributionParams
  token0?: Token
  token1?: Token
  totalAmount0?: bigint
  totalAmount1?: bigint
}): LBPairDistribution[] | undefined => {
  if (!distributionParams || !activeBinId || !token0 || !token1 || !binStep) {
    return undefined
  }
  const { deltaIds, distributionX, distributionY } = distributionParams

  try {
    const sumDistributionX = distributionX.reduce((a, b) => a + b, BigInt(0))

    const sumDistributionY = distributionY.reduce((a, b) => a + b, BigInt(0))

    return deltaIds.map((deltaId, i) => {
      const binId = activeBinId + deltaId
      const price = computeAndParsePriceFromBin(
        binId,
        Number(binStep),
        token0,
        token1,
        token1.decimals
      )
      const parsedPrice = parseEther(price as `${number}`)

      const amountXFactor =
        sumDistributionX > BigInt(0)
          ? (distributionX[i] * BigInt(10000)) / sumDistributionX
          : BigInt(0)
      const amountYFactor =
        sumDistributionY > 0
          ? (distributionY[i] * BigInt(10000)) / sumDistributionY
          : BigInt(0)
      const amountX = totalAmount0
        ? (totalAmount0 * amountXFactor) / BigInt(10000)
        : BigInt(0)
      const amountY = totalAmount1
        ? (totalAmount1 * amountYFactor) / BigInt(10000)
        : BigInt(0)

      const scaledAmountY =
        (amountY * WEI_PER_ETHER) / BigInt(10) ** BigInt(token1.decimals)
      const scaledAmountX =
        (amountX * parsedPrice) / BigInt(10) ** BigInt(token0.decimals)
      const liquidity = scaledAmountX + scaledAmountY

      const amountYPct =
        liquidity > 0 ? new Percent(scaledAmountY, liquidity).toFixed(0) : '0'

      return {
        amountX: new TokenAmount(token0, amountX.toString()),
        amountY: new TokenAmount(token1, amountY.toString()),
        amountYPct,
        binId,
        isActiveBin: binId === activeBinId,
        liquidity: parseFloat(formatEther(liquidity)),
        price
      }
    })
  } catch (err) {
    console.error(err)
    return undefined
  }
}

export const getAddLiquidityDistributionParams = ({
  activeBinId,
  amount0,
  amount1,
  binRange,
  currency0,
  currency1,
  liquidityDistribution
}: {
  liquidityDistribution: LiquidityDistribution
  activeBinId?: number
  amount0?: bigint
  amount1?: bigint
  binRange?: number[]
  currency0?: Currency
  currency1?: Currency
}): LiquidityDistributionParams | undefined => {
  const currencyAmount0 = getCurrencyAmount(currency0, amount0)
  const currencyAmount1 = getCurrencyAmount(currency1, amount1)

  if (!activeBinId || !binRange || !currencyAmount0 || !currencyAmount1) {
    return undefined
  }

  let distributionParams: LiquidityDistributionParams | undefined
  try {
    distributionParams =
      liquidityDistribution === LiquidityDistribution.BID_ASK
        ? getBidAskDistributionFromBinRange(activeBinId, binRange, [
            currencyAmount0,
            currencyAmount1
          ])
        : liquidityDistribution === LiquidityDistribution.CURVE
        ? getCurveDistributionFromBinRange(activeBinId, binRange, [
            currencyAmount0,
            currencyAmount1
          ])
        : getUniformDistributionFromBinRange(activeBinId, binRange, [
            currencyAmount0,
            currencyAmount1
          ])
  } catch (e) {
    console.error(e)
    // getUniformDistributionFromBinRange may throw an error when the bin range is invalid
    // for example: the user enters a min price greater than max price
    distributionParams = undefined
  }

  const {
    deltaIds,
    distributionX: distributionX_,
    distributionY: distributionY_
  } = distributionParams || {}

  const sumX = distributionX_?.reduce((sum, cur) => sum + cur, BigInt(0))
  const sumY = distributionY_?.reduce((sum, cur) => sum + cur, BigInt(0))

  const distributionX =
    distributionX_ && sumX && sumX > 0
      ? normalizeDist(distributionX_, parseEther('1'), parseEther('1'))
      : distributionX_

  const distributionY =
    distributionY_ && sumY && sumY > 0
      ? normalizeDist(distributionY_, parseEther('1'), parseEther('1'))
      : distributionY_

  return deltaIds && distributionX && distributionY
    ? { deltaIds, distributionX, distributionY }
    : undefined
}
