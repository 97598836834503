import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { VaultABI } from '@traderjoe-team/spruce-sdk-v2'
import useTransactionToast from 'hooks/useTransactionToast'
import { getAddress } from 'viem'
import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi'

interface UseVaultCancelQueuedWithdrawalProps {
  shares: bigint
  vaultAddress: string
  enabled?: boolean
  onSuccess?: () => void
}

const useVaultCancelQueuedWithdrawal = ({
  enabled = true,
  onSuccess,
  shares,
  vaultAddress
}: UseVaultCancelQueuedWithdrawalProps) => {
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { config } = usePrepareContractWrite({
    abi: VaultABI,
    address: getAddress(vaultAddress),
    args: shares ? [shares] : undefined,
    cacheTime: 0,
    enabled,
    functionName: 'cancelQueuedWithdrawal',
    value: BigInt(0) as any // workaround for safe app
  })

  const { data, isLoading, write } = useContractWrite({
    ...config,
    onSuccess: (data) => {
      const transactionSummary = 'Cancel vault withdrawal'
      addRecentTransaction({
        description: transactionSummary,
        hash: data.hash
      })
      addTransactionToast({ description: transactionSummary, hash: data.hash })
    }
  })

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransaction({
      hash: data?.hash,
      onSuccess
    })

  return {
    cancelQueuedWithdrawal: write,
    isLoading: isWaitingForTransaction || isLoading,
    isSuccess
  }
}

export default useVaultCancelQueuedWithdrawal
