import { Box, HStack, Tab, TabProps } from '@chakra-ui/react'
import React from 'react'

interface TabWithValueProps {
  selected: boolean
  title: string
  value: string
}

const TabWithValue = ({
  selected,
  title,
  value,
  ...props
}: TabWithValueProps & TabProps) => {
  return (
    <Tab {...props}>
      <HStack spacing={8}>
        <Box>{title}</Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="full"
          bg={selected ? 'joeLight.500' : 'joeLight.400'}
          fontSize="sm"
          fontWeight="semibold"
          minW={6}
          minH={6}
          _dark={{ bg: selected ? 'joeDark.600' : 'joeDark.500' }}
        >
          {value}
        </Box>
      </HStack>
    </Tab>
  )
}

export default TabWithValue
