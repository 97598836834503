import { SmallCloseIcon } from '@chakra-ui/icons'
import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement
} from '@chakra-ui/react'
import React from 'react'
import { SearchIcon } from 'theme/icons'

interface SearchBarProps {
  onValueChange?: (value: string) => void
}

const SearchBar = ({
  onValueChange,
  ...props
}: SearchBarProps & InputProps) => (
  <InputGroup size={props.size}>
    <InputLeftElement>
      <SearchIcon />
    </InputLeftElement>
    <Input
      {...props}
      onChange={(e) => onValueChange?.(e.currentTarget.value)}
      bg="silver"
      fontSize="sm"
      _dark={{ bg: 'transparent' }}
      borderRadius="0"
      _focus={{ borderColor: 'border', boxShadow: 'none', outline: 'none' }}
    />
    {props.value ? (
      <InputRightElement pr={1} _hover={{ cursor: 'pointer' }}>
        <Button
          borderRadius={8}
          size="sm"
          variant="ghost"
          onClick={() => onValueChange?.('')}
        >
          <SmallCloseIcon boxSize={4} />
        </Button>
      </InputRightElement>
    ) : null}
  </InputGroup>
)

export default SearchBar
