import { useAddRecentTransaction } from '@rainbow-me/rainbowkit'
import { Token } from '@traderjoe-team/spruce-sdk'
import {
  LIMIT_ORDER_MANAGER_ADDRESS,
  LimitOrderManagerABI
} from '@traderjoe-team/spruce-sdk-v2'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import { useMemo } from 'react'
import { OrderType } from 'types/limitOrder'
import { capturePrepareContractWriteError } from 'utils/logger'
import { isWrappedNativeCurrency } from 'utils/wrappedCurrency'
import { getAddress, zeroAddress } from 'viem'
import {
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useWaitForTransaction
} from 'wagmi'

interface UseBatchPlaceLimitOrdersProps {
  amounts?: bigint[]
  binIds?: number[]
  binStep?: number
  enabled?: boolean
  isNative?: boolean
  onOrdersSuccess?: () => void
  orderType?: OrderType
  tokenX?: Token
  tokenY?: Token
  totalAmount?: bigint
}

const useBatchPlaceLimitOrders = ({
  amounts,
  binIds,
  binStep,
  enabled = true,
  isNative,
  onOrdersSuccess,
  orderType,
  tokenX,
  tokenY,
  totalAmount
}: UseBatchPlaceLimitOrdersProps) => {
  const chainId = useChainId()
  const walletChainId = useNetwork().chain?.id

  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  // prepare 'batchPlaceOrdersSamePair' contract write
  const args = useMemo(
    () =>
      tokenX?.address &&
      tokenY?.address &&
      binStep &&
      binIds &&
      amounts &&
      binIds.length > 0 &&
      binIds.length === amounts.length &&
      orderType !== undefined
        ? ([
            isWrappedNativeCurrency(tokenX.address, chainId)
              ? zeroAddress
              : getAddress(tokenX.address),
            isWrappedNativeCurrency(tokenY.address, chainId)
              ? zeroAddress
              : getAddress(tokenY.address),
            binStep,
            binIds.map((binId, index) => ({
              amount: amounts[index],
              binId,
              orderType
            }))
          ] as const)
        : undefined,
    [tokenX, tokenY, binStep, amounts, binIds, orderType, chainId]
  )

  const { config } = usePrepareContractWrite({
    abi: LimitOrderManagerABI,
    address: getAddress(LIMIT_ORDER_MANAGER_ADDRESS[chainId]),
    args,
    cacheTime: 0,
    chainId,
    enabled: !!args && chainId === walletChainId && enabled,
    functionName: 'batchPlaceOrdersSamePair',
    onSettled: (_, error) => {
      capturePrepareContractWriteError(error)
    },
    value: isNative && totalAmount ? totalAmount : BigInt(0)
  })

  const { data, isLoading, write } = useContractWrite({
    ...config,
    onSuccess: (data) => {
      const description = 'Place limit orders'
      addRecentTransaction({ description, hash: data.hash })
      addTransactionToast({ description, hash: data.hash })
    }
  })

  const { isLoading: isWaitingForTransaction } = useWaitForTransaction({
    hash: data?.hash,
    onSuccess: onOrdersSuccess
  })

  return {
    data,
    isLoading: isLoading || isWaitingForTransaction,
    write
  }
}

export default useBatchPlaceLimitOrders
