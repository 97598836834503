import { avalancheFuji } from '@wagmi/core/chains'
import { avalanche } from 'viem/chains'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'

export const isRunningE2ETests = window.Cypress

const forkRpcUrl = localStorage.getItem('forkRpcUrl')
const forkChainId = localStorage.getItem('forkChainId')

const defaultRpcUrl = avalancheFuji.rpcUrls.public.http[0]
const rpcUrl = forkRpcUrl ?? defaultRpcUrl

export const e2eTestsConfig = {
  chain: forkChainId === '43114' ? avalanche : avalancheFuji,
  providers: [
    jsonRpcProvider({
      rpc: () => ({
        http: rpcUrl
      })
    })
  ],
  rpcUrl
}
