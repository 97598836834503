import {
  Divider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@chakra-ui/react'
import { Currency } from '@traderjoe-team/spruce-sdk'
import { FetchBalanceResult } from '@wagmi/core'
import React from 'react'

import VaultAddLiquidityPanel from './VaultAddLiquidityPanel'
import VaultRemoveLiquidityPanel from './VaultRemoveLiquidityPanel'

interface VaultAddRemoveLiquidityProps {
  vaultAddress: string
  balance0?: FetchBalanceResult
  balance1?: FetchBalanceResult
  currency0?: Currency
  currency1?: Currency
  depositDisabled?: boolean
  onAddLiquiditySuccess?: () => void
  onRemoveLiquiditySuccess?: () => void
}

const VaultAddRemoveLiquidity = (props: VaultAddRemoveLiquidityProps) => {
  return (
    <Tabs isFitted variant="soft-rounded-outlined-white" w="full">
      <TabList my={4} mx={{ base: 4, md: 8 }}>
        <Tab _selected={{ bg: 'joeLight.400', color: 'textPrimary' }}>
          Add Liquidity
        </Tab>
        <Tab _selected={{ bg: 'joeLight.400', color: 'textPrimary' }}>
          Remove Liquidity
        </Tab>
      </TabList>
      <Divider />
      <TabPanels py={{ base: 4, md: 6 }} px={{ base: 4, md: 8 }}>
        <TabPanel p={0}>
          <VaultAddLiquidityPanel {...props} />
        </TabPanel>
        <TabPanel p={0}>
          <VaultRemoveLiquidityPanel {...props} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default VaultAddRemoveLiquidity
