import {
  Divider,
  InputGroup,
  InputProps,
  InputRightElement,
  Text,
  useDimensions,
  VStack
} from '@chakra-ui/react'
import NumericalInput from 'components/NumericalInput'
import React, { useRef } from 'react'

interface LabeledInputProps {
  title: string
  inputType?: 'integer' | 'decimal'
  onValueChange?: (value: string) => void
  rightLabel?: string
  value?: string
}

const LabeledInput = ({
  inputType,
  onValueChange,
  rightLabel,
  title,
  value,
  ...props
}: LabeledInputProps & InputProps) => {
  const rightElementRef = useRef<HTMLDivElement | null>(null)
  const rightElementDimensions = useDimensions(rightElementRef)
  return (
    <VStack align="flex-start">
      <Text fontSize="sm">{title}</Text>
      <InputGroup size="lg">
        <NumericalInput
          value={value}
          onValueChange={onValueChange}
          placeholder={inputType === 'integer' ? '0' : '0.0'}
          inputType={inputType}
          pr={
            rightElementDimensions
              ? rightElementDimensions.contentBox.width + 16
              : 0
          }
          {...props}
        />
        {rightLabel ? (
          <InputRightElement
            ref={rightElementRef}
            w="fit-content"
            px={3}
            py={3}
          >
            <Divider orientation="vertical" mr={2} borderColor="textPrimary" />
            <Text fontSize="xs" textColor="textPrimary">
              {rightLabel}
            </Text>
          </InputRightElement>
        ) : null}
      </InputGroup>
    </VStack>
  )
}

export default LabeledInput
