import { Link, Text, useColorMode, useMediaQuery } from '@chakra-ui/react'
import {
  darkTheme,
  DisclaimerComponent,
  lightTheme,
  RainbowKitProvider as RainbowKitProviderDefault,
  Theme
} from '@rainbow-me/rainbowkit'
import { supportedChains } from 'constants/chains'
import useActiveChain from 'hooks/useActiveChain'
import React, { useMemo } from 'react'
import { fontStyle } from 'theme/fonts'

const joeDarkTheme: Theme = {
  ...darkTheme({ accentColor: '#8473ff' }),
  fonts: { body: fontStyle }
}

const joeLightTheme: Theme = {
  ...lightTheme({ accentColor: '#8473ff' }),
  fonts: { body: fontStyle }
}

interface RainbowKitProviderProps {
  children: React.ReactNode
}

const RainbowKitProvider = ({ children }: RainbowKitProviderProps) => {
  const [isLargerThan720] = useMediaQuery('(min-width: 720px)')
  const { colorMode } = useColorMode()

  // we can't rely on useParams here because RainbowKitProvider is outside BrowserRouter
  const pathname = location.pathname
  const chainSlugInUrl = useMemo(() => {
    const paths = pathname.split('/').filter((path) => path.length !== 0)
    return paths.length > 0 ? paths[0] : undefined
  }, [pathname])
  const activeChain = useActiveChain({ overridesChainSlug: chainSlugInUrl })

  const Disclaimer: DisclaimerComponent = () => (
    <Text fontSize="xs" align="left" textColor="textSecondary">
      By connecting your wallet, you agree to the{' '}
      <Link isExternal href="https://traderjoexyz.com/tos" color="accent.500">
        Terms of Service
      </Link>
      .
    </Text>
  )

  return (
    <RainbowKitProviderDefault
      chains={supportedChains}
      initialChain={activeChain}
      appInfo={{
        appName: 'Trader Joe',
        disclaimer: Disclaimer,
        learnMoreUrl:
          'https://support.traderjoexyz.com/en/collections/3691849-getting-setup#:~:text=a%20week%20ago-,Cryptocurrency%20Wallets,-Wallet%20Setup%3A%20Metamask'
      }}
      theme={colorMode === 'dark' ? joeDarkTheme : joeLightTheme}
      showRecentTransactions={isLargerThan720}
    >
      {children}
    </RainbowKitProviderDefault>
  )
}

export default RainbowKitProvider
