import {
  Box,
  Center,
  Divider,
  Flex,
  Heading,
  Hide,
  Spacer,
  Spinner,
  Tab,
  Table,
  TableContainer,
  TabList,
  Tabs,
  Tbody,
  Th,
  Thead,
  Tooltip,
  Tr
} from '@chakra-ui/react'
import { Currency } from '@traderjoe-team/spruce-sdk'
import SortableTableHeader from 'components/SortableTableHeader'
import usePoolLeaderboard from 'hooks/pool/usePoolLeaderboard'
import React, { useCallback, useMemo, useState } from 'react'
import { LeaderboardData, LeaderboardFilterType } from 'types/leaderboard'

import LeaderboardRow from './LeaderboardRow'
import LeaderboardRowMobile from './LeaderboardRowMobile'

enum SortMethod {
  VOLUME_TRADED_USD,
  ACCRUED_FEES_USD
}

interface LeaderboardProps {
  currency0?: Currency
  currency1?: Currency
  lbPairAddress?: string
}

const Leaderboard = ({
  currency0,
  currency1,
  lbPairAddress
}: LeaderboardProps) => {
  const [filterType, setTimeFrameFilter] = useState(LeaderboardFilterType.DAY)
  const [sortMethod, setSortMethod] = useState<SortMethod>(
    SortMethod.VOLUME_TRADED_USD
  )
  const [isSortDescending, setIsSortDescending] = useState<boolean>(true)

  const { data: leaderboardData = [], isLoading: isLeaderboardLoading } =
    usePoolLeaderboard({ filterType, lbPairAddress })

  const onHeaderClick = useCallback(
    (method: SortMethod) => {
      sortMethod !== method
        ? setSortMethod(method)
        : setIsSortDescending((previous) => !previous)
    },
    [sortMethod]
  )

  const sortedData = useMemo(() => {
    const sorter =
      sortMethod === SortMethod.VOLUME_TRADED_USD
        ? (a: LeaderboardData, b: LeaderboardData) =>
            parseFloat(a.shareVolumeTradedUsd) -
            parseFloat(b.shareVolumeTradedUsd)
        : sortMethod === SortMethod.ACCRUED_FEES_USD
        ? (a: LeaderboardData, b: LeaderboardData) =>
            parseFloat(a.accruedFeesUsd) - parseFloat(b.accruedFeesUsd)
        : (a: LeaderboardData, b: LeaderboardData) =>
            a.currentBinCount - b.currentBinCount

    let sorted = [...leaderboardData].sort(sorter)

    if (isSortDescending) {
      sorted = sorted.reverse()
    }

    return sorted
  }, [sortMethod, isSortDescending, leaderboardData])

  const handleTabsChange = (index: number) => {
    switch (index) {
      case 0:
        setTimeFrameFilter(LeaderboardFilterType.DAY)
        break
      case 1:
        setTimeFrameFilter(LeaderboardFilterType.WEEK)
        break
      case 2:
        setTimeFrameFilter(LeaderboardFilterType.TWO_WEEK)
        break
      case 3:
        setTimeFrameFilter(LeaderboardFilterType.MONTH)
        break
    }
  }

  const getTabIndex = (filterType: LeaderboardFilterType): number => {
    switch (filterType) {
      case LeaderboardFilterType.DAY:
        return 0
      case LeaderboardFilterType.WEEK:
        return 1
      case LeaderboardFilterType.TWO_WEEK:
        return 2
      case LeaderboardFilterType.MONTH:
        return 3
    }
  }

  const initialTabIndex = getTabIndex(filterType)

  if (isLeaderboardLoading) {
    return (
      <Center p={8}>
        <Spinner />
      </Center>
    )
  }

  return (
    <Flex flexDir="column">
      <Flex justifyContent="space-between" alignItems="center">
        <Heading size="md">LEADERBOARD</Heading>
        <Tabs
          overflowX="auto"
          defaultIndex={initialTabIndex}
          onChange={handleTabsChange}
          variant="lb-pro"
        >
          {/* TODO: 30D tabs disabled until backend ready */}
          <TabList>
            <Tab>24H</Tab>
            <Tab>7D</Tab>
            <Tab>14D</Tab>
            <Tooltip label="Coming Soon">
              <Tab isDisabled>30D</Tab>
            </Tooltip>
          </TabList>
        </Tabs>
      </Flex>
      <Spacer mt="1rem" />
      <Hide below="md">
        <TableContainer>
          <Table
            variant="card"
            style={{ borderCollapse: 'separate', borderSpacing: '0 8px' }}
          >
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Address</Th>
                <Th>Position</Th>
                <SortableTableHeader
                  isNumeric
                  name="Volume"
                  isSortActive={sortMethod === SortMethod.VOLUME_TRADED_USD}
                  isSortDescending={isSortDescending}
                  onClick={() => onHeaderClick(SortMethod.VOLUME_TRADED_USD)}
                />
                <SortableTableHeader
                  isNumeric
                  name="Fees Generated"
                  isSortActive={sortMethod === SortMethod.ACCRUED_FEES_USD}
                  isSortDescending={isSortDescending}
                  onClick={() => onHeaderClick(SortMethod.ACCRUED_FEES_USD)}
                />
              </Tr>
            </Thead>
            <Tbody>
              {sortedData.map((row, index) => (
                <LeaderboardRow
                  key={index}
                  {...row}
                  position={index + 1}
                  currency0={currency0}
                  currency1={currency1}
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Hide>
      <Hide above="md">
        {sortedData.map((row, index) => {
          return (
            <Box key={index}>
              <LeaderboardRowMobile
                {...row}
                position={index + 1}
                currency0={currency0}
                currency1={currency1}
              />
              {index < sortedData.length - 1 && <Divider my={6} />}
            </Box>
          )
        })}
      </Hide>
    </Flex>
  )
}

export default Leaderboard
