import { ArrowForwardIcon } from '@chakra-ui/icons'
import {
  Button,
  Divider,
  Flex,
  Heading,
  SimpleGrid,
  Text,
  VStack
} from '@chakra-ui/react'
import useChainId from 'hooks/useChainId'
import PoolTableNameColumn from 'pages/Pool/PoolTableNameColumn'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Vault } from 'types/vault'
import { formattedNum } from 'utils/format'
import { getPoolDetailV2Url } from 'utils/poolUrl'

import VaultInfoStats from './VaultInfoStats'

interface VaultInfoProps {
  vault: Vault
}

const VaultInfo = ({ vault }: VaultInfoProps) => {
  const navigate = useNavigate()
  const chainId = useChainId()

  const navigateToPoolDetail = useCallback(() => {
    const url = getPoolDetailV2Url(
      vault.lbPair.version,
      vault.lbPair.binStep,
      chainId,
      vault.tokenX.address,
      vault.tokenY.address
    )
    navigate(url)
  }, [navigate, vault, chainId])

  return (
    <VStack
      bg="bgCard"
      align="flex-start"
      borderRadius="2xl"
      border="1px"
      borderColor="border"
      p={{ base: 4, md: 8 }}
      spacing={{ base: 4, md: 6 }}
    >
      <Heading size="md">{vault.name}</Heading>
      <Text whiteSpace="pre-line" fontSize="sm">
        {vault.description}
      </Text>
      <Divider />
      <SimpleGrid w="full" columns={{ base: 2, sm: 4 }} gap={4}>
        <VaultInfoStats
          title="Liquidity"
          value={formattedNum(vault.totalValueLockedUSD, { usd: true })}
        />
        <VaultInfoStats
          title="Fees (24H)"
          value={formattedNum(vault.feesUsd, { usd: true })}
        />
        <VaultInfoStats
          title="APR (24H)"
          value={`${formattedNum(vault.apr1D * 100, { places: 2 })}%`}
        />
        <VaultInfoStats
          title="Automation Fee"
          value={`${vault.managementFeePct}%`}
          tooltipLabel={`The automation fee is applied to each rebalance taken from the total assets within the Auto-Pool, which equates to an annual rate of ${vault.managementFeePct}%.`}
        />
      </SimpleGrid>
      <Divider />
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        w="full"
        justify="space-between"
        gap={4}
      >
        <PoolTableNameColumn
          fees={`${vault.lbPair.baseFeePct}%`}
          token0={vault.tokenX}
          token1={vault.tokenY}
        />
        <Button
          variant="outline-bold"
          rightIcon={<ArrowForwardIcon />}
          onClick={navigateToPoolDetail}
        >
          Go to Pool Page
        </Button>
      </Flex>
    </VStack>
  )
}

export default VaultInfo
