import { InfoOutlineIcon } from '@chakra-ui/icons'
import { HStack, Text, VStack } from '@chakra-ui/react'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import React from 'react'

interface VaultInfoStatsProps {
  title: string
  value: string
  tooltipLabel?: string
}

const VaultInfoStats = ({
  title,
  tooltipLabel,
  value
}: VaultInfoStatsProps) => (
  <VStack align="flex-start" spacing={0}>
    <TouchFriendlyTooltip label={tooltipLabel} isDisabled={!tooltipLabel}>
      <HStack spacing={1}>
        <Text fontSize="sm" textColor="textSecondary" fontWeight="semibold">
          {title}
        </Text>
        {tooltipLabel ? (
          <InfoOutlineIcon boxSize={3} color="textSecondary" />
        ) : null}
      </HStack>
    </TouchFriendlyTooltip>
    <Text fontSize="lg" fontWeight="semibold">
      {value}
    </Text>
  </VStack>
)

export default VaultInfoStats
