import {
  Box,
  Center,
  Divider,
  Heading,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react'
import { Currency, Token, TokenAmount } from '@traderjoe-team/spruce-sdk'
import { FetchBalanceResult } from '@wagmi/core'
import CurrencyAmountsPanel from 'components/CurrencyAmountsPanel'
import LBPairDistributionChart from 'components/LBPairDistributionChart'
import Web3Button from 'components/Web3Button'
import { ZERO_ADDRESS } from 'constants/tokens'
import { getAddress } from 'ethers/lib/utils.js'
import useBatchMakeOrdersAction from 'hooks/limitOrder/useBatchMakeOrdersAction'
import { UseGetOrdersResult } from 'hooks/limitOrder/useGetOrders'
import React, { useMemo } from 'react'
import { LimitOrder } from 'types/dexbarn'
import { getPriceFromBinId } from 'utils/bin'
import { computeAndParsePriceFromBin } from 'utils/prices'
import { zeroAddress } from 'viem'

interface ActiveOrdersProps {
  isPriceRatioInversed: boolean
  onClaimOrdersSuccess: () => void
  ordersResult: UseGetOrdersResult
  activeBinId?: number
  balance0?: FetchBalanceResult
  balance1?: FetchBalanceResult
  binStep?: string
  currency0?: Currency
  currency1?: Currency
  tokenX?: Token
  tokenY?: Token
}

const ActiveOrders = ({
  activeBinId,
  binStep,
  currency0,
  currency1,
  isPriceRatioInversed,
  onClaimOrdersSuccess,
  ordersResult,
  tokenX,
  tokenY
}: ActiveOrdersProps) => {
  const { isLoading: isLoadingOrders, ordersByStatus } = ordersResult

  const { activeAmountX, activeAmountY, completedAmountX, completedAmountY } =
    calculateAmounts(
      ordersByStatus.placed,
      ordersByStatus.executed,
      tokenX,
      tokenY
    )

  const liquidityDistribution = useMemo(() => {
    if (!tokenX || !tokenY) {
      return []
    }

    const sorted = [...ordersByStatus.placed].sort((a, b) => a.binId - b.binId)
    const minBinId = sorted[0]?.binId
    const maxBinId = sorted[sorted.length - 1]?.binId

    const bins = []

    for (let binId = minBinId; binId <= maxBinId; binId++) {
      const bin = sorted.find((order) => order.binId === binId)

      const price = `${computeAndParsePriceFromBin(
        binId,
        Number(binStep),
        tokenX,
        tokenY,
        tokenY.decimals
      )}`

      if (bin) {
        const { pairLbBinStep } = bin
        const { amount, amountRaw, tokenId } = bin.tokenIn

        const isTokenX =
          getAddress(tokenId) === getAddress(tokenX?.address || ZERO_ADDRESS)

        const amountX = isTokenX ? amount : 0
        const amountY = !isTokenX ? amount : 0
        const rawAmountX = isTokenX ? amountRaw : '0'
        const rawAmountY = !isTokenX ? amountRaw : '0'
        const numPrice = Number(
          getPriceFromBinId(
            binId,
            pairLbBinStep,
            tokenX?.decimals || 18,
            tokenY?.decimals || 18,
            18
          ) ?? 0
        )
        const amountYRatio =
          amountY > 0 || amountX > 0
            ? amountY / (amountX * numPrice + amountY)
            : 0

        const liquidity = amountY || amountX * numPrice // amounts in Y

        bins.push({
          amountX: new TokenAmount(tokenX, rawAmountX),
          amountY: new TokenAmount(tokenY, rawAmountY),
          amountYPct: (amountYRatio * 100).toFixed(2),
          binId,
          isActiveBin: binId === activeBinId,
          liquidity,
          price
        })
      } else {
        // bin does not exist, so add empty bin
        bins.push({
          amountX: new TokenAmount(tokenX, '0'),
          amountY: new TokenAmount(tokenY, '0'),
          amountYPct: '0',
          binId,
          isActiveBin: binId === activeBinId,
          liquidity: 0,
          price
        })
      }
    }

    return bins
  }, [tokenX, tokenY, ordersByStatus.placed, activeBinId, binStep])

  const { isLoading, write: claimOrders } = useBatchMakeOrdersAction({
    batchOrdersAction: 'batchClaimOrders',
    enabled: ordersByStatus.executed.length > 0 && !!tokenX && !!tokenY,
    limitOrders: ordersByStatus.executed,
    onSuccess: onClaimOrdersSuccess
  })

  return (
    <VStack spacing={4}>
      <Box
        w="full"
        bg="bgCard"
        borderRadius="2xl"
        border="1px solid"
        borderColor="border"
      >
        {activeBinId ? (
          <Box px={{ base: 4, md: 8 }} py={{ base: 4, md: 6 }}>
            {isLoadingOrders || liquidityDistribution.length === 0 ? (
              <Box w="full">
                <Heading size="md" mb={2}>
                  My Active Orders
                </Heading>
                <Center h="216px">
                  {isLoadingOrders ? (
                    <Spinner size="sm" color="textSecondary" />
                  ) : (
                    <Text fontSize="sm" color="textSecondary">
                      You don&apos;t have any active orders in this pool
                    </Text>
                  )}
                </Center>
              </Box>
            ) : (
              <LBPairDistributionChart
                title="My Active Orders"
                data={liquidityDistribution}
                activeBinId={activeBinId}
                currency0={tokenX}
                currency1={tokenY}
                isPriceRatioInversed={isPriceRatioInversed}
              />
            )}
          </Box>
        ) : null}

        <Divider />
        {tokenX && tokenY ? (
          <CurrencyAmountsPanel
            title="My Liquidity"
            tokens={[tokenX.address, tokenY.address]}
            tokenAmounts={[activeAmountX, activeAmountY]}
            tokenSymbols={[currency0?.symbol, currency1?.symbol]}
          />
        ) : null}
      </Box>
      {tokenX && tokenY ? (
        <Box
          w="full"
          bg="bgCard"
          borderRadius="2xl"
          border="1px solid"
          borderColor="border"
        >
          <CurrencyAmountsPanel
            title="Claimable Funds"
            tokens={[tokenX.address, tokenY.address]}
            tokenAmounts={[completedAmountX, completedAmountY]}
            tokenSymbols={[currency0?.symbol, currency1?.symbol]}
            bottomContent={
              <Web3Button
                variant="primary"
                colorScheme="accent"
                size="xl"
                w="full"
                mt={4}
                isDisabled={!claimOrders}
                isLoading={isLoading}
                loadingText="Claiming fees"
                onClick={claimOrders}
              >
                Claim Funds
              </Web3Button>
            }
          />
        </Box>
      ) : null}
    </VStack>
  )
}

export default ActiveOrders

const calculateAmounts = (
  activeOrders: LimitOrder[],
  completedOrders: LimitOrder[],
  tokenX?: Token,
  tokenY?: Token
) => {
  const activeAmountX = activeOrders
    .filter(
      (order) =>
        getAddress(order.tokenIn.tokenId) ===
        getAddress(tokenX?.address || zeroAddress)
    )
    .reduce((prev, curr) => prev + curr.tokenIn.amount, 0)

  const activeAmountY = activeOrders
    .filter(
      (order) =>
        getAddress(order.tokenIn.tokenId) ===
        getAddress(tokenY?.address || zeroAddress)
    )
    .reduce((prev, curr) => prev + curr.tokenIn.amount, 0)

  const completedAmountX = completedOrders
    .filter(
      (order) =>
        getAddress(order.tokenIn.tokenId) ===
        getAddress(tokenX?.address || zeroAddress)
    )
    .reduce((prev, curr) => prev + curr.tokenIn.amount, 0)

  const completedAmountY = completedOrders
    .filter(
      (order) =>
        getAddress(order.tokenIn.tokenId) ===
        getAddress(tokenY?.address || zeroAddress)
    )
    .reduce((prev, curr) => prev + curr.tokenIn.amount, 0)

  return {
    activeAmountX,
    activeAmountY,
    completedAmountX,
    completedAmountY
  }
}
