import { useInfiniteQuery } from '@tanstack/react-query'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { Pool as DexbarnPool, PoolQueryParam } from 'types/dexbarn'
import { Pool } from 'types/pool'
import { getDexbarnChainParam } from 'utils/chains'
import { convertDexbarnPoolToPool } from 'utils/poolV2'

interface UsePoolV2Props {
  orderBy: PoolQueryParam.OrderBy
  pageSize: number
  status: PoolQueryParam.Status
}

const usePoolsV2 = ({ orderBy, pageSize, status }: UsePoolV2Props) => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)

  // fetch from dexbarn
  const fetchPoolsV2 = useDexbarnGet<DexbarnPool[]>(`/v1/pools/${chain}`)
  const result = useInfiniteQuery<DexbarnPool[]>(
    ['LBPairsQuery', chain, pageSize, orderBy, status],
    ({ pageParam = 1 }) =>
      fetchPoolsV2({
        params: {
          filterBy: '1d',
          orderBy,
          pageNum: pageParam,
          pageSize,
          status
        }
      }),
    {
      getNextPageParam: (lastPage, allPages) =>
        lastPage.length % pageSize === 0 ? allPages.length + 1 : undefined,
      keepPreviousData: true
    }
  )

  // convert to common pool interface
  const pools: Pool[] =
    result.data?.pages.flat().map(convertDexbarnPoolToPool) ?? []

  return {
    ...result,
    data: pools
  }
}

export default usePoolsV2
