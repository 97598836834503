import { ChainId } from '@traderjoe-team/spruce-sdk'
import { supportedChains } from 'constants/chains'
import { Chain } from 'types/dexbarn'

export const getChain = (chainId: ChainId) => {
  return supportedChains.find((chain) => chain.id === chainId)
}

export const getChainSlug = (chainId: ChainId): string | undefined => {
  return getChain(chainId) ? getChain(chainId)?.slug : 'spruce'
}

export const getDexbarnChainParam = (chainId: ChainId): Chain => {
  switch (chainId) {
    case ChainId.FUJI:
      return 'avalanche'
    case ChainId.SPRUCE:
      return 'spruce'
  }
}
