import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { CNATIVE } from '@traderjoe-team/spruce-sdk'
import { PoolVersion, Quote, RouteV2 } from '@traderjoe-team/spruce-sdk-v2'
import CurrencyLogo from 'components/CurrencyLogo'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { isWrappedNativeCurrency } from 'utils/wrappedCurrency'

import RouteDetailsStep from './RouteDetailsStep'

interface RouteDetailsProps {
  quote: Quote
  route: RouteV2
}

const RouteDetails = ({ quote, route }: RouteDetailsProps) => {
  const chainId = useChainId()
  const nativeCurrency = CNATIVE.onChain(chainId)

  const { pairs: pairsAddr, versions } = quote
  const { path } = route

  const pairs = path.slice(1).map((token, i) => [path[i], token])

  return (
    <VStack align="flex-start" pb={4}>
      <Text fontSize="sm" fontWeight="semibold">
        Route
      </Text>
      <Flex align="center" flexWrap="wrap" gap={2}>
        <HStack>
          <CurrencyLogo
            address={path[0].address}
            symbol={
              isWrappedNativeCurrency(path[0].address, chainId)
                ? nativeCurrency.symbol
                : path[0].symbol
            }
            boxSize={5}
          />
        </HStack>
        <ArrowForwardIcon boxSize="14px" color="textSecondary" />
        {pairs.map((pair, i) => {
          const version = versions[i]
          const lbPairAddress =
            version === PoolVersion.V2 || version === PoolVersion.V2_1
              ? pairsAddr[i]
              : undefined
          return (
            <RouteDetailsStep
              key={i}
              pair={pair}
              lbPairAddress={lbPairAddress}
              poolVersion={version}
              showForwardArrow={route.pairs.length - 1 > i}
            />
          )
        })}
      </Flex>
    </VStack>
  )
}

export default RouteDetails
