import { useQuery } from '@tanstack/react-query'
import { TokenInfo } from 'types/tokensList'

const useFetchTokensList = () => {
  const traderJoeListUrl =
    'https://raw.githubusercontent.com/traderjoe-xyz/lb-pro-tokenlist/main/spruce.json'

  return useQuery<{ tokens: TokenInfo[] }, Error>(
    ['TraderJoeTokensListV2Query'],
    () => fetch(traderJoeListUrl).then((res) => res.json()),
    { cacheTime: 10 * 60 * 1000 } // 10 mins
  )
}

export default useFetchTokensList
