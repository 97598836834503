import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  HStack,
  Link,
  SimpleGrid,
  Text,
  VStack
} from '@chakra-ui/react'
import { ChainId } from '@traderjoe-team/spruce-sdk'
import { STRATEGIES_INFO } from 'constants/vaults'
import useActiveChain from 'hooks/useActiveChain'
import React from 'react'
import { AchievementIcon } from 'theme/icons'
import { LeaderboardRowProps } from 'types/leaderboard'
import { shortenAddress } from 'utils/addresses'
import { formattedNum } from 'utils/format'

const LeaderboardRowMobile = ({
  accruedFeesUsd,
  currency0,
  currency1,
  currentBinCount,
  currentPositionTokenX,
  currentPositionTokenY,
  position,
  userAddress,
  volumeTradedUsd
}: LeaderboardRowProps) => {
  const { blockExplorers, id: chainId } = useActiveChain()

  const autoPoolName = STRATEGIES_INFO[chainId as ChainId][userAddress]?.name
  const userName = autoPoolName ?? shortenAddress(userAddress, 6)

  const renderTrophy = (position: number | undefined) => {
    switch (position) {
      case 1:
        return <AchievementIcon fill="yellow.400" />
      case 2:
        return <AchievementIcon fill="gray.400" />
      case 3:
        return <AchievementIcon fill="yellow.600" />
      default:
        return null
    }
  }

  return (
    <SimpleGrid columns={2}>
      <VStack w="full" alignItems="flex-start">
        <Text fontSize="sm" textColor="textSecondaryLight">
          User Address:
        </Text>
        <Flex align="flex-start">
          <Text fontSize="sm" textColor="textSecondaryLight">
            Volume traded:
          </Text>
        </Flex>
        <Flex align="flex-start">
          <Text fontSize="sm" textColor="textSecondaryLight">
            Fees generated:
          </Text>
        </Flex>
        <Flex align="flex-start">
          <Text fontSize="sm" textColor="textSecondaryLight">
            Bins
          </Text>
        </Flex>
        <Flex align="flex-start">
          <Text fontSize="sm" textColor="textSecondaryLight">
            Token X:
          </Text>
        </Flex>
        <Flex align="flex-start">
          <Text fontSize="sm" textColor="textSecondaryLight">
            Token Y:
          </Text>
        </Flex>
      </VStack>
      <VStack w="full" alignItems="flex-start">
        <Link
          aria-label="Link to user address on explorer"
          isExternal
          href={`${blockExplorers?.default.url}/address/${userAddress}`}
        >
          <HStack fontSize="sm" textColor="textPrimary" fontWeight="semibold">
            {renderTrophy(position)}
            <Box>{userName}</Box>
            <ExternalLinkIcon />
          </HStack>
        </Link>
        <Flex alignItems="flex-start">
          <Text fontSize="sm" fontWeight="semibold">
            {formattedNum(volumeTradedUsd, { usd: true })}
          </Text>
        </Flex>
        <Flex>
          <Text fontSize="sm" fontWeight="semibold">
            {formattedNum(accruedFeesUsd, { usd: true })}
          </Text>
        </Flex>
        <Flex>
          <Text fontSize="sm" fontWeight="semibold">
            {formattedNum(currentBinCount, { usd: false })}
          </Text>
        </Flex>
        <Flex>
          <Text fontSize="sm" fontWeight="semibold">
            {`${formattedNum(currentPositionTokenX)} ${currency0?.symbol}`}
          </Text>
        </Flex>
        <Flex>
          <Text fontSize="sm" fontWeight="semibold">
            {`${formattedNum(currentPositionTokenY)} ${currency1?.symbol}`}
          </Text>
        </Flex>
      </VStack>
    </SimpleGrid>
  )
}

export default LeaderboardRowMobile
