import { ChainId } from '@traderjoe-team/spruce-sdk'

const getMaxGasLimit = (chainId: number): bigint | undefined => {
  switch (chainId) {
    case ChainId.FUJI:
      return BigInt(15_000_000)
    default:
      return undefined
  }
}

export const getConfigWithGasLimitIncreased = ({
  config,
  percentageIncrease
}: {
  config: any
  percentageIncrease: number
}) => {
  if (percentageIncrease < 1) {
    throw Error('Percentage increase must be >= 1%')
  }

  if (config && config.request) {
    let increasedGasLimit =
      config.request.gas !== undefined
        ? (config.request.gas * (BigInt(percentageIncrease) + BigInt(100))) /
          BigInt(100)
        : undefined

    if (config.request.chainId) {
      const maxGasLimit = getMaxGasLimit(config.request.chainId)
      if (maxGasLimit && increasedGasLimit && increasedGasLimit > maxGasLimit) {
        increasedGasLimit = maxGasLimit
      }
    }

    return {
      ...config,
      request: {
        ...config.request,
        gas: increasedGasLimit
      }
    }
  }
  return config
}
