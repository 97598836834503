import { Token } from '@traderjoe-team/spruce-sdk'
import CurrencyAmountsPanel from 'components/CurrencyAmountsPanel'
import Web3Button from 'components/Web3Button'
import useLBPairClaimFees from 'hooks/pool/v2/useLBPairClaimFees'
import React from 'react'
import { poolClaimFees } from 'utils/measure'
import { formatUnits } from 'viem'

interface ClaimFeesProps {
  wrappedCurrency0: Token
  wrappedCurrency1: Token
  currency0Symbol?: string
  currency1Symbol?: string
  lbPairAddress?: string
  userPositions?: number[]
}

const ClaimFees = ({
  currency0Symbol,
  currency1Symbol,
  lbPairAddress,
  userPositions,
  wrappedCurrency0,
  wrappedCurrency1
}: ClaimFeesProps) => {
  const {
    claimFees,
    isLoading: isClaimingFees,
    pendingFees
  } = useLBPairClaimFees({
    lbPairAddress,
    poolName: `${currency0Symbol}-${currency1Symbol}`,
    userPositions
  })

  const pendingFee0 = pendingFees?.[0] ?? BigInt(0)
  const pendingFee1 = pendingFees?.[1] ?? BigInt(0)
  const hasPendingFees = pendingFee0 > 0 || pendingFee1 > 0

  const tokenAmount0 = Number(
    formatUnits(pendingFee0, wrappedCurrency0.decimals)
  )
  const tokenAmount1 = Number(
    formatUnits(pendingFee1, wrappedCurrency1.decimals)
  )

  return (
    <CurrencyAmountsPanel
      title="Claimable Fees"
      tokens={[wrappedCurrency0.address, wrappedCurrency1.address]}
      tokenAmounts={[tokenAmount0, tokenAmount1]}
      tokenSymbols={[currency0Symbol, currency1Symbol]}
      bottomContent={
        <Web3Button
          variant="primary"
          colorScheme="accent"
          size="xl"
          w="full"
          mt={4}
          isDisabled={!hasPendingFees || !claimFees}
          isLoading={isClaimingFees}
          loadingText="Claiming fees"
          onClick={() => {
            claimFees?.()
            poolClaimFees(`${currency0Symbol}-${currency1Symbol}`)
          }}
        >
          Claim Fees
        </Web3Button>
      }
    />
  )
}

export default ClaimFees
