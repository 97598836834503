export interface TokenInfo {
  chainId: number
  decimals: number
  name: string
  symbol: string
  tags: TokenInfoTag[]
  address?: `0x${string}`
  balance?: string
  logoURI?: string
}

export enum TokenInfoTag {
  COMMODITY = 'Commodity',
  FX = 'FX',
  TOP = 'Top',
  USER = 'My Tokens'
}
