import { JSBI } from '@traderjoe-team/spruce-sdk'

const BIPS_BASE = JSBI.BigInt(10000)

export const TRADE_HELMET_TITLE =
  'LB Pro | Decentralized Exchange for Institutions | Trade'
export const TRADE_HELMET_DESCRIPTION =
  'Enterprise grade Decentralized Exchange. DeFi experience for compliant institutions. Powered by Liquidity Book Protocol.'

export { BIPS_BASE }
